import dayjs from 'dayjs';

export interface IImportSession {
  id?: number;
  idTehnic?: string | null;
  sourceDataIdentifier?: string | null;
  recordsInFile?: string | null;
  recordsWithError?: string | null;
  dateOfDataExport?: string | null;
  reconciliationPerformed?: boolean | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IImportSession> = {
  reconciliationPerformed: false,
};
