import dayjs from 'dayjs';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IInUserIdentity {
  id?: number;
  applicationCode?: string | null;
  userBusinessKey?: string | null;
  userName?: string | null;
  alternativeUserName?: string | null;
  displayName?: string | null;
  status?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IInUserIdentity> = {};
