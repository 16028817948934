import { RoleRequirementsPerApplication } from 'app/shared/model/enumerations/role-requirements-per-application.model';
import { IIAMSystem } from 'app/shared/model/iam-system.model';
import { IUserWithEmployee } from './user.model';

export interface IApplication {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  uniqueIdentityKey?: string | null;
  roleRequired?: boolean | null;
  roleRequirements?: RoleRequirementsPerApplication | null;
  connectorTopic?: string | null;
  system?: IIAMSystem | null;
  main?: boolean;
  adminUsers?: string;
  adminUsersList?: IUserWithEmployee[];
  userDependsOnApplication?: IApplication | null;
  applicableBusinessValidityStartDateOnUser?: boolean | null;
  applicableBusinessValidityEndDateOnUser?: boolean | null;
  applicableBusinessValidityStartDateOnRole?: boolean | null;
  applicableBusinessValidityEndDateOnRole?: boolean | null;
  applicableBusinessValidityStartDateOnGroup?: boolean | null;
  applicableBusinessValidityEndDateOnGroup?: boolean | null;
  applicableBusinessValidityStartDateOnPermission?: boolean | null;
  applicableBusinessValidityEndDateOnPermission?: boolean | null;
  connectorTimeout?: number | null;
  supportsUserSuspend?: boolean | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IApplication> = {};
