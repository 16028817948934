import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderedSpecificPermission from './ordered-specific-permission';
import OrderedSpecificPermissionDetail from './ordered-specific-permission-detail';
import OrderedSpecificPermissionUpdate from './ordered-specific-permission-update';
import OrderedSpecificPermissionDeleteDialog from './ordered-specific-permission-delete-dialog';

const OrderedSpecificPermissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderedSpecificPermission />} />
    <Route path="new" element={<OrderedSpecificPermissionUpdate />} />
    <Route path=":orderedSpecificPermissionId">
      <Route index element={<OrderedSpecificPermissionDetail />} />
      <Route path="edit" element={<OrderedSpecificPermissionUpdate />} />
      <Route path="delete" element={<OrderedSpecificPermissionDeleteDialog />} />
      <Route path="*" element={<OrderedSpecificPermissionDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderedSpecificPermissionRoutes;
