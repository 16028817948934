import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RiskCombination from './risk-combination';
import RiskCombinationDetail from './risk-combination-detail';
import RiskCombinationUpdate from './risk-combination-update';
import RiskCombinationDeleteDialog from './risk-combination-delete-dialog';

const RiskCombinationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RiskCombination />} />
    <Route path="new" element={<RiskCombinationUpdate />} />
    <Route path=":id">
      <Route index element={<RiskCombinationDetail />} />
      <Route path="edit" element={<RiskCombinationUpdate />} />
      <Route path="delete" element={<RiskCombinationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RiskCombinationRoutes;
