import dayjs from 'dayjs';
import { IProfileUserIdentity } from 'app/shared/model/profile-user-identity.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';

export interface IProfileSpecificPermission {
  id?: number;
  descriptionExpresion?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  nameExpression?: string | null;
  permissionDefinitionExpression?: string | null;
  profileUserIdentity?: IProfileUserIdentity | null;
  permissionDefinition?: IPermissionDefinition | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IProfileSpecificPermission> = {};

export const ENTITY_NAME = 'ProfileSpecificPermission';
