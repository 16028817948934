/* eslint-disable react/jsx-key */
import { faPen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_GLOBAL_INSTANT_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AuditRevType, IAudit, IAuditEntry } from 'app/shared/model/audit.model';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { getRevisionsById } from './audit.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

interface ITimelineElementProps {
  change: IAuditEntry;
}

const TimelineElement = ({ change }: ITimelineElementProps) => {
  const isInsert = change.revType == AuditRevType.ADD;
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <VerticalTimelineElement
      className="vertical-timeline-element--work"
      contentStyle={{ background: 'rgb(51, 179, 221)', color: '#fff' }}
      contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
      date={moment(change.modifiedAt).format(convertDateFormat(locale, 'instant'))}
      iconStyle={{ background: 'rgb(232, 50, 131)', color: '#fff' }}
      icon={<FontAwesomeIcon icon={isInsert ? faPlus : faPen} />}
    >
      {/* <h3 className="vertical-timeline-element-title">{change.modifiedBy}</h3> */}
      <h4 className="vertical-timeline-element-subtitle">
        {isInsert ? (
          <Translate contentKey="iamdentityApp.audit.changes.insert" interpolate={{ user: change.modifiedBy }} />
        ) : change.fields.length == 1 ? (
          <Translate contentKey="iamdentityApp.audit.changes.updateOne" interpolate={{ user: change.modifiedBy }} />
        ) : (
          <Translate
            contentKey="iamdentityApp.audit.changes.updateMore"
            interpolate={{ user: change.modifiedBy, changesNumber: change.fields.length }}
          />
        )}
      </h4>
      <div>
        <Table>
          <thead>
            <tr>
              <th>{translate('iamdentityApp.audit..modal.field')}</th>
              {!isInsert && <th>{translate('iamdentityApp.audit.modal.previousValue')}</th>}
              <th>{translate('iamdentityApp.audit.modal.currentValue')}</th>
            </tr>
          </thead>
          <tbody>
            {change.fields?.map(field => (
              <tr>
                <td>{field.name}</td>
                {!isInsert && (
                  <td className="table-danger">
                    {field.previousValue && field.type === 'DATE' ? (
                      <TextFormat type="date" value={field.previousValue} format={convertDateFormat(locale, 'date')} />
                    ) : field.previousValue && field.type === 'DATETIME' ? (
                      <TextFormat type="date" value={field.previousValue} format={convertDateFormat(locale, 'instant')} />
                    ) : field.type === 'BOOLEAN' ? (
                      field.previousValue ? (
                        translate('global.yes')
                      ) : (
                        translate('global.no')
                      )
                    ) : (
                      field.previousValue
                    )}
                  </td>
                )}
                <td className="table-success">
                  {field.currentValue && field.type === 'DATE' ? (
                    <TextFormat type="date" value={field.currentValue} format={convertDateFormat(locale, 'date')} />
                  ) : field.currentValue && field.type === 'DATETIME' ? (
                    <TextFormat type="date" value={field.currentValue} format={convertDateFormat(locale, 'instant')} />
                  ) : field.type === 'BOOLEAN' ? (
                    field.currentValue ? (
                      translate('global.yes')
                    ) : (
                      translate('global.no')
                    )
                  ) : (
                    field.currentValue
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </VerticalTimelineElement>
  );
};

interface IModalProps {
  isOpen: boolean;
  toggle: () => void;
  entityName: string;
  entityId: string;
}

const AuditModal = ({ isOpen, toggle, entityName, entityId }: IModalProps) => {
  const dispatch = useAppDispatch();

  const [entityChanges, setEntityChanges] = useState<IAuditEntry[]>([]);

  const audit: IAudit = useAppSelector(state => state.audit.entities);

  useEffect(() => {
    dispatch(getRevisionsById({ id: entityId, entityName }));
  }, [entityName, entityId]);

  useEffect(() => {
    let changes = [...(audit.entityChanges ?? []), ...(audit.customAttributesChanges ?? [])];
    setEntityChanges(changes.sort((a, b) => (moment(a.modifiedAt).isAfter(moment(b.modifiedAt)) ? -1 : 1)));
  }, [audit]);

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="xl" scrollable={true}>
      <ModalHeader toggle={toggle}>
        <Translate contentKey="iamdentityApp.audit.modal.title" />
      </ModalHeader>
      <ModalBody>
        {entityChanges?.length ? (
          <VerticalTimeline layout="1-column-left">
            {entityChanges?.map((change, index) => (
              <TimelineElement key={`change-${index}`} change={change} />
            ))}
          </VerticalTimeline>
        ) : (
          <em>
            <Translate contentKey="iamdentityApp.audit.modal.empty" />
          </em>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={toggle}>
          {translate('entity.action.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AuditModal;
