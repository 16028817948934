import React from 'react';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import '../style.scss';

const RecertificationSessionTypeCard = () => {
  return (
    <React.Fragment>
      <Card color="default" inverse>
        <CardHeader></CardHeader>
        <CardBody>
          <CardText></CardText>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default RecertificationSessionTypeCard;
