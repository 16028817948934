import { generateOptionLabel, generateOptionValue, generateSelectStyles } from 'app/shared/util/react-select-utils';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import './style.scss';
import { ISelectComponentProps } from './types';

const createValue = (value: any, data: any[]) => {
  if (!value) return null;

  if (!data.length) return value;

  let newValue = null;
  if (Array.isArray(value)) {
    newValue = value.map(obj => {
      const foundData = data.find(entity => obj.id == entity.id);
      if (foundData) {
        return foundData;
      } else {
        return obj;
      }
    });
  } else {
    const foundData = data.find(entity => value.id == entity.id);
    newValue = foundData ?? value;
  }

  return newValue;
};

function SelectComponent({
  url,
  value,
  error,
  isTouched,
  queryProps,
  hideSelectedOptions,
  isDisabled,
  isMulti,
  onBlur,
  onChange,
}: ISelectComponentProps) {
  const [entities, setEntities] = useState([]);
  const finalValue = useMemo(() => createValue(value, entities), [value, entities]);

  const fetchData = async () => {
    return await axios.get(url);
  };

  const { isLoading, isError, data, refetch } = useQuery(url, fetchData, { refetchOnWindowFocus: false });

  useEffect(() => {
    if (data && !isLoading && !isError) {
      setEntities(data?.data);
    }
  }, [data]);

  return (
    <Select
      styles={generateSelectStyles({ error, isTouched, isDisabled })}
      value={finalValue}
      isDisabled={!!isDisabled}
      isMulti={!!isMulti}
      menuPortalTarget={document.body}
      hideSelectedOptions={!!hideSelectedOptions}
      isLoading={isLoading}
      isClearable={true}
      getOptionLabel={op => generateOptionLabel(op, queryProps)}
      getOptionValue={op => generateOptionValue(op, queryProps)}
      onChange={onChange}
      onBlur={onBlur}
      options={entities}
    />
  );
}

export default SelectComponent;
