import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecertificationSessionUserRole } from 'app/shared/model/recertification-session-user-role.model';
import React from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedRoleUrl } from './recertification-session-users/recertification-session-users';
import useModal from 'app/shared/components/modal/modal.hook';
import { revokeUserRoleById } from 'app/entities/orders/orders.reducer';

interface IAssignedRecertificationSessionRolesTablesProps {
  recertificationSessionRoles: IRecertificationSessionUserRole[];
  isTask?: boolean | false;
}

const AssignedRecertificationSessionRolesTable = ({
  recertificationSessionRoles,
  isTask,
}: IAssignedRecertificationSessionRolesTablesProps) => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);

  // recertification session revoke user role from task
  const startRevokeUserRole = (recertificationSessionEntityId, recertificationSessionRoleIdForRevokeInit) => {
    dispatch(revokeUserRoleById({ recertificationSessionEntityId, recertificationSessionRoleIdForRevokeInit }));
  };

  const modal = useModal();
  const revokeUserRole = (
    recertificationSessionEntityId,
    recertificationSessionRoleIdForRevoke,
    roleNameParam,
    applicationNameParam,
    employeeNameParam
  ) => {
    modal.show({
      size: 'LARGE',
      title: translate('iamdentityApp.recertificationSessionUserRole.revokeRole'),
      body: translate('iamdentityApp.recertificationSessionUserRole.revokeRoleDialog', {
        roleName: roleNameParam,
        applicationName: applicationNameParam,
        employeeName: employeeNameParam,
      }),
      callback: startRevokeUserRole.bind(null, recertificationSessionEntityId, recertificationSessionRoleIdForRevoke),
    });
  };

  return (
    <div className="table-responsive">
      {recertificationSessionRoles.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.application">Application</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.roleDefinition">Role Definition</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.status">Status</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.grantedBy">Granted By</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.validFrom">Valid From</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.validTo">Valid To</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revoked">Revoked</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {recertificationSessionRoles.map((recertificationSessionRole, i) => (
              <tr key={`role-${i}`}>
                <td>
                  {recertificationSessionRole.userRole.roleDefinition ? (
                    <Link to={`/application/${recertificationSessionRole.userRole.roleDefinition.application.id}`}>
                      {recertificationSessionRole.userRole.roleDefinition.application.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionRole.userRole.roleDefinition ? (
                    <Link to={`/role-definition/${recertificationSessionRole.userRole.roleDefinition.id}`}>
                      {recertificationSessionRole.userRole.roleDefinition.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{recertificationSessionRole.userRole.status ? recertificationSessionRole.userRole.status : ''}</td>
                <td>{recertificationSessionRole.userRole.grantedBy ? recertificationSessionRole.userRole.grantedBy : ''}</td>
                <td>
                  {recertificationSessionRole.userRole.startDate ? (
                    <TextFormat
                      value={recertificationSessionRole.userRole.startDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionRole.userRole.endDate ? (
                    <TextFormat
                      value={recertificationSessionRole.userRole.endDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionRole.revoked !== null && recertificationSessionRole.revoked !== undefined ? (
                    recertificationSessionRole.revoked === true ? (
                      recertificationSessionRole.order ? (
                        <div>
                          <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revokedByOrder">Revoked by Order</Translate>
                          :&nbsp;
                          <Link to={`/orders/${recertificationSessionRole.order.id}`}>{recertificationSessionRole.order.id}</Link>
                          &nbsp;
                          {recertificationSessionRole?.order?.status ? (
                            <span className="fw-bold text-black d-inline-block">({recertificationSessionRole?.order?.status})</span>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        <Translate contentKey="iamdentityApp.recertificationSessionUserRole.revokedByOrder">Revoked by Order</Translate>
                      )
                    ) : (
                      <Translate contentKey="iamdentityApp.recertificationSessionUserRole.confirmed">Confirmed</Translate>
                    )
                  ) : (
                    ''
                  )}
                </td>

                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    {!isTask ? (
                      <Button
                        tag={Link}
                        to={generateAssignedRoleUrl(
                          recertificationSessionRole.recertificationSessionUserIdentity,
                          recertificationSessionRole.id,
                          'VIEW'
                        )}
                        className="btn-md"
                        color="info"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                      </Button>
                    ) : null}
                    {isTask ? (
                      <Button
                        disabled={
                          recertificationSessionRole?.revoked === true || recertificationSessionRole?.revoked === false ? true : false
                        }
                        onClick={() =>
                          revokeUserRole(
                            recertificationSessionRole?.recertificationSession?.id,
                            recertificationSessionRole?.id,
                            recertificationSessionRole?.userRole?.roleDefinition?.name,
                            recertificationSessionRole?.userRole?.roleDefinition?.application?.name,
                            recertificationSessionRole?.employee?.code + ' - ' + recertificationSessionRole?.employee?.fullName
                          )
                        }
                        className="btn-md"
                        color="primary"
                      >
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        {translate('iamdentityApp.recertificationSession.revoke')}
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.recertificationSessionUserRole.home.notFound">
            No Recertification Session User Roles found
          </Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedRecertificationSessionRolesTable;
