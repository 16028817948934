import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserIdentityRoleSystemsNotInIam from './recon-session-user-identity-role-systems-not-in-iam';
import ReconSessionUserIdentityRoleSystemsNotInIamDetail from './recon-session-user-identity-role-systems-not-in-iam-detail';
import ReconSessionUserIdentityRoleSystemsNotInIamUpdate from './recon-session-user-identity-role-systems-not-in-iam-update';
import ReconSessionUserIdentityRoleSystemsNotInIamDeleteDialog from './recon-session-user-identity-role-systems-not-in-iam-delete-dialog';

const ReconSessionUserIdentityRoleSystemsNotInIamRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserIdentityRoleSystemsNotInIam />} />
    <Route path="new" element={<ReconSessionUserIdentityRoleSystemsNotInIamUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserIdentityRoleSystemsNotInIamDetail />} />
      <Route path="edit" element={<ReconSessionUserIdentityRoleSystemsNotInIamUpdate />} />
      <Route path="delete" element={<ReconSessionUserIdentityRoleSystemsNotInIamDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserIdentityRoleSystemsNotInIamRoutes;
