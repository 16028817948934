import dayjs from 'dayjs';
import { IProject } from 'app/shared/model/project.model';
import { IEmployee } from 'app/shared/model/employee.model';

export interface IProjectAssignment {
  id?: number;
  startDate?: string | null;
  endDate?: string | null;
  project?: IProject | null;
  employee?: IEmployee | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IProjectAssignment> = {};
