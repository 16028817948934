import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserIdentityRole from './user-identity-role';
import UserIdentityRoleDetail from './user-identity-role-detail';
import UserIdentityRoleUpdate from './user-identity-role-update';
import UserIdentityRoleDeleteDialog from './user-identity-role-delete-dialog';

const UserIdentityRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserIdentityRole />} />
    <Route path="new" element={<UserIdentityRoleUpdate />} />
    <Route path=":id">
      <Route index element={<UserIdentityRoleDetail />} />
      <Route path="edit" element={<UserIdentityRoleUpdate />} />
      <Route path="delete" element={<UserIdentityRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserIdentityRoleRoutes;
