import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './email-template.reducer';

export const EmailTemplateDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { id } = useParams<'id'>();
  const locale = useAppSelector(state => state.locale.currentLocale);
  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const emailTemplateEntity = useAppSelector(state => state.emailTemplate.entity);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Col md="8">
          <h2 data-cy="emailTemplateDetailsHeading">
            <Translate contentKey="iamdentityApp.emailTemplate.detail.title">EmailTemplate</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="id">
                <Translate contentKey="global.field.id">ID</Translate>
              </span>
            </dt>
            <dd>{emailTemplateEntity.id}</dd>
            <dt>
              <span id="name">
                <Translate contentKey="iamdentityApp.emailTemplate.name">Name</Translate>
              </span>
            </dt>
            <dd>{emailTemplateEntity.name}</dd>
            <dt>
              <span id="emailEvent">
                <Translate contentKey="iamdentityApp.emailTemplate.emailEvent">Email Event</Translate>
              </span>
            </dt>
            <dd>{emailTemplateEntity.emailEvent}</dd>
            <dt>
              <span id="subject">
                <Translate contentKey="iamdentityApp.emailTemplate.subject">Subject</Translate>
              </span>
            </dt>
            <dd>{emailTemplateEntity.subject}</dd>
            <dt>
              <span id="templateContent">
                <Translate contentKey="iamdentityApp.emailTemplate.templateContent">Template Content</Translate>
              </span>
            </dt>
            <dd>{emailTemplateEntity.templateContent}</dd>
            <dt>
              <Translate contentKey="global.field.createdBy">Created By</Translate>
            </dt>
            <dd>{emailTemplateEntity.createdBy ? emailTemplateEntity.createdBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.createdDate">Created Date</Translate>
            </dt>
            <dd>
              {emailTemplateEntity.createdDate ? (
                <TextFormat value={emailTemplateEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
            </dt>
            <dd>{emailTemplateEntity.lastModifiedBy ? emailTemplateEntity.lastModifiedBy : ''}</dd>
            <dt>
              <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
            </dt>
            <dd>
              {emailTemplateEntity.lastModifiedDate ? (
                <TextFormat value={emailTemplateEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
              ) : null}
            </dd>
          </dl>
          <Button tag={Link} to="/email-template" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
          &nbsp;
          <Button tag={Link} to={`/email-template/${emailTemplateEntity.id}/edit`} color="primary">
            <FontAwesomeIcon icon="pencil-alt" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.edit">Edit</Translate>
            </span>
          </Button>
          <Button onClick={toggleAuditModal} color="light">
            <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.history">History</Translate>
            </span>
          </Button>
        </Col>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.EMAIL_TEMPLATE} />
    </React.Fragment>
  );
};

export default EmailTemplateDetail;
