import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  Col,
  Row,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
  Table,
  Spinner,
} from 'reactstrap';
import { getEntities as getEmployeeDelegationEntities } from 'app/entities/employee-delegation/employee-delegation.reducer';
import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { getEntities as getOrders } from 'app/entities/orders/orders.reducer';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { APP_LOCAL_DATE_FORMAT, APP_DATE_FORMAT } from 'app/config/constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import { convertDateFormat } from 'app/shared/util/date-utils';

interface IDetailsProps {
  employeeId: number;
}

const Details = ({ employeeId }: IDetailsProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //select component data lists
  const employeeDelegationList = useAppSelector(state => state.employeeDelegation.entities);
  const employeeList = useAppSelector(state => state.employee.entities);
  const { entities: employeeOrders, totalItems: totalNrEmployeeOrders } = useAppSelector(state => state.orders);
  const locale = useAppSelector(state => state.locale.currentLocale);

  //dispatch data
  useEffect(() => {
    dispatch(getEmployeeDelegationEntities({ query: 'employeeId.equals=' + employeeId.toString() + `&page=0&size=200` }));
    dispatch(getEmployees({ query: 'superiorId.equals=' + employeeId.toString() + '&page=0&size=200' }));
    dispatch(getOrders({ query: 'employeeId.equals=' + employeeId.toString() + `&page=${0}&size=${ITEMS_PER_PAGE}` }));
  }, [employeeId]);

  //orders -> states to handle infinity scroll option
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState<number>(1);
  const [orders, setOrders] = useState([]);

  //handle orders -> infinity scroll data
  useEffect(() => {
    if (employeeOrders && employeeOrders.length > 0) {
      const newOrders = [...orders, ...employeeOrders];

      // Remove duplicates based on the 'id' field
      const filteredOrders = newOrders.filter((order, index, self) => index === self.findIndex(o => o.id === order.id));

      setHasMore(newOrders.length < totalNrEmployeeOrders);
      setOrders(filteredOrders);
    } else {
      setHasMore(false);
    }
  }, [employeeOrders]);

  //orders --> fetch more data -> infinity scroll component
  const fetchData = (pg: number = null) => {
    const nextPage = pg != null ? pg : (page ? page : 1) + 1;
    dispatch(getOrders({ query: 'employeeId.equals=' + employeeId.toString() + `&page=${page}&size=${ITEMS_PER_PAGE}` }));
    setPage(nextPage);
  };

  //loading state for fetching data
  const loadingEmployee = useAppSelector(state => state.employee.loading);
  const loadingDelegationList = useAppSelector(state => state.employeeDelegation.loading);

  return (
    <React.Fragment>
      <Row>
        <UncontrolledAccordion open="" defaultOpen="1">
          <AccordionItem>
            <AccordionHeader targetId="1">
              <Translate contentKey="iamdentityApp.employeeDelegation.home.title">Delegations</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="1">
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employeeDelegation.job">Job</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employeeDelegation.organizationUnit">Organization Unit</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employeeDelegation.cumulated">Cumulated</Translate>
                      </th>

                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employeeDelegation.validFrom">Valid From</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employeeDelegation.validTo">Valid To</Translate>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(employeeDelegationList) &&
                      employeeDelegationList.map(employeeDelegation => (
                        <tr key={`entity-${employeeDelegation.id}`} data-cy="entityTable">
                          <td>
                            {employeeDelegation?.job ? (
                              <Link to={`/job-definition/${employeeDelegation.job.id}`}>{employeeDelegation.job.name}</Link>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {employeeDelegation?.organizationUnit ? (
                              <Link to={`/organization-unit/${employeeDelegation.organizationUnit.id}`}>
                                {employeeDelegation.organizationUnit.name}
                              </Link>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{employeeDelegation?.cumulated ? translate('global.yes') : translate('global.no')}</td>
                          <td>
                            {employeeDelegation?.validFrom ? (
                              <TextFormat type="date" value={employeeDelegation.validFrom} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          <td>
                            {employeeDelegation?.validTo ? (
                              <TextFormat type="date" value={employeeDelegation.validTo} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!employeeDelegationList || employeeDelegationList.length < 1) && !loadingDelegationList && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.employeeDelegation.home.notFound">No Employee Delegations found</Translate>
                  </div>
                )}
                {loadingDelegationList ? <Spinner className="d-flex justify-content-center align-items-center m-auto" /> : null}
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">
              <Translate contentKey="iamdentityApp.employee.home.titleSubordinates">Subordinates</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="2">
              <div className="table-responsive">
                <Table responsive>
                  <thead>
                    <tr>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.code">Employee</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.person">Person</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.job">Job Definition</Translate>
                      </th>

                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.organizationUnit">Organization Unit</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.location">Location</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.employeeStatus">Employee Status</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.contractStartDate">Contract Start Date</Translate>
                      </th>
                      <th className="text-center">
                        <Translate contentKey="iamdentityApp.employee.contractEndDate">Contract End Date</Translate>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(employeeList) &&
                      employeeList.map(employee => (
                        <tr key={`entity-${employee.id}`} data-cy="entityTable">
                          <td>
                            {employee?.id && employee?.code ? (
                              <Button tag={Link} to={`/employee/${employee.id}`} color="link" size="sm">
                                {employee.code}
                              </Button>
                            ) : null}
                          </td>
                          <td>
                            {employee?.fullName && employee?.person.id ? (
                              <Link to={`/person/${employee.person.id}`}>{employee.fullName}</Link>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>{employee?.job ? <Link to={`/job-definition/${employee.job.id}`}>{employee.job.name}</Link> : ''}</td>
                          <td>
                            {employee?.organizationUnit ? (
                              <Link to={`/organization-unit/${employee.organizationUnit.id}`}>{employee.organizationUnit.name}</Link>
                            ) : (
                              ''
                            )}
                          </td>
                          <td>
                            {employee?.location ? <Link to={`/location/${employee.location.id}`}>{employee.location.name}</Link> : ''}
                          </td>
                          <td>{employee?.employeeStatus && translate(`iamdentityApp.EmployeeStatus.${employee.employeeStatus}`)}</td>

                          <td>
                            {employee?.contractStartDate ? (
                              <TextFormat type="date" value={employee.contractStartDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                          <td>
                            {employee?.contractEndDate ? (
                              <TextFormat type="date" value={employee.contractEndDate} format={convertDateFormat(locale, 'date')} />
                            ) : null}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
                {(!employeeList || employeeList.length < 1) && !loadingEmployee && (
                  <div className="alert alert-warning mt-1">
                    <Translate contentKey="iamdentityApp.employee.home.notFound">No Employees found</Translate>
                  </div>
                )}
                {loadingEmployee ? <Spinner className="d-flex justify-content-center align-items-center m-auto" /> : null}
              </div>
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">
              <Translate contentKey="iamdentityApp.orders.home.title">No Orders found</Translate>
            </AccordionHeader>
            <AccordionBody accordionId="3">
              <InfiniteScroll
                className="order-list"
                dataLength={orders.length}
                next={fetchData}
                hasMore={hasMore}
                scrollableTarget="app-container"
                loader={
                  <div className="entity-spinner-container">
                    <Spinner color="primary"></Spinner>
                  </div>
                }
                endMessage={
                  orders.length === 0 ? (
                    <div className="alert alert-warning mt-1">
                      <Translate contentKey="iamdentityApp.orders.home.notFound">No Orders found</Translate>
                    </div>
                  ) : null
                }
              >
                <div className="table-responsive">
                  <Table responsive>
                    <thead>
                      <tr>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.id">Id</Translate>
                        </th>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.orderType">Order Type</Translate>
                        </th>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.status">Status</Translate>
                        </th>

                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.scheduleTime">Schedule Time</Translate>
                        </th>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.dependsOn">Depends On</Translate>
                        </th>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.approveDate">Approve Date</Translate>
                        </th>
                        <th className="text-center">
                          <Translate contentKey="iamdentityApp.orders.lastApprover">Last Approver</Translate>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {Array.isArray(orders) &&
                        orders.map(order => (
                          <tr key={`entity-${order.id}`} data-cy="entityTable">
                            <td>
                              {order?.id ? (
                                <Button tag={Link} to={`/orders/${order.id}`} color="link" size="sm">
                                  {order.id}
                                </Button>
                              ) : null}
                            </td>
                            <td>{order?.orderType ? <Link to={`/order-type/${order.orderType.id}`}>{order.orderType.name}</Link> : ''}</td>
                            <td>{order?.status && <Translate contentKey={`iamdentityApp.OrderStatus.${order.status}`} />}</td>
                            <td>
                              {order?.scheduleTime ? (
                                <TextFormat type="date" value={order.scheduleTime} format={convertDateFormat(locale, 'instant')} />
                              ) : null}
                            </td>
                            <td>{order?.dependsOn ? <Link to={`/orders/${order.dependsOn.id}`}>{order.dependsOn.id}</Link> : ''}</td>
                            <td>
                              {order?.approveDate ? (
                                <TextFormat type="date" value={order?.approveDate} format={convertDateFormat(locale, 'date')} />
                              ) : null}
                            </td>
                            <td>
                              {order?.lastApprover ? <Link to={`/orders/${order.lastApprover.id}`}>{order.employee.fullName}</Link> : ''}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </InfiniteScroll>
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </Row>
    </React.Fragment>
  );
};

export default Details;
