import dayjs from 'dayjs';

export interface IProfileDefinition {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  uniqueKey?: string | null;
  partial?: boolean | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IProfileDefinition> = {};
