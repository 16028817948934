import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RoleDefinition from './role-definition';
import RoleDefinitionDetail from './role-definition-detail';
import RoleDefinitionUpdate from './role-definition-update';
import RoleDefinitionDeleteDialog from './role-definition-delete-dialog';

const RoleDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RoleDefinition />} />
    <Route path="new" element={<RoleDefinitionUpdate />} />
    <Route path=":roleDefinitionUpdateId">
      <Route index element={<RoleDefinitionDetail />} />
      <Route path="edit" element={<RoleDefinitionUpdate />} />
      <Route path="delete" element={<RoleDefinitionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RoleDefinitionRoutes;
