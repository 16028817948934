import dayjs from 'dayjs';

export interface IInLocation {
  id?: number;
  code?: string | null;
  name?: string | null;
  validFrom?: string | null;
  validTo?: string | null;
}

export const defaultValue: Readonly<IInLocation> = {};
