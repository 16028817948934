import { IConnectorCommand } from 'app/shared/model/connector-command.model';
import { INonCircularyCustomAttributeDefinition } from 'app/shared/model/custom-attribute-definition.model';
import { ConnectorCommandAttributeDirection } from 'app/shared/model/enumerations/connector-command-attribute-direction.model';
import { ConnectorCommandAttributeType } from 'app/shared/model/enumerations/connector-command-attribute-type.model';

export interface IConnectorCommandAttribute {
  id?: number;
  name?: string;
  description?: string | null;
  dataType?: ConnectorCommandAttributeType | null;
  attributeValueExpression?: string | null;
  validFrom?: string;
  validTo?: string | null;
  direction?: ConnectorCommandAttributeDirection | null;
  connectorCommand?: IConnectorCommand | null;
  attributeDefinition?: INonCircularyCustomAttributeDefinition | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IConnectorCommandAttribute> = {};

export const ENTITY_NAME = 'ConnectorCommandAttribute';
