import { IInSpecificPermission } from 'app/shared/model/in-specific-permission.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserPermissionSystemsNotInIam {
  id?: number;
  applicationCode?: string | null;
  userBusinessKey?: string | null;
  userName?: string | null;
  permissionCode?: string | null;
  processed?: boolean | null;
  comments?: string | null;
  applicationResourceCode?: string | null;
  inSpecificPermission?: IInSpecificPermission | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IReconSessionUserPermissionSystemsNotInIam> = {
  processed: false,
};
