import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderType from './order-type';
import OrderTypeDetail from './order-type-detail';
import OrderTypeUpdate from './order-type-update';
import OrderTypeDeleteDialog from './order-type-delete-dialog';

const OrderTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderType />} />
    <Route path="new" element={<OrderTypeUpdate />} />
    <Route path=":id">
      <Route index element={<OrderTypeDetail />} />
      <Route path="edit" element={<OrderTypeUpdate />} />
      <Route path="delete" element={<OrderTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderTypeRoutes;
