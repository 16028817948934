import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import StandardPolicyRule from './standard-policy-rule';
import StandardPolicyRuleDetail from './standard-policy-rule-detail';
import StandardPolicyRuleUpdate from './standard-policy-rule-update';
import StandardPolicyRuleDeleteDialog from './standard-policy-rule-delete-dialog';

const StandardPolicyRuleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<StandardPolicyRule />} />
    <Route path="new" element={<StandardPolicyRuleUpdate />} />
    <Route path=":id">
      <Route index element={<StandardPolicyRuleDetail />} />
      <Route path="edit" element={<StandardPolicyRuleUpdate />} />
      <Route path="delete" element={<StandardPolicyRuleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default StandardPolicyRuleRoutes;
