import React from 'react';
import { Controller } from 'react-hook-form';
import SelectComponent from './select-component';
import './style.scss';
import { IUncontrolledReactSelectProps } from './types';

function UncontrolledReactSelect(props: IUncontrolledReactSelectProps) {
  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        return <SelectComponent value={value} onChange={onChange} onBlur={onBlur} error={error} isTouched={isTouched} {...props} />;
      }}
    />
  );
}

export default UncontrolledReactSelect;
