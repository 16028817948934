import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IApplication } from 'app/shared/model/application.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import { IUserIdentityRole } from 'app/shared/model/user-identity-role.model';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { IOrder } from 'app/shared/model/order.model';
import { IOrderedUserIdentity } from './ordered-user-identity.model';

export interface IUserIdentity {
  id?: number;
  userName?: string | null;
  alternativeUserName?: string | null;
  associatedEmailAddress?: string | null;
  displayName?: string | null;
  initialPassword?: string | null;
  mustChangePasswordOnFirstLogin?: boolean | null;
  dateNextChangePassword?: string | null;
  status?: UserStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  explicitlyRemoved?: boolean;
  employee?: IEmployee | null;
  application?: IApplication;
  lastModifiedByOrder?: IOrder | null;
  createdByOrderedUserIdentity?: IOrderedUserIdentity | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export interface IUserIdentityAccessRights extends IUserIdentity {
  userGroups: IUserGroup[];
  userIdentityRoles: IUserIdentityRole[];
  specificPermissions: ISpecificPermission[];
}

export const defaultValue: Readonly<IUserIdentity> = {
  mustChangePasswordOnFirstLogin: false,
  explicitlyRemoved: false,
};
