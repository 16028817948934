import React, { useContext } from 'react';
import { translate } from 'react-jhipster';
import { Field } from '../classes';
import { ExpressioNBuilderContext } from '../expression-builder';
import '../style.scss';
import { DropdownClickProps } from '../types';
import { newNodeByValueAndType } from '../utils';
import NodeComponent from './node.component';

interface FieldComponentProps {
  node: Field;
}

const FieldComponent = ({ node }: FieldComponentProps) => {
  const { tree, setTree } = useContext(ExpressioNBuilderContext);

  const onChangeClick = ({ value, type }: DropdownClickProps) => {
    const newNode = newNodeByValueAndType(value, type, null, null, node.expressionEntity);
    newNode.acceptedValueTypes = node.acceptedValueTypes;
    newNode.expressionEntity = node.expressionEntity;
    newNode._type = node._type;
    tree.changeNode(node, newNode);
    setTree(tree);
  };

  return (
    <NodeComponent onChange={onChangeClick} items={node.getAlternatives()} value={node.value} color="white">
      {`${translate(`iamdentityApp.${node.translatableEntityName ? node.translatableEntityName : node.entityName}.detail.title`)}: ${
        node.displayName
      }`}
    </NodeComponent>
  );
};

export default FieldComponent;
