import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ConnectorCommandAttribute from './connector-command-attribute';
import ConnectorCommandAttributeDetail from './connector-command-attribute-detail';
import ConnectorCommandAttributeUpdate from './connector-command-attribute-update';
import ConnectorCommandAttributeDeleteDialog from './connector-command-attribute-delete-dialog';

const ConnectorCommandAttributeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ConnectorCommandAttribute />} />
    <Route path="new" element={<ConnectorCommandAttributeUpdate />} />
    <Route path=":connectorCommandAtributeUpdateId">
      <Route index element={<ConnectorCommandAttributeDetail />} />
      <Route path="edit" element={<ConnectorCommandAttributeUpdate />} />
      <Route path="delete" element={<ConnectorCommandAttributeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConnectorCommandAttributeRoutes;
