import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserIdentityIamNotInSystems from './recon-session-user-identity-iam-not-in-systems';
import ReconSessionUserIdentityIamNotInSystemsDetail from './recon-session-user-identity-iam-not-in-systems-detail';
import ReconSessionUserIdentityIamNotInSystemsUpdate from './recon-session-user-identity-iam-not-in-systems-update';
import ReconSessionUserIdentityIamNotInSystemsDeleteDialog from './recon-session-user-identity-iam-not-in-systems-delete-dialog';

const ReconSessionUserIdentityIamNotInSystemsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserIdentityIamNotInSystems />} />
    <Route path="new" element={<ReconSessionUserIdentityIamNotInSystemsUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserIdentityIamNotInSystemsDetail />} />
      <Route path="edit" element={<ReconSessionUserIdentityIamNotInSystemsUpdate />} />
      <Route path="delete" element={<ReconSessionUserIdentityIamNotInSystemsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserIdentityIamNotInSystemsRoutes;
