import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileUserIdentityRole from './profile-user-identity-role';
import ProfileUserIdentityRoleDetail from './profile-user-identity-role-detail';
import ProfileUserIdentityRoleUpdate from './profile-user-identity-role-update';
import ProfileUserIdentityRoleDeleteDialog from './profile-user-identity-role-delete-dialog';

const ProfileUserIdentityRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileUserIdentityRole />} />
    <Route path="new" element={<ProfileUserIdentityRoleUpdate />} />
    <Route path=":profileUserIdentityRoleId">
      <Route index element={<ProfileUserIdentityRoleDetail />} />
      <Route path="edit" element={<ProfileUserIdentityRoleUpdate />} />
      <Route path="delete" element={<ProfileUserIdentityRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileUserIdentityRoleRoutes;
