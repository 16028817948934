import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';
import { APP_DATETIME_SAFE_FORMAT } from 'app/config/constants';
import { ITechnicalOrderAction, defaultValue } from 'app/shared/model/technical-order-action.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import saveAs from 'file-saver';
import moment from 'moment';
import { getEntities as getMyTasks } from 'app/entities/tasks/tasks.reducer';

const initialState: EntityState<ITechnicalOrderAction> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/technical-order-actions';
const apiSearchUrl = 'api/_search/technical-order-actions';

// Actions

export const searchEntities = createAsyncThunk('technicalOrderAction/search_entity', async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<ITechnicalOrderAction[]>(requestUrl);
});

export const getEntities = createAsyncThunk('technicalOrderAction/fetch_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return axios.get<ITechnicalOrderAction[]>(requestUrl);
});

export const exportEntities = createAsyncThunk('technicalOrderAction/export_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}/export${generateQueryUrl(params)}`;
  return axios.get<any>(requestUrl, {
    responseType: 'blob',
  });
});

export const cancelProcess = createAsyncThunk(
  'toa/cancel_process',
  async (toaId: string | number, thunkAPI) => {
    const requestUrl = `api/user-process/cancel?toaId=${toaId}`;
    const result = await axios.post(requestUrl);
    thunkAPI.dispatch(getEntity(toaId));
    thunkAPI.dispatch(getEntities({}));
    thunkAPI.dispatch(getMyTasks({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const getEntity = createAsyncThunk(
  'technicalOrderAction/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITechnicalOrderAction>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'technicalOrderAction/create_entity',
  async (entity: ITechnicalOrderAction, thunkAPI) => {
    const result = await axios.post<ITechnicalOrderAction>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'technicalOrderAction/update_entity',
  async (entity: ITechnicalOrderAction, thunkAPI) => {
    const result = await axios.put<ITechnicalOrderAction>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'technicalOrderAction/partial_update_entity',
  async (entity: ITechnicalOrderAction, thunkAPI) => {
    const result = await axios.patch<ITechnicalOrderAction>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'technicalOrderAction/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ITechnicalOrderAction>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const TechnicalOrderActionSlice = createEntitySlice({
  name: 'technicalOrderAction',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(exportEntities), (state, action) => {
        const { data } = action.payload;

        const filename = `technical_order_actions_${moment().format(APP_DATETIME_SAFE_FORMAT)}`;

        saveAs(data, `${filename}.xlsx`);
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(getEntities, searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, searchEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = TechnicalOrderActionSlice.actions;

// Reducer
export default TechnicalOrderActionSlice.reducer;
