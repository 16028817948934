import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import UserIdentity from './user-identity';
import UserIdentityDetail from './user-identity-detail';
import UserIdentityUpdate from './user-identity-update';
import UserIdentityDeleteDialog from './user-identity-delete-dialog';

const UserIdentityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<UserIdentity />} />
    <Route path="new" element={<UserIdentityUpdate />} />
    <Route path=":id">
      <Route index element={<UserIdentityDetail />} />
      <Route path="edit" element={<UserIdentityUpdate />} />
      <Route path="delete" element={<UserIdentityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default UserIdentityRoutes;
