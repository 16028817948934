import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { deleteEntity, getEntity } from './profile-user-identity.reducer';

export const ProfileUserIdentityDeleteDialog = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { profileUserIdentityId } = useParams<'profileUserIdentityId'>();

  const [loadModal, setLoadModal] = useState(false);

  useEffect(() => {
    dispatch(getEntity(profileUserIdentityId));
    setLoadModal(true);
  }, []);

  const profileUserIdentityEntity = useAppSelector(state => state.profileUserIdentity.entity);
  const updateSuccess = useAppSelector(state => state.profileUserIdentity.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  const confirmDelete = () => {
    dispatch(deleteEntity(profileUserIdentityEntity.id)).then(handleClose);
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="profileUserIdentityDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="iamdentityApp.profileUserIdentity.delete.question">
        <Translate contentKey="iamdentityApp.profileUserIdentity.delete.question" interpolate={{ id: profileUserIdentityEntity.id }}>
          Are you sure you want to delete this ProfileUserIdentity?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-profileUserIdentity" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Delete</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileUserIdentityDeleteDialog;
