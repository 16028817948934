import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Row, Col, Spinner } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IConnectorType } from 'app/shared/model/connector-type.model';
import { getEntities as getConnectorTypes } from 'app/entities/connector-type/connector-type.reducer';
import { IConnectorCommand } from 'app/shared/model/connector-command.model';
import { getEntity, updateEntity, createEntity, reset } from './connector-command.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';

export const ConnectorCommandUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { connectorCommandDetailId: id } = useParams<'connectorCommandDetailId'>();
  const isNew = id === undefined;

  const connectorTypes = useAppSelector(state => state.connectorType.entities);
  const connectorCommandEntity = useAppSelector(state => state.connectorCommand.entity);
  const loading = useAppSelector(state => state.connectorCommand.loading);
  const updating = useAppSelector(state => state.connectorCommand.updating);
  const updateSuccess = useAppSelector(state => state.connectorCommand.updateSuccess);

  const handleClose = () => {
    navigate(-1);
  };
  const validFrom = useRef('');
  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getConnectorTypes({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...connectorCommandEntity,
      ...values,
      connectorType: connectorTypes.find(it => it.id.toString() === values.connectorType.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? { validFrom: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          ...connectorCommandEntity,
          connectorType: connectorCommandEntity?.connectorType?.id,
        };
    validFrom.current = result?.validFrom;
    return result;
  };
  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.connectorCommand.home.createOrEditLabel" data-cy="ConnectorCommandCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.connectorCommand.home.createOrEditLabel">Create or edit a ConnectorCommand</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="connector-command-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={'*' + translate('iamdentityApp.connectorCommand.code')}
                id="connector-command-code"
                name="code"
                data-cy="code"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              <ValidatedField
                label={translate('iamdentityApp.connectorCommand.description')}
                id="connector-command-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.connectorCommand.validFrom')}
                id="connector-command-validFrom"
                name="validFrom"
                data-cy="validFrom"
                type="date"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
                onChange={event => (validFrom.current = event.target.value)}
              />
              <ValidatedField
                label={translate('iamdentityApp.connectorCommand.validTo')}
                id="connector-command-validTo"
                name="validTo"
                data-cy="validTo"
                type="date"
                min={validFrom.current}
              />
              <ValidatedField
                id="connector-command-connectorType"
                name="connectorType"
                data-cy="connectorType"
                label={translate('iamdentityApp.connectorCommand.connectorType')}
                type="select"
              >
                <option value="" key="0" />
                {connectorTypes
                  ? connectorTypes.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.name}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ConnectorCommandUpdate;
