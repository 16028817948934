import { IEmployee } from 'app/shared/model/employee.model';
import React from 'react';
import { Translate } from 'react-jhipster';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import '../style.scss';
import { Link } from 'react-router-dom';

interface IEmployeeCardProps {
  employee: IEmployee;
}

const EmployeeCard = ({ employee }: IEmployeeCardProps) => {
  return (
    <Card color="default" inverse>
      <CardHeader>
        <Translate contentKey="iamdentityApp.tasks.employee">Employee</Translate> :
      </CardHeader>
      <CardBody>
        <Link to={`/employee/${employee?.id}`}>
          {employee?.code} - {employee?.fullName}
        </Link>
      </CardBody>
    </Card>
  );
};

export default EmployeeCard;
