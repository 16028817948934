import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IOrder } from 'app/shared/model/order.model';
import { IRecertificationSessionUserRole } from './recertification-session-user-role.model';

export interface IRecertificationSessionUserIdentity {
  id?: number;
  revoked?: boolean | null;
  recertificationSession?: IRecertificationSession;
  employee?: IEmployee;
  userIdentity?: IUserIdentity;
  order?: IOrder | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  recertificationSessionUserRoles?: IRecertificationSessionUserRole[] | null;
}

export const defaultValue: Readonly<IRecertificationSessionUserIdentity> = {
  revoked: false,
};
