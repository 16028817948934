import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ExpressionBuilderSandbox from '../shared/components/expression-builder';
import Application from './application';
import ApplicationResource from './application-resource';
import ConnectorType from './connector-type';
import CustomAttributeDefinition from './custom-attribute-definition';
import Employee from './employee';
import EmployeeDelegation from './employee-delegation';
import IAMSystem from './iam-system';
import JobDefinition from './job-definition';
import Location from './location';
import OrderComments from './order-comments';
import OrderType from './order-type';
import OrderedGroup from './ordered-group';
import OrderedRole from './ordered-role';
import OrderedSpecificPermission from './ordered-specific-permission';
import OrderedUserIdentity from './ordered-user-identity';
import Orders from './orders';
import OrganizationUnit from './organization-unit';
import OrganizationUnitType from './organization-unit-type';
import PermissionDefinition from './permission-definition';
import Person from './person';
import ProfileDefinition from './profile-definition';
import ProfileSpecificPermission from './profile-specific-permission';
import ProfileUserGroup from './profile-user-group';
import ProfileUserIdentity from './profile-user-identity';
import ProfileUserIdentityRole from './profile-user-identity-role';
import RoleDefinition from './role-definition';
import RoleGroup from './role-group';
import SpecificPermission from './specific-permission';
import StandardPolicyRule from './standard-policy-rule';
import UserGroup from './user-group';
import UserIdentity from './user-identity';
import UserIdentityRole from './user-identity-role';
import OrderApprovalLevels from './order-approval-levels';
import MyTasks from './tasks';
import ConnectorCommand from './connector-command';
import ConnectorCommandAttribute from './connector-command-attribute';
import TechnicalOrderAction from './technical-order-action';
import TechnicalOrderActionAttribute from './technical-order-action-attribute';
import TechnicalOrderActionCreate from './technical-order-action-create';
import RiskRule from './risk-rule';
import RiskCombination from './risk-combination';
import Project from './project';
import ProjectAssignment from './project-assignment';
import EmailTemplate from './email-template';
import MyProfile from './my-profile/my-profile';
import ImportSession from './import-session';
import InUserIdentity from './in-user-identity';
import InUserIdentityRole from './in-user-identity-role';
import InUserGroup from './in-user-group';
import InSpecificPermission from './in-specific-permission';
import InEmployee from './in-employee';
import InOrganizationUnit from './in-organization-unit';
import InLocation from './in-location';
import InEmployeeDelegation from './in-employee-delegation';
import InProject from './in-project';
import InProjectAssignment from './in-project-assignment';
import InJobDefinition from './in-job-definition';
import ReconSessionUserIdentityIamNotInSystems from './recon-session-user-identity-iam-not-in-systems';
import ReconSessionUserIdentityRoleIamNotInSystems from './recon-session-user-identity-role-iam-not-in-systems';
import ReconSessionUserGroupIamNotInSystems from './recon-session-user-group-iam-not-in-systems';
import ReconSessionUserPermissionIamNotInSystems from './recon-session-user-permission-iam-not-in-systems';
import ReconSessionUserIdentitySystemsNotInIam from './recon-session-user-identity-systems-not-in-iam';
import ReconSessionUserIdentityRoleSystemsNotInIam from './recon-session-user-identity-role-systems-not-in-iam';
import ReconSessionUserGroupSystemsNotInIam from './recon-session-user-group-systems-not-in-iam';
import ReconSessionUserPermissionSystemsNotInIam from './recon-session-user-permission-systems-not-in-iam';
import RecertificationSession from './recertification-session';
import RecertificationSessionUserIdentity from './recertification-session-user-identity';
import RecertificationSessionUserRole from './recertification-session-user-role';
import RecertificationSessionUserGroup from './recertification-session-user-group';
import RecertificationSessionSpecificPermission from './recertification-session-specific-permission';
import IAMdentityAccessToOrderTypes from './iamdentity-access-to-order-types';
import InAdditionalAttribute from './in-additional-attribute/in-additional-attribute';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="person/*" element={<Person />} />
        <Route path="location/*" element={<Location />} />
        <Route path="organization-unit-type/*" element={<OrganizationUnitType />} />
        <Route path="organization-unit/*" element={<OrganizationUnit />} />
        <Route path="job-definition/*" element={<JobDefinition />} />
        <Route path="employee/*" element={<Employee />} />
        <Route path="custom-attribute-definition/*" element={<CustomAttributeDefinition />} />
        <Route path="connector-type/*" element={<ConnectorType />} />
        <Route path="iam-system/*" element={<IAMSystem />} />
        <Route path="application/*" element={<Application />} />
        <Route path="application-resource/*" element={<ApplicationResource />} />
        <Route path="role-definition/*" element={<RoleDefinition />} />
        <Route path="permission-definition/*" element={<PermissionDefinition />} />
        <Route path="user-identity/*" element={<UserIdentity />} />
        <Route path="role-group/*" element={<RoleGroup />} />
        <Route path="user-group/*" element={<UserGroup />} />
        <Route path="user-identity-role/*" element={<UserIdentityRole />} />
        <Route path="specific-permission/*" element={<SpecificPermission />} />
        <Route path="order-type/*" element={<OrderType />} />
        <Route path="orders/*" element={<Orders />} />
        <Route path="order-comments/*" element={<OrderComments />} />
        <Route path="ordered-user-identity/*" element={<OrderedUserIdentity />} />
        <Route path="ordered-group/*" element={<OrderedGroup />} />
        <Route path="ordered-role/*" element={<OrderedRole />} />
        <Route path="ordered-specific-permission/*" element={<OrderedSpecificPermission />} />
        <Route path="expression-builder/*" element={<ExpressionBuilderSandbox />} />
        <Route path="profile-definition/*" element={<ProfileDefinition />} />
        <Route path="profile-user-identity/*" element={<ProfileUserIdentity />} />
        <Route path="profile-user-identity-role/*" element={<ProfileUserIdentityRole />} />
        <Route path="profile-user-group/*" element={<ProfileUserGroup />} />
        <Route path="profile-specific-permission/*" element={<ProfileSpecificPermission />} />
        <Route path="employee-delegation/*" element={<EmployeeDelegation />} />
        <Route path="standard-policy-rule/*" element={<StandardPolicyRule />} />
        <Route path="order-approval-levels/*" element={<OrderApprovalLevels />} />
        <Route path="my-tasks/*" element={<MyTasks />} />
        <Route path="connector-command/*" element={<ConnectorCommand />} />
        <Route path="connector-command-attribute/*" element={<ConnectorCommandAttribute />} />
        <Route path="technical-order-action/*" element={<TechnicalOrderAction />} />
        <Route path="technical-order-action-attribute/*" element={<TechnicalOrderActionAttribute />} />
        <Route path="technical-order-action-create/*" element={<TechnicalOrderActionCreate />} />
        <Route path="risk-rule/*" element={<RiskRule />} />
        <Route path="risk-combination/*" element={<RiskCombination />} />
        <Route path="project/*" element={<Project />} />
        <Route path="project-assignment/*" element={<ProjectAssignment />} />
        <Route path="email-template/*" element={<EmailTemplate />} />
        <Route path="my-profile/*" element={<MyProfile />} />
        <Route path="import-session/*" element={<ImportSession />} />
        <Route path="in-user-identity/*" element={<InUserIdentity />} />
        <Route path="in-user-identity-role/*" element={<InUserIdentityRole />} />
        <Route path="in-user-group/*" element={<InUserGroup />} />
        <Route path="in-specific-permission/*" element={<InSpecificPermission />} />
        <Route path="in-employee/*" element={<InEmployee />} />
        <Route path="in-organization-unit/*" element={<InOrganizationUnit />} />
        <Route path="in-location/*" element={<InLocation />} />
        <Route path="in-employee-delegation/*" element={<InEmployeeDelegation />} />
        <Route path="in-project/*" element={<InProject />} />
        <Route path="in-project-assignment/*" element={<InProjectAssignment />} />
        <Route path="in-job-definition/*" element={<InJobDefinition />} />
        <Route path="in-additional-attribute/*" element={<InAdditionalAttribute />} />
        <Route path="recon-session-user-identity-iam-not-in-systems/*" element={<ReconSessionUserIdentityIamNotInSystems />} />
        <Route path="recon-session-user-identity-role-iam-not-in-systems/*" element={<ReconSessionUserIdentityRoleIamNotInSystems />} />
        <Route path="recon-session-user-group-iam-not-in-systems/*" element={<ReconSessionUserGroupIamNotInSystems />} />
        <Route path="recon-session-user-permission-iam-not-in-systems/*" element={<ReconSessionUserPermissionIamNotInSystems />} />
        <Route path="recon-session-user-identity-systems-not-in-iam/*" element={<ReconSessionUserIdentitySystemsNotInIam />} />
        <Route path="recon-session-user-identity-role-systems-not-in-iam/*" element={<ReconSessionUserIdentityRoleSystemsNotInIam />} />
        <Route path="recon-session-user-group-systems-not-in-iam/*" element={<ReconSessionUserGroupSystemsNotInIam />} />
        <Route path="recon-session-user-permission-systems-not-in-iam/*" element={<ReconSessionUserPermissionSystemsNotInIam />} />
        <Route path="recertification-session/*" element={<RecertificationSession />} />
        <Route path="recertification-session-user-identity/*" element={<RecertificationSessionUserIdentity />} />
        <Route path="recertification-session-user-role/*" element={<RecertificationSessionUserRole />} />
        <Route path="recertification-session-user-group/*" element={<RecertificationSessionUserGroup />} />
        <Route path="recertification-session-specific-permission/*" element={<RecertificationSessionSpecificPermission />} />
        <Route path="iamdentity-access-to-order-types/*" element={<IAMdentityAccessToOrderTypes />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
