import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RecertificationSessionSpecificPermission from './recertification-session-specific-permission';
import RecertificationSessionSpecificPermissionDetail from './recertification-session-specific-permission-detail';
import RecertificationSessionSpecificPermissionUpdate from './recertification-session-specific-permission-update';
import RecertificationSessionSpecificPermissionDeleteDialog from './recertification-session-specific-permission-delete-dialog';

const RecertificationSessionSpecificPermissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RecertificationSessionSpecificPermission />} />
    <Route path="new" element={<RecertificationSessionSpecificPermissionUpdate />} />
    <Route path=":id">
      <Route index element={<RecertificationSessionSpecificPermissionDetail />} />
      <Route path="edit" element={<RecertificationSessionSpecificPermissionUpdate />} />
      <Route path="delete" element={<RecertificationSessionSpecificPermissionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecertificationSessionSpecificPermissionRoutes;
