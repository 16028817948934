import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrder } from 'app/shared/model/order.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IApplication } from './application.model';
import { IUserGroup } from './user-group.model';

export interface IOrderedGroup {
  id?: number;
  grantedBy?: PermissionGrantLevel | null;
  executionStatus?: OrderExecutionStatus | null;
  executionResult?: string | null;
  scheduleTime?: string | null;
  executionStartTime?: string | null;
  executionEndTime?: string | null;
  executionEstimatedDuration?: number | null;
  executionActualDuration?: number | null;
  businessValidityStart?: string | null;
  businessValidityEnd?: string | null;
  returnedBusinessValidityStart?: string | null;
  returnedBusinessValidityEnd?: string | null;
  order?: IOrder | null;
  userIdentity?: IUserIdentity | null;
  orderedUserIdentity?: IOrderedUserIdentity | null;
  roleGroup?: IRoleGroup | null;
  operationType?: string | null;
  application?: IApplication | null;
  dependsOn?: IOrderedGroup | null;
  first?: boolean;
  relatedUserGroup?: IUserGroup | null;
  originalOrderedGroup?: IOrderedGroup | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IOrderedGroup> = {};
