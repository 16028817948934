import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './in-project.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';

export const InProjectDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const inProjectEntity = useAppSelector(state => state.inProject.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <Row>
      <Col md="12">
        <Card className="jh-card">
          <h2 data-cy="inProjectDetailsHeading">
            <Translate contentKey="iamdentityApp.inProject.detail.title">InProject</Translate>
          </h2>
          <dl className="jh-entity-details">
            <dt>
              <span id="name">
                <Translate contentKey="iamdentityApp.inProject.name">Name</Translate>
              </span>
            </dt>
            <dd>{inProjectEntity.name}</dd>
            <dt>
              <span id="code">
                <Translate contentKey="iamdentityApp.inProject.code">Code</Translate>
              </span>
            </dt>
            <dd>{inProjectEntity.code}</dd>
            <dt>
              <span id="startDate">
                <Translate contentKey="iamdentityApp.inProject.startDate">Start Date</Translate>
              </span>
            </dt>
            <dd>
              {inProjectEntity.startDate ? (
                <TextFormat value={inProjectEntity.startDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
              ) : null}
            </dd>
            <dt>
              <span id="endDate">
                <Translate contentKey="iamdentityApp.inProject.endDate">End Date</Translate>
              </span>
            </dt>
            <dd>
              {inProjectEntity.endDate ? <TextFormat value={inProjectEntity.endDate} type="date" format={APP_LOCAL_DATE_FORMAT} /> : null}
            </dd>
            <dt>
              <span id="projectManager">
                <Translate contentKey="iamdentityApp.inProject.projectManager">Project Manager</Translate>
              </span>
            </dt>
            <dd>{inProjectEntity.projectManager}</dd>
            {customAttributeDefinitions?.map(cad => (
              <React.Fragment key={cad.id}>
                <dt>
                  <span id={cad.displayName}>{cad.displayName}</span>
                </dt>
                <dd>{findCustomAttributeValueByKey(cad, inProjectEntity, locale)}</dd>
              </React.Fragment>
            ))}
          </dl>
          <Button style={{ width: 'fit-content' }} tag={Link} to="/in-project" replace color="info" data-cy="entityDetailsBackButton">
            <FontAwesomeIcon icon="arrow-left" />{' '}
            <span className="d-none d-md-inline">
              <Translate contentKey="entity.action.back">Back</Translate>
            </span>
          </Button>
        </Card>
      </Col>
    </Row>
  );
};

export default InProjectDetail;
