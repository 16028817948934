import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { IOrderedUserIdentity } from 'app/shared/model/ordered-user-identity.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './ordered-user-identity.reducer';
import { getEntities } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';

interface ISearchParamsObject {
  editDisabled: boolean;
}

export const OrderedUserIdentityDetail = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [auditModal, setAuditModal] = useState<boolean>(false);
  const { orderedUserIdentityId: id } = useParams<'orderedUserIdentityId'>();
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState<ISearchParamsObject>(buildSearchParamsObject(searchParams));

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getEntities({ query }));
  }, []);

  const orderedUserIdentityEntity: IOrderedUserIdentity = useAppSelector(state => state.orderedUserIdentity.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card pad-1">
          <Col md="8">
            <h2 data-cy="orderedUserIdentityDetailsHeading">
              <Translate contentKey="iamdentityApp.orderedUserIdentity.detail.title">OrderedUserIdentity</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.id}</dd>
              <dt>
                <span id="userName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.userName">User Name</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.userName}</dd>
              <dt>
                <span id="alternativeUserName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.alternativeUserName">Alternative User Name</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.alternativeUserName}</dd>
              <dt>
                <span id="associatedEmailAddress">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.associatedEmailAddress">Associated Email Address</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.associatedEmailAddress}</dd>
              <dt>
                <span id="displayName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.displayName">Display Name</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.displayName}</dd>
              <dt>
                <span id="initialPassword">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.initialPassword">Initial Password</Translate>
                </span>
              </dt>
              <dd>*****</dd>
              <dt>
                <span id="mustChangePasswordOnFirstLogin">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin">
                    Must Change Password On First Login
                  </Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.mustChangePasswordOnFirstLogin ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="dateNextChangePassword">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.dateNextChangePassword">Date Next Change Password</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.dateNextChangePassword ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.dateNextChangePassword}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="returnedUserName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedUserName">Returned User Name</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.returnedUserName}</dd>
              <dt>
                <span id="returnedAlternativeUserName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedAlternativeUserName">
                    Returned Alternative User Name
                  </Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.returnedAlternativeUserName}</dd>
              <dt>
                <span id="returnedAssociatedEmailAddress">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedAssociatedEmailAddress">
                    Returned Associated Email Address
                  </Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.returnedAssociatedEmailAddress}</dd>
              <dt>
                <span id="returnedDisplayName">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedDisplayName">Returned Display Name</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.returnedDisplayName}</dd>
              <dt>
                <span id="returnedInitialPassword">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedInitialPassword">Returned Initial Password</Translate>
                </span>
              </dt>
              <dd>*****</dd>
              <dt>
                <span id="returnedMustChangePasswordOnFirstLogin">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin">
                    Returned Must Change Password On First Login
                  </Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.returnedMustChangePasswordOnFirstLogin ? translate('global.yes') : translate('global.no')}
              </dd>
              <dt>
                <span id="returnedDateNextChangePassword">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedDateNextChangePassword">
                    Returned Date Next Change Password
                  </Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.returnedDateNextChangePassword ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.returnedDateNextChangePassword}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="grantedBy">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.grantedBy">Granted By</Translate>
                </span>
              </dt>
              <dd>{translate(`iamdentityApp.PermissionGrantLevel.${orderedUserIdentityEntity.grantedBy}`)}</dd>
              <dt>
                <span id="executionStatus">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionStatus">Execution Status</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.executionStatus}</dd>
              <dt>
                <span id="executionResult">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionResult">Execution Result</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.executionResult}</dd>
              <dt>
                <span id="scheduleTime">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.scheduleTime">Schedule Time</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.scheduleTime ? (
                  <TextFormat value={orderedUserIdentityEntity.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <span id="executionStartTime">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionStartTime">Execution Start Time</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.executionStartTime ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.executionStartTime}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="executionEndTime">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionEndTime">Execution End Time</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.executionEndTime ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.executionEndTime}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="executionEstimatedDuration">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionEstimatedDuration">
                    Execution Estimated Duration
                  </Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.executionEstimatedDuration}</dd>
              <dt>
                <span id="executionActualDuration">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.executionActualDuration">Execution Actual Duration</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.executionActualDuration}</dd>
              <dt>
                <span id="businessValidityStart">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.businessValidityStart">Business Validity Start</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.businessValidityStart ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.businessValidityStart}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="businessValidityEnd">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.businessValidityEnd">Business Validity End</Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity.businessValidityEnd ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.businessValidityEnd}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="returnedBusinessValidityStart">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedBusinessValidityStart">
                    Returned Business Validity Start
                  </Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity.returnedBusinessValidityStart ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.returnedBusinessValidityStart}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="returnedBusinessValidityEnd">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedBusinessValidityEnd">
                    Returned Business Validity End
                  </Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity.returnedBusinessValidityEnd ? (
                  <TextFormat
                    value={orderedUserIdentityEntity.returnedBusinessValidityEnd}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
              <dt>
                <span id="retrieveUserNameFromDependentOrderedUserIdentity">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.retrieveUserNameFromDependentOrderedUserIdentity">
                    Retrieve User Name From Dependent Ordered User Identity
                  </Translate>
                </span>
              </dt>
              <dd>
                {orderedUserIdentityEntity?.retrieveUserNameFromDependentOrderedUserIdentity
                  ? translate('global.yes')
                  : translate('global.no')}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.orders">Orders</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity?.order ? orderedUserIdentityEntity.order.id : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.application">Application</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity?.application ? orderedUserIdentityEntity.application.name : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.employee">Employee</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity?.employee ? orderedUserIdentityEntity.employee.code : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOn">Depends On</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity?.dependsOn ? orderedUserIdentityEntity.dependsOn.userName : ''}</dd>
              <dt>
                <span id="operationType">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.operationType">Operation Type</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity?.operationType}</dd>
              <dt>
                <span id="userSuspended">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.userSuspended">User Suspended</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity.userSuspended ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <span id="returnedUserSuspended">
                  <Translate contentKey="iamdentityApp.orderedUserIdentity.returnedUserSuspended">Returned User Suspended</Translate>
                </span>
              </dt>
              <dd>{orderedUserIdentityEntity.returnedUserSuspended ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.dependsOnExisting">Depends On Existing</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity.dependsOnExisting ? orderedUserIdentityEntity.dependsOnExisting.userName : ''}</dd>
              {customAttributeDefinitions?.map((cad, index) => (
                <React.Fragment key={index}>
                  <dt>
                    <span id={`${cad.id}`}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, orderedUserIdentityEntity, locale)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.originalOrderedUserIdentity">
                  Original Ordered User Identity
                </Translate>
              </dt>
              <dd>
                {orderedUserIdentityEntity.originalOrderedUserIdentity ? orderedUserIdentityEntity.originalOrderedUserIdentity.id : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity.createdBy ? orderedUserIdentityEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {orderedUserIdentityEntity.createdDate ? (
                  <TextFormat value={orderedUserIdentityEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{orderedUserIdentityEntity.lastModifiedBy ? orderedUserIdentityEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {orderedUserIdentityEntity.lastModifiedDate ? (
                  <TextFormat value={orderedUserIdentityEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              {/* Comentat deoarece este posibil sa revenim la acest buton de "edit" */}
              {/* {!searchParamsObject.editDisabled ? (
                <Button tag={Link} to={`/ordered-user-identity/${orderedUserIdentityEntity.id}/edit`} color="primary">
                  <FontAwesomeIcon icon="pencil-alt" />{' '}
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.edit">Edit</Translate>
                  </span>
                </Button>
              ) : null} */}
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.ORDERED_USER_IDENTITY} />
    </React.Fragment>
  );
};

export default OrderedUserIdentityDetail;
