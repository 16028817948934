import { ValueType } from '../components/expression-builder/types';

export enum ExpressionEntity {
  OBJECT = 'OBJECT',
  ARRAY = 'ARRAY',
}

export interface IExpressionEntity {
  entity?: string;
  className?: string;
  type?: ExpressionEntity;
  properties?: IExpressionField[];
  customAttributes?: IExpressionField[];
}

export interface IExpressionField {
  name: string;
  value: string;
  type: ValueType;
  expressionEntity: ExpressionEntity;
}

export const defaultValue: Readonly<IExpressionEntity> = {};
