import React from 'react';
import ModalContainer from './modal';
import { useModalState } from './modal.hook';

interface IModalContext {
  visible: boolean;
  title: string;
  body: any;
  callbackRef: React.MutableRefObject<any> | null;
  size: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'FULLSCREEN';
  showFooter: boolean;
  hide: () => void;
  show: (props: {
    title: string;
    body: any;
    callback?: Function;
    size?: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'FULLSCREEN';
    showFooter?: boolean;
  }) => void;
}

export const ModalContext = React.createContext<IModalContext>({
  hide: () => null,
  show: () => null,
  visible: false,
  title: '',
  body: '',
  callbackRef: null,
  showFooter: true,
  size: 'SMALL',
});

export const ModalProvider = ({ children }: { children: any }) => {
  const { visible, title, body, callbackRef, showFooter, size, hide, show } = useModalState();

  return (
    <ModalContext.Provider value={{ visible, title, body, callbackRef, showFooter, size, hide, show }}>
      <ModalContainer />
      {children}
    </ModalContext.Provider>
  );
};
