import React from 'react';
import { ICompactNode, NodeType } from './types';

/* interface VerboseNodeProps {
  node: ICompactNode;
  depth: number;
  truncate?: boolean;
}

function VerboseNode({ node, depth, truncate }: VerboseNodeProps) {
  if (node?.type == NodeType._IF || node?.type == NodeType._THEN || node?.type == NodeType._ELSE || node?.type == NodeType._SWITCH) {
    return (
      <span>
        <Badge color="danger">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
        {node.nodes.map((n, index) => (
          <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
        ))}
      </span>
    );
  } else if (node?.type == NodeType._CONDITIONAL) {
    return (
      <span>
        {node.nodes.map((n, index) => (
          <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
        ))}
      </span>
    );
  } else if (node?.type == NodeType._OPERATORS) {
    return (
      <span>
        {' ( '}
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment>
              <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? (
                <Badge color="primary">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
              ) : null}
            </React.Fragment>
          );
        })}
        {' ) '}
      </span>
    );
  } else if (node?.type == NodeType._FUNCTIONS) {
    return (
      <span>
        <Badge color="warning">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
        {' ( '}
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment>
              <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? <span>,</span> : null}
            </React.Fragment>
          );
        })}
        {' ) '}
      </span>
    );
  } else if (node?.type == NodeType._AGGREGATORS) {
    return (
      <span>
        {node.nodes.map((n, index) => {
          return (
            <React.Fragment>
              <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
              {index < node.nodes.length - 1 ? (
                <span>
                  {' '}
                  <Badge color="primary">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>{' '}
                </span>
              ) : null}
            </React.Fragment>
          );
        })}
      </span>
    );
  } else if (node?.type == NodeType._CONDITIONS) {
    return (
      <span>
        <React.Fragment>
          {node.nodes.length > 0} <VerboseNode node={node.nodes[0]} depth={depth + 1} truncate={truncate} />
          <Badge color="warning">{translate(`iamdentityApp.expressionBuilder.${node.name}`)}</Badge>
          {node.nodes.length > 1} <VerboseNode node={node.nodes[1]} depth={depth + 1} truncate={truncate} />
        </React.Fragment>
      </span>
    );
  } else {
    return (
      <React.Fragment>
        <Badge color="secondary">{node?.value}</Badge>
        {node?.type == '_INTERMEDIARY'
          ? node?.nodes.map((n, index) => {
              return (
                <React.Fragment>
                  <VerboseNode key={`${n.name}-${index}`} node={n} depth={depth + 1} truncate={truncate} />
                </React.Fragment>
              );
            })
          : null}
      </React.Fragment>
    );
  }
}*/

type NodeStatsType = 'TREE' | 'LEAF';
type ConditionsStatsAggregator = 'AND' | 'OR';

interface NodeStatsConditions {
  no: number;
  aggregator?: ConditionsStatsAggregator;
}

interface INodeStats {
  type: NodeStatsType;
  conditions?: NodeStatsConditions;
}

const extractNodeStats = (node: ICompactNode) => {
  const nodeStats: INodeStats = {
    type: 'TREE',
  };

  if (node.type == NodeType._IF) {
    const conditionStats: NodeStatsConditions = {
      no: 0,
    };
    nodeStats.type = 'TREE';
    const conditionalNode = node.nodes.find(n => n.type == NodeType._CONDITIONAL);

    // check if exists
    //const conditionalChild = conditionalNode.nodes[0];
  }

  return nodeStats;
};

interface VerboseExpressionBuilderProps {
  tree: ICompactNode | string;
}

function VerboseExpressionBuilder({ tree }: VerboseExpressionBuilderProps) {
  if (!tree || tree === 'null') return null;

  let parsedTree = tree && typeof tree == 'string' ? JSON.parse(tree) : tree;

  const stats = extractNodeStats(parsedTree);

  return <div>Verbose</div>;
}

export default VerboseExpressionBuilder;
