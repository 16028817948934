import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { findCustomAttributeValueByKey } from 'app/shared/util/entity-utils';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './job-definition.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const JobDefinitionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [auditModal, setAuditModal] = useState<boolean>(false);

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.JOB_DEFINITION));
  }, []);

  const jobDefinitionEntity = useAppSelector(state => state.jobDefinition.entity);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const locale = useAppSelector(state => state.locale.currentLocale);

  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };

  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="jobDefinitionDetailsHeading">
              <Translate contentKey="iamdentityApp.jobDefinition.detail.title">JobDefinition</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{jobDefinitionEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.jobDefinition.name">Name</Translate>
                </span>
              </dt>
              <dd>{jobDefinitionEntity.name}</dd>
              <dt>
                <span id="internalCode">
                  <Translate contentKey="iamdentityApp.jobDefinition.internalCode">Internal Code</Translate>
                </span>
              </dt>
              <dd>{jobDefinitionEntity.internalCode}</dd>
              <dt>
                <span id="publicCode">
                  <Translate contentKey="iamdentityApp.jobDefinition.publicCode">Public Code</Translate>
                </span>
              </dt>
              <dd>{jobDefinitionEntity.publicCode}</dd>
              <dt>
                <span id="startDate">
                  <Translate contentKey="iamdentityApp.jobDefinition.startDate">Start Date</Translate>
                </span>
              </dt>
              <dd>
                {jobDefinitionEntity.startDate ? (
                  <TextFormat value={jobDefinitionEntity.startDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="endDate">
                  <Translate contentKey="iamdentityApp.jobDefinition.endDate">End Date</Translate>
                </span>
              </dt>
              <dd>
                {jobDefinitionEntity.endDate ? (
                  <TextFormat value={jobDefinitionEntity.endDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              {customAttributeDefinitions?.map(cad => (
                <React.Fragment>
                  <dt>
                    <span id={cad.displayName}>{cad.displayName}</span>
                  </dt>
                  <dd>{findCustomAttributeValueByKey(cad, jobDefinitionEntity, locale)}</dd>
                </React.Fragment>
              ))}
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{jobDefinitionEntity.createdBy ? jobDefinitionEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {jobDefinitionEntity.createdDate ? (
                  <TextFormat value={jobDefinitionEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{jobDefinitionEntity.lastModifiedBy ? jobDefinitionEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {jobDefinitionEntity.lastModifiedDate ? (
                  <TextFormat value={jobDefinitionEntity.lastModifiedDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
            </dl>
            <div className="btn-group flex-btn-group-container">
              <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              <Button tag={Link} to={`/job-definition/${jobDefinitionEntity.id}/edit`} color="primary">
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
              <Button onClick={toggleAuditModal} color="light">
                <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.history">History</Translate>
                </span>
              </Button>
            </div>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.JOB_DEFINITION} />
    </React.Fragment>
  );
};

export default JobDefinitionDetail;
