import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IApplication } from 'app/shared/model/application.model';
import { getEntities as getApplications } from 'app/entities/application/application.reducer';
import { IEmployee } from 'app/shared/model/employee.model';
import { getEntities as getEmployees } from 'app/entities/employee/employee.reducer';
import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import { RecertificationSessionStatus } from 'app/shared/model/enumerations/recertification-session-status.model';
import { getEntity, updateEntity, createEntity, reset } from './recertification-session.reducer';

export const RecertificationSessionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const applications = useAppSelector(state => state.application.entities);
  const employees = useAppSelector(state => state.employee.entities);
  const recertificationSessionEntity = useAppSelector(state => state.recertificationSession.entity);
  const loading = useAppSelector(state => state.recertificationSession.loading);
  const updating = useAppSelector(state => state.recertificationSession.updating);
  const updateSuccess = useAppSelector(state => state.recertificationSession.updateSuccess);
  const recertificationSessionStatusValues = Object.keys(RecertificationSessionStatus);

  const handleClose = () => {
    navigate('/recertification-session' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getApplications(''));
    dispatch(getEmployees({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...recertificationSessionEntity,
      ...values,
      application: applications.find(it => it.id.toString() === values.application.toString()),
      manager: employees.find(it => it.id.toString() === values.manager.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          status: 'IN_PROCESS',
          ...recertificationSessionEntity,
          application: recertificationSessionEntity?.application?.id,
          manager: recertificationSessionEntity?.manager?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.recertificationSession.home.createOrEditLabel" data-cy="RecertificationSessionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.recertificationSession.home.createOrEditLabel">
              Create or edit a RecertificationSession
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="recertification-session-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('iamdentityApp.recertificationSession.name')}
                id="recertification-session-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.recertificationSession.status')}
                id="recertification-session-status"
                name="status"
                data-cy="status"
                type="select"
              >
                {recertificationSessionStatusValues.map(recertificationSessionStatus => (
                  <option value={recertificationSessionStatus} key={recertificationSessionStatus}>
                    {translate('iamdentityApp.RecertificationSessionStatus.' + recertificationSessionStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                id="recertification-session-application"
                name="application"
                data-cy="application"
                label={translate('iamdentityApp.recertificationSession.application')}
                type="select"
              >
                <option value="" key="0" />
                {applications
                  ? applications.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.code}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="recertification-session-manager"
                name="manager"
                data-cy="manager"
                label={translate('iamdentityApp.recertificationSession.manager')}
                type="select"
                required
              >
                <option value="" key="0" />
                {employees
                  ? employees.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.code}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <FormText>
                <Translate contentKey="entity.validation.required">This field is required.</Translate>
              </FormText>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/recertification-session" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default RecertificationSessionUpdate;
