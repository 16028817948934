import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

import { APP_DATETIME_SAFE_FORMAT } from 'app/config/constants';
import { defaultValue, IApplication } from 'app/shared/model/application.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import saveAs from 'file-saver';
import moment from 'moment';

const REDUCER_NAME = 'application';

const initialState: EntityState<IApplication> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  lastUrl: null,
};

export const apiUrl = 'api/applications';
const apiSearchUrl = 'api/_search/applications';

// Actions
export const fetchEntityList = (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return getEntities(requestUrl);
};

export const getEntitiesByProfileDefinitionId = (profileDefinitionId: number) => {
  const requestUrl = `${apiUrl}/profile-definition/${profileDefinitionId}`;
  return getEntities(requestUrl);
};

export const getEntitiesByOrderId = (orderId: number) => {
  const requestUrl = `${apiUrl}/order/${orderId}`;
  return getEntities(requestUrl);
};

export const getEntitiesByEmployeeId = (employeeId: number) => {
  const requestUrl = `${apiUrl}/employee/${employeeId}`;
  return getEntities(requestUrl);
};

export const searchEntities = createAsyncThunk(`${REDUCER_NAME}/search_entity`, async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IApplication[]>(requestUrl);
});

export const getEntities = createAsyncThunk(`${REDUCER_NAME}/fetch_entity_list`, async (requestUrl: string, thunkAPI) => {
  const url = requestUrl && requestUrl != '' ? requestUrl : thunkAPI.getState()[REDUCER_NAME].lastUrl ?? apiUrl ?? apiUrl;
  return axios.get<IApplication[]>(url);
});

export const exportEntities = createAsyncThunk(`${REDUCER_NAME}/export_entity_list`, async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}/export${generateQueryUrl(params)}`;
  return axios.get<any>(requestUrl, {
    responseType: 'blob',
  });
});

export const getEntity = createAsyncThunk(
  `${REDUCER_NAME}/fetch_entity`,
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IApplication>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  `${REDUCER_NAME}/create_entity`,
  async (entity: IApplication, thunkAPI) => {
    const result = await axios.post<IApplication>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(''));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  `${REDUCER_NAME}/update_entity`,
  async (entity: IApplication, thunkAPI) => {
    const result = await axios.put<IApplication>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(''));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  `${REDUCER_NAME}/partial_update_entity`,
  async (entity: IApplication, thunkAPI) => {
    const result = await axios.patch<IApplication>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities(''));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  `${REDUCER_NAME}/delete_entity`,
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IApplication>(requestUrl);
    thunkAPI.dispatch(getEntities(''));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ApplicationSlice = createEntitySlice({
  name: REDUCER_NAME,
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(exportEntities), (state, action) => {
        const { data } = action.payload;

        const filename = `applications_${moment().format(APP_DATETIME_SAFE_FORMAT)}`;

        saveAs(data, `${filename}.xlsx`);
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(getEntities, searchEntities), (state, action) => {
        const { data, headers, config } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
          lastUrl: config.url,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, searchEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, resetEntity } = ApplicationSlice.actions;

// Reducer
export default ApplicationSlice.reducer;
