import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import JobDefinition from './job-definition';
import JobDefinitionDetail from './job-definition-detail';
import JobDefinitionUpdate from './job-definition-update';
import JobDefinitionDeleteDialog from './job-definition-delete-dialog';

const JobDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<JobDefinition />} />
    <Route path="new" element={<JobDefinitionUpdate />} />
    <Route path=":id">
      <Route index element={<JobDefinitionDetail />} />
      <Route path="edit" element={<JobDefinitionUpdate />} />
      <Route path="delete" element={<JobDefinitionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default JobDefinitionRoutes;
