import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Row, Spinner, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  addCustomPropertiesToCustomAttributesMap,
  convertReactSelectValuesToEntityIds,
  extractCustomAttributesAsEntityProperties,
} from 'app/shared/util/entity-utils';

import { APP_GLOBAL_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { getEntitiesByObjectType as getCustomPropertiesByObjectType } from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { getEntities as getIAmSystems } from 'app/entities/iam-system/iam-system.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { RoleRequirementsPerApplication } from 'app/shared/model/enumerations/role-requirements-per-application.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import moment from 'moment';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, resetEntity, updateEntity } from './application.reducer';

import { apiUrl as iamSystemUrl } from 'app/entities/iam-system/iam-system.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import UncontrolledReactSelect from 'app/shared/components/react-select/uncontrolled-react-select';
import { IApplication } from 'app/shared/model/application.model';
import { apiUrl as usersUrl } from 'app/shared/reducers/user-management';
import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';

export const ApplicationUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const applications = useAppSelector(state => state.application.entities);
  const { applicationId: id } = useParams<'applicationId'>();
  const isNew = id === undefined;

  const applicationEntity: IApplication = useAppSelector(state => state.application.entity);
  const loading = useAppSelector(state => state.application.loading);
  const updating = useAppSelector(state => state.application.updating);
  const updateSuccess = useAppSelector(state => state.application.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const roleRequirementsPerApplicationValues = Object.keys(RoleRequirementsPerApplication);
  const [toggleModal, setToggleModal] = useState(false);

  const handleClose = () => {
    navigate(-1);
  };

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getIAmSystems({}));
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.APPLICATION));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [applicationEntity]);

  const locale = useAppSelector(state => state.locale.currentLocale);

  const saveEntity = values => {
    const entity = {
      ...applicationEntity,
      ...addCustomPropertiesToCustomAttributesMap(
        applicationEntity,
        convertReactSelectValuesToEntityIds(values),
        customAttributeDefinitions
      ),
      adminUsers: values?.adminUsers ? values.adminUsers.map(user => user?.login).join(',') : '',
      startDate: values.startDate ? moment(values.startDate).format('YYYY-MM-DD') : null, // Format startDate for server
      endDate: values.endDate ? moment(values.endDate).format('YYYY-MM-DD') : null, // Format endDate for server
      roleRequirements: watchRoleRequired === true ? values?.roleRequirements : null,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () => {
    const result = isNew
      ? {
          startDate: moment(new Date(), convertDateFormat(locale, 'date')).toDate(),
          endDate: null,
        }
      : {
          ...applicationEntity,
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, applicationEntity),
          system: applicationEntity?.system,
          adminUsers: applicationEntity?.adminUsersList?.length
            ? applicationEntity?.adminUsersList
            : applicationEntity?.adminUsers
            ? applicationEntity?.adminUsers.split(',').map(userId => ({
                id: userId,
                employee: {
                  fullName: '',
                },
              }))
            : '',
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchStartDate = watch('startDate');
  const watchEndDate = watch('endDate');
  const watchConnectorTopic: string = watch('connectorTopic');
  const watchSystem: string = watch('system');
  const watchRoleRequired = watch('roleRequired');

  useEffect(() => {
    if (watchRoleRequired && !watch('roleRequirements')) {
      setValue('roleRequirements', roleRequirementsPerApplicationValues[0]);
    }
  }, [watchRoleRequired, setValue, roleRequirementsPerApplicationValues]);

  const handleSubmitForm = (event, isModal?: boolean) => {
    event.preventDefault();
    if (!isModal) {
      if (watchSystem && !watchConnectorTopic) {
        setToggleModal(true);
      } else {
        handleSubmit(saveEntity)();
      }
    } else {
      handleSubmit(saveEntity)();
      setToggleModal(false);
    }
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.application.home.createOrEditLabel" data-cy="ApplicationCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.application.home.createOrEditLabel">Create or edit a Application</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={event => handleSubmitForm(event, false)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="application-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                required={true}
                label={`*${translate('iamdentityApp.application.name')}`}
                id="application-name"
                name="name"
                data-cy="name"
                type="text"
              />
              <ValidatedField
                register={register}
                required={true}
                label={`*${translate('iamdentityApp.application.code')}`}
                id="application-code"
                name="code"
                data-cy="code"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.description')}
                id="application-description"
                name="description"
                data-cy="description"
                type="text"
              />
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="startDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="application-startDate" className="form-label">
                        *{translate('iamdentityApp.application.startDate')}
                      </label>
                      <DatePicker
                        maxDate={
                          !isNew
                            ? applicationEntity?.startDate
                              ? moment(applicationEntity?.startDate, 'YYYY-MM-DD').toDate()
                              : null
                            : watchEndDate
                            ? moment(watchEndDate, 'YYYY-MM-DD').toDate()
                            : null
                        }
                        selected={value ? new Date(value) : new Date()}
                        onChange={date => {
                          onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                        }}
                        value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                        onBlur={onBlur}
                        locale={locale === 'ro' ? ro : 'en'}
                        todayButton={<Translate contentKey="global.today">Today</Translate>}
                        showMonthDropdown
                        showYearDropdown
                        className="form-control"
                        ref={ref}
                        id="application-startDate"
                        data-cy="startDate"
                      />
                    </div>
                  )}
                />
              </div>
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="endDate"
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="application-endDate" className="form-label">
                        {translate('iamdentityApp.application.endDate')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          disabled={!isNew && !applicationEntity?.endDate && true}
                          minDate={
                            !isNew
                              ? applicationEntity?.endDate
                                ? moment(applicationEntity?.endDate, 'YYYY-MM-DD').toDate()
                                : null
                              : watchStartDate
                              ? moment(watchStartDate, 'YYYY-MM-DD').toDate()
                              : null
                          }
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className="form-control"
                          ref={ref}
                          id="application-endDate"
                          data-cy="endDate"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              <FormGroup>
                <Label>
                  *<Translate contentKey="iamdentityApp.application.system" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="system"
                  validate={{ required: true }}
                  url={iamSystemUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.uniqueIdentityKey')}
                id="application-uniqueIdentityKey"
                name="uniqueIdentityKey"
                data-cy="uniqueIdentityKey"
                type="text"
              />
              <ValidatedField
                register={register}
                className="mb-2"
                label={translate('iamdentityApp.application.roleRequired')}
                id="application-roleRequired"
                name="roleRequired"
                data-cy="roleRequired"
                check
                type="checkbox"
              />
              {watchRoleRequired && (
                <ValidatedField
                  register={register}
                  label={translate('iamdentityApp.application.roleRequirements')}
                  id="application-roleRequirements"
                  name="roleRequirements"
                  data-cy="roleRequirements"
                  type="select"
                >
                  {roleRequirementsPerApplicationValues.map(roleRequirementsPerApplication => (
                    <option value={roleRequirementsPerApplication} key={roleRequirementsPerApplication}>
                      {translate('iamdentityApp.RoleRequirementsPerApplication.' + roleRequirementsPerApplication)}
                    </option>
                  ))}
                </ValidatedField>
              )}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.connectorTopic')}
                id="application-connectorTopic"
                name="connectorTopic"
                data-cy="connectorTopic"
                type="text"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.application.adminUsers" />
                </Label>
                <UncontrolledReactSelect
                  control={control}
                  isMulti
                  name="adminUsers"
                  url={`${usersUrl}/authority/${AUTHORITIES.USER}/all`}
                  queryProps={{
                    name: 'employee.code',
                    secondName: 'employee.fullName',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.main')}
                id="application-main"
                name="main"
                data-cy="main"
                check
                type="checkbox"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.application.userDependsOnApplication" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="userDependsOnApplication"
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                    sort: 'name,asc',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.connectorTimeout')}
                id="application-connectorTimeout"
                name="connectorTimeout"
                data-cy="connectorTimeout"
                type="number"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityStartDateOnUser')}
                id="application-applicableBusinessValidityStartDateOnUser"
                name="applicableBusinessValidityStartDateOnUser"
                data-cy="applicableBusinessValidityStartDateOnUser"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityEndDateOnUser')}
                id="application-applicableBusinessValidityEndDateOnUser"
                name="applicableBusinessValidityEndDateOnUser"
                data-cy="applicableBusinessValidityEndDateOnUser"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityStartDateOnRole')}
                id="application-applicableBusinessValidityStartDateOnRole"
                name="applicableBusinessValidityStartDateOnRole"
                data-cy="applicableBusinessValidityStartDateOnRole"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityEndDateOnRole')}
                id="application-applicableBusinessValidityEndDateOnRole"
                name="applicableBusinessValidityEndDateOnRole"
                data-cy="applicableBusinessValidityEndDateOnRole"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityStartDateOnGroup')}
                id="application-applicableBusinessValidityStartDateOnGroup"
                name="applicableBusinessValidityStartDateOnGroup"
                data-cy="applicableBusinessValidityStartDateOnGroup"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityEndDateOnGroup')}
                id="application-applicableBusinessValidityEndDateOnGroup"
                name="applicableBusinessValidityEndDateOnGroup"
                data-cy="applicableBusinessValidityEndDateOnGroup"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityStartDateOnPermission')}
                id="application-applicableBusinessValidityStartDateOnPermission"
                name="applicableBusinessValidityStartDateOnPermission"
                data-cy="applicableBusinessValidityStartDateOnPermission"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.application.applicableBusinessValidityEndDateOnPermission')}
                id="application-applicableBusinessValidityEndDateOnPermission"
                name="applicableBusinessValidityEndDateOnPermission"
                data-cy="applicableBusinessValidityEndDateOnPermission"
                check
                type="checkbox"
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `application-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
      <Modal isOpen={toggleModal} toggle={handleToggleModal}>
        <ModalHeader toggle={handleToggleModal} data-cy="applicationModalHeading">
          <Translate contentKey="iamdentityApp.application.modal.title">Warning</Translate>
        </ModalHeader>
        <ModalBody id="iamdentityApp.application.modal.question">
          <Translate contentKey="iamdentityApp.application.modal.question" interpolate={{ id: applicationEntity.id }}>
            By leaving Connector Identification Code empty, you are configuring an Application with manually processed Technical Order
            Actions. Are you sure?
          </Translate>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleToggleModal}>
            <Translate contentKey="entity.action.cancel">Cancel</Translate>
          </Button>
          <Button
            id="jhi-confirm-modal-application"
            data-cy="entityConfirmModalButton"
            color="danger"
            onClick={event => handleSubmitForm(event, true)}
          >
            <Translate contentKey="entity.action.save">Save</Translate>
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default ApplicationUpdate;
