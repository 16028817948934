import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InJobDefinition from './in-job-definition';
import InJobDefinitionDetail from './in-job-definition-detail';
import InJobDefinitionUpdate from './in-job-definition-update';
import InJobDefinitionDeleteDialog from './in-job-definition-delete-dialog';

const InJobDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InJobDefinition />} />
    <Route path="new" element={<InJobDefinitionUpdate />} />
    <Route path=":id">
      <Route index element={<InJobDefinitionDetail />} />
      <Route path="edit" element={<InJobDefinitionUpdate />} />
      <Route path="delete" element={<InJobDefinitionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InJobDefinitionRoutes;
