import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import CustomAttributeDefinition from './custom-attribute-definition';
import CustomAttributeDefinitionDetail from './custom-attribute-definition-detail';
import CustomAttributeDefinitionUpdate from './custom-attribute-definition-update';
import CustomAttributeDefinitionDeleteDialog from './custom-attribute-definition-delete-dialog';

const CustomAttributeDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<CustomAttributeDefinition />} />
    <Route path="new" element={<CustomAttributeDefinitionUpdate />} />
    <Route path=":id">
      <Route index element={<CustomAttributeDefinitionDetail />} />
      <Route path="edit" element={<CustomAttributeDefinitionUpdate />} />
      <Route path="delete" element={<CustomAttributeDefinitionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default CustomAttributeDefinitionRoutes;
