import dayjs from 'dayjs';
import { IConnectorType } from 'app/shared/model/connector-type.model';

export interface IIAMSystem {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  connectorType?: IConnectorType | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IIAMSystem> = {};
