import { ITask } from 'app/shared/model/task.model';
import React from 'react';
import { translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardText, CardTitle } from 'reactstrap';
import '../style.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';

interface ITaskCardProps {
  task: ITask;
  children?: any;
  onClick?: Function;
}

const TaskCard = ({ task, children, onClick }: ITaskCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);
  const createdDateBadge = (
    <Badge color="success">{<TextFormat value={task?.createTime} type="date" format={convertDateFormat(locale, 'instant')} />}</Badge>
  );
  const isAssigned = task.assignee ? (
    <Badge color="warning">
      {' '}
      {translate(`iamdentityApp.tasks.assiggnedTo`)}: {task.assignee}
    </Badge>
  ) : null;
  const assignedColorBackground = task.assignee ? 'is-assigned' : '';
  const hasClickAction = onClick instanceof Function;
  const hasChildren = !!children;

  return (
    <Card
      className={hasClickAction ? `hand ${assignedColorBackground}` : `${assignedColorBackground}`}
      onClick={() => (hasClickAction ? onClick(task.id) : null)}
    >
      <CardHeader className={hasChildren ? null : 'no-border'}>
        <CardTitle tag="h5">{translate(`iamdentityApp.tasks.names.${task.name}`)}</CardTitle>
        <CardText>
          <span>{createdDateBadge}</span>
          <span>{isAssigned}</span>
        </CardText>
      </CardHeader>
      {hasChildren ? <CardBody className="flex-div">{children}</CardBody> : null}
    </Card>
  );
};

export default TaskCard;
