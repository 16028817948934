import dayjs from 'dayjs';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IOrder } from 'app/shared/model/order.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';

export interface IUserGroup {
  id?: number;
  status?: UserStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  explicitlyRemoved?: boolean | null;
  userIdentity?: IUserIdentity | null;
  roleGroup?: IRoleGroup | null;
  lastModifiedByOrder?: IOrder | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IUserGroup> = {};
