import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InSpecificPermission from './in-specific-permission';
import InSpecificPermissionDetail from './in-specific-permission-detail';
import InSpecificPermissionUpdate from './in-specific-permission-update';
import InSpecificPermissionDeleteDialog from './in-specific-permission-delete-dialog';

const InSpecificPermissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InSpecificPermission />} />
    <Route path="new" element={<InSpecificPermissionUpdate />} />
    <Route path=":id">
      <Route index element={<InSpecificPermissionDetail />} />
      <Route path="edit" element={<InSpecificPermissionUpdate />} />
      <Route path="delete" element={<InSpecificPermissionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InSpecificPermissionRoutes;
