import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ConnectorCommand from './connector-command';
import ConnectorCommandDetail from './connector-command-detail';
import ConnectorCommandUpdate from './connector-command-update';
import ConnectorCommandDeleteDialog from './connector-command-delete-dialog';

const ConnectorCommandRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ConnectorCommand />} />
    <Route path="new" element={<ConnectorCommandUpdate />} />
    <Route path=":connectorCommandDetailId">
      <Route index element={<ConnectorCommandDetail />} />
      <Route path="edit" element={<ConnectorCommandUpdate />} />
      <Route path="delete" element={<ConnectorCommandDeleteDialog />} />
      <Route path="*" element={<ConnectorCommandDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ConnectorCommandRoutes;
