export enum EmailEvents {
  ORDER_PROCESSED = 'ORDER_PROCESSED',

  ORDER_CANCELLED = 'ORDER_CANCELLED',

  ORDER_STEP_FAILED = 'ORDER_STEP_FAILED',

  TASK_ASSIGNED = 'TASK_ASSIGNED',

  TOXIC_PROFILE_DETECTED = 'TOXIC_PROFILE_DETECTED',

  REUSE_OF_USER_DETECTED = 'REUSE_OF_USER_DETECTED',
}
