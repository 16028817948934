export enum CustomAttributeApplicableObjectType {
  JOB_DEFINITION = 'JOB_DEFINITION',

  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',

  EMPLOYEE = 'EMPLOYEE',

  PERSON = 'PERSON',

  USER_IDENTITY = 'USER_IDENTITY',

  USER_IDENTITY_ROLE = 'USER_IDENTITY_ROLE',

  SPECIFIC_PERMISSION = 'SPECIFIC_PERMISSION',

  APPLICATION = 'APPLICATION',

  ROLE_DEFINITION = 'ROLE_DEFINITION',

  APPLICATION_RESOURCE = 'APPLICATION_RESOURCE',

  PERMISSION_DEFINITION = 'PERMISSION_DEFINITION',

  ORDERED_USER_IDENTITY = 'ORDERED_USER_IDENTITY',

  ORDERED_GROUP = 'ORDERED_GROUP',

  ORDERED_ROLE = 'ORDERED_ROLE',

  ORDERED_SPECIFIC_PERMISSION = 'ORDERED_SPECIFIC_PERMISSION',

  PROFILE_USER_IDENTITY = 'PROFILE_USER_IDENTITY',

  PROFILE_USER_IDENTITY_ROLE = 'PROFILE_USER_IDENTITY_ROLE',

  PROFILE_USER_GROUP = 'PROFILE_USER_GROUP',

  PROFILE_SPECIFIC_PERMISSION = 'PROFILE_SPECIFIC_PERMISSION',

  PROFILE_DEFINITION = 'PROFILE_DEFINITION',

  PROJECT = 'PROJECT',

  PROJECT_ASSIGNMENT = 'PROJECT_ASSIGNMENT',

  ROLE_GROUP = 'ROLE_GROUP',

  USER_GROUP = 'USER_GROUP',
}
