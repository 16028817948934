import React from 'react';
import ReactDOM from 'react-dom';
import { Translate } from 'react-jhipster';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { ModalContext } from './modal.provider';

const ModalContainer = () => {
  const { visible, title, body, size, showFooter, hide, callbackRef } = React.useContext(ModalContext);

  const confirm = () => {
    if (callbackRef.current) {
      callbackRef.current();
    }
    hide();
  };

  const sizeProps = {
    fullscreen: size == 'FULLSCREEN' ? true : false,
    size: size != 'FULLSCREEN' ? (size == 'EXTRA_LARGE' ? 'xl' : size == 'LARGE' ? 'lg' : size == 'MEDIUM' ? 'md' : 'sm') : undefined,
  };

  return visible
    ? ReactDOM.createPortal(
        <Modal {...sizeProps} isOpen={true} toggle={hide}>
          <ModalHeader toggle={hide}>{title}</ModalHeader>
          <ModalBody>{body}</ModalBody>
          {showFooter ? (
            <ModalFooter>
              {callbackRef.current ? (
                <React.Fragment>
                  <Button color="primary" onClick={confirm}>
                    <Translate contentKey="global.yes" />
                  </Button>{' '}
                </React.Fragment>
              ) : null}
              <Button color="secondary" onClick={hide}>
                <Translate contentKey="global.no" />
              </Button>
            </ModalFooter>
          ) : null}
        </Modal>,
        document.querySelector('body')
      )
    : null;
};

export default ModalContainer;
