import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InEmployeeDelegation from './in-employee-delegation';
import InEmployeeDelegationDetail from './in-employee-delegation-detail';
import InEmployeeDelegationUpdate from './in-employee-delegation-update';
import InEmployeeDelegationDeleteDialog from './in-employee-delegation-delete-dialog';

const InEmployeeDelegationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InEmployeeDelegation />} />
    <Route path="new" element={<InEmployeeDelegationUpdate />} />
    <Route path=":id">
      <Route index element={<InEmployeeDelegationDetail />} />
      <Route path="edit" element={<InEmployeeDelegationUpdate />} />
      <Route path="delete" element={<InEmployeeDelegationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InEmployeeDelegationRoutes;
