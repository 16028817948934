import moment from 'moment';
export const is_valid_age = value => {
  const fortheenYearsAgo = moment().subtract('years', 14);
  const birthday = moment(value);

  if (fortheenYearsAgo.isAfter(birthday)) {
    return true;
  }
  return false;
};
