import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderComments from './order-comments';
import OrderCommentsDetail from './order-comments-detail';
import OrderCommentsUpdate from './order-comments-update';
import OrderCommentsDeleteDialog from './order-comments-delete-dialog';

const OrderCommentsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderComments />} />
    <Route path="new" element={<OrderCommentsUpdate />} />
    <Route path=":id">
      <Route index element={<OrderCommentsDetail />} />
      <Route path="edit" element={<OrderCommentsUpdate />} />
      <Route path="delete" element={<OrderCommentsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderCommentsRoutes;
