import { IApplication } from 'app/shared/model/application.model';
import { IProfileDefinition } from 'app/shared/model/profile-definition.model';
import { IProfileSpecificPermission } from './profile-specific-permission.model';
import { IProfileUserGroup } from './profile-user-group.model';
import { IProfileUserIdentityRole } from './profile-user-identity-role.model';

export interface IProfileUserIdentity {
  id?: number;
  userNameExpression?: string | null;
  alternativeUserNameExpression?: string | null;
  associatedEmailAddressExpression?: string | null;
  displayNameExpression?: string | null;
  initialPasswordExpression?: string | null;
  mustChangePasswordOnFirstLoginExpression?: string | null;
  dateNextChangePasswordExpression?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  profileDefinition?: IProfileDefinition | null;
  application?: IApplication | null;
  profileUserIdentityRoles?: IProfileUserIdentityRole[] | null;
  profileUserGroups?: IProfileUserGroup[] | null;
  profileSpecificPermissions?: IProfileSpecificPermission[] | null;
  profileDefinitionName?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
  description?: string | null;
}

export const defaultValue: Readonly<IProfileUserIdentity> = {};

export const ENTITY_NAME = 'ProfileUserIdentity';
