import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TechnicalOrderAction from './technical-order-action';
import TechnicalOrderActionDetail from './technical-order-action-detail';
import TechnicalOrderActionUpdate from './technical-order-action-update';
import TechnicalOrderActionDeleteDialog from './technical-order-action-delete-dialog';

const TechnicalOrderActionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TechnicalOrderAction />} />
    <Route path="new" element={<TechnicalOrderActionUpdate />} />
    <Route path=":technicalOrderActionId">
      <Route index element={<TechnicalOrderActionDetail />} />
      <Route path="edit" element={<TechnicalOrderActionUpdate />} />
      <Route path="delete" element={<TechnicalOrderActionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TechnicalOrderActionRoutes;
