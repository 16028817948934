import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrganizationUnit from './organization-unit';
import OrganizationUnitDetail from './organization-unit-detail';
import OrganizationUnitUpdate from './organization-unit-update';
import OrganizationUnitDeleteDialog from './organization-unit-delete-dialog';

const OrganizationUnitRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrganizationUnit />} />
    <Route path="new" element={<OrganizationUnitUpdate />} />
    <Route path=":id">
      <Route index element={<OrganizationUnitDetail />} />
      <Route path="edit" element={<OrganizationUnitUpdate />} />
      <Route path="delete" element={<OrganizationUnitDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrganizationUnitRoutes;
