import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IInEmployee } from 'app/shared/model/in-employee.model';
import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { getEntity, updateEntity, createEntity, reset } from './in-employee.reducer';

export const InEmployeeUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const inEmployeeEntity = useAppSelector(state => state.inEmployee.entity);
  const loading = useAppSelector(state => state.inEmployee.loading);
  const updating = useAppSelector(state => state.inEmployee.updating);
  const updateSuccess = useAppSelector(state => state.inEmployee.updateSuccess);
  const employeeStatusValues = Object.keys(EmployeeStatus);

  const handleClose = () => {
    navigate('/in-employee' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...inEmployeeEntity,
      ...values,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          employeeStatus: 'ACTIVE',
          ...inEmployeeEntity,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.inEmployee.home.createOrEditLabel" data-cy="InEmployeeCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.inEmployee.home.createOrEditLabel">Create or edit a InEmployee</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="in-employee-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.code')}
                id="in-employee-code"
                name="code"
                data-cy="code"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.contractStartDate')}
                id="in-employee-contractStartDate"
                name="contractStartDate"
                data-cy="contractStartDate"
                type="date"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.contractEndDate')}
                id="in-employee-contractEndDate"
                name="contractEndDate"
                data-cy="contractEndDate"
                type="date"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.employeeStatus')}
                id="in-employee-employeeStatus"
                name="employeeStatus"
                data-cy="employeeStatus"
                type="select"
              >
                {employeeStatusValues.map(employeeStatus => (
                  <option value={employeeStatus} key={employeeStatus}>
                    {translate('iamdentityApp.EmployeeStatus.' + employeeStatus)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.organizationUnitCode')}
                id="in-employee-organizationUnitCode"
                name="organizationUnitCode"
                data-cy="organizationUnitCode"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.jobDefinitionInternalCode')}
                id="in-employee-jobDefinitionInternalCode"
                name="jobDefinitionInternalCode"
                data-cy="jobDefinitionInternalCode"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.superiorEmployeeCode')}
                id="in-employee-superiorEmployeeCode"
                name="superiorEmployeeCode"
                data-cy="superiorEmployeeCode"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.locationCode')}
                id="in-employee-locationCode"
                name="locationCode"
                data-cy="locationCode"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personName')}
                id="in-employee-personName"
                name="personName"
                data-cy="personName"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personFirstName')}
                id="in-employee-personFirstName"
                name="personFirstName"
                data-cy="personFirstName"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personNationalIdentifier')}
                id="in-employee-personNationalIdentifier"
                name="personNationalIdentifier"
                data-cy="personNationalIdentifier"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personPhoneNumber')}
                id="in-employee-personPhoneNumber"
                name="personPhoneNumber"
                data-cy="personPhoneNumber"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personCommunicationEmail')}
                id="in-employee-personCommunicationEmail"
                name="personCommunicationEmail"
                data-cy="personCommunicationEmail"
                type="text"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personDateOfBirth')}
                id="in-employee-personDateOfBirth"
                name="personDateOfBirth"
                data-cy="personDateOfBirth"
                type="date"
              />
              <ValidatedField
                label={translate('iamdentityApp.inEmployee.personNationality')}
                id="in-employee-personNationality"
                name="personNationality"
                data-cy="personNationality"
                type="text"
              />
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/in-employee" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InEmployeeUpdate;
