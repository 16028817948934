export interface IAudit {
  entityChanges: IAuditEntry[];
  customAttributesChanges: IAuditEntry[];
}

export const defaultValue: Readonly<IAudit> = { entityChanges: [], customAttributesChanges: [] };

export interface IAuditEntry {
  modifiedAt: string;
  modifiedBy: string;
  revType: AuditRevType;
  revId: number;
  fields: IAuditEntryField[];
}

export interface IAuditEntryField {
  currentValue: string;
  name: string;
  previousValue: string;
  type: string;
}

export enum AuditEntityName {
  LOCATION = 'LOCATION',
  JOB_DEFINITION = 'JOB_DEFINITION',
  ORGANIZATION_UNIT = 'ORGANIZATION_UNIT',
  ORGANIZATION_UNIT_TYPE = 'ORGANIZATION_UNIT_TYPE',
  EMPLOYEE = 'EMPLOYEE',
  PERSON = 'PERSON',
  USER_IDENTITY = 'USER_IDENTITY',
  USER_IDENTITY_ROLE = 'USER_IDENTITY_ROLE',
  USER_GROUP = 'USER_GROUP',
  SPECIFIC_PERMISSION = 'SPECIFIC_PERMISSION',
  APPLICATION = 'APPLICATION',
  ROLE_DEFINITION = 'ROLE_DEFINITION',
  ROLE_GROUP = 'ROLE_GROUP',
  APPLICATION_RESOURCE = 'APPLICATION_RESOURCE',
  PERMISSION_DEFINITION = 'PERMISSION_DEFINITION',
  CUSTOM_ATTRIBUTE_DEFINITION = 'CUSTOM_ATTRIBUTE_DEFINITION',
  CONNECTOR_TYPE = 'CONNECTOR_TYPE',
  IAM_SYSTEM = 'IAM_SYSTEM',
  ORDERS = 'ORDERS',
  ORDER_COMMENTS = 'ORDER_COMMENTS',
  ORDER_TYPE = 'ORDER_TYPE',
  ORDERED_GROUP = 'ORDERED_GROUP',
  ORDERED_ROLE = 'ORDERED_ROLE',
  ORDERED_SPECIFIC_PERMISSION = 'ORDERED_SPECIFIC_PERMISSION',
  ORDERED_USER_IDENTITY = 'ORDERED_USER_IDENTITY',
  PROFILE_DEFINITION = 'PROFILE_DEFINITION',
  PROFILE_SPECIFIC_PERMISSION = 'PROFILE_SPECIFIC_PERMISSION',
  PROFILE_USER_GROUP = 'PROFILE_USER_GROUP',
  PROFILE_USER_IDENTITY = 'PROFILE_USER_IDENTITY',
  PROFILE_USER_IDENTITY_ROLE = 'PROFILE_USER_IDENTITY_ROLE',
  EMPLOYEE_DELEGATION = 'EMPLOYEE_DELEGATION',
  STANDARD_POLICY_RULE = 'STANDARD_POLICY_RULE',
  ORDER_APPROVAL_LEVELS = 'ORDER_APPROVAL_LEVELS',
  CONNECTOR_COMMAND = 'CONNECTOR_COMMAND',
  CONNECTOR_COMMAND_ATTRIBUTE = 'CONNECTOR_COMMAND_ATTRIBUTE',
  TECHNICAL_ORDER_ACTION = 'TECHNICAL_ORDER_ACTION',
  TECHNICAL_ORDER_ACTION_ATTRIBUTE = 'TECHNICAL_ORDER_ACTION_ATTRIBUTE',
  TECHNICAL_ORDER_ACTION_CREATE = 'TECHNICAL_ORDER_ACTION_CREATE',
  RISK_RULE = 'RISK_RULE',
  RISK_COMBINATION = 'RISK_COMBINATION',
  PROJECT = 'PROJECT',
  PROJECT_ASSIGNMENT = 'PROJECT_ASSIGNMENT',
  EMAIL_TEMPLATE = 'EMAIL_TEMPLATE',
  IAMDENTITY_ACCESS_TO_ORDER_TYPES = 'IAMDENTITY_ACCESS_TO_ORDER_TYPES',
  IMPORT_SESSION = 'IMPORT_SESSION',
  IN_EMPLOYEE = 'IN_EMPLOYEE',
  IN_JOB_DEFINITION = 'IN_JOB_DEFINITION',
  IN_LOCATION = 'IN_LOCATION',
  IN_ORGANIZATION_UNIT = 'IN_ORGANIZATION_UNIT',
  IN_PROJECT_ASSIGNMENT = 'IN_PROJECT_ASSIGNMENT',
  IN_PROJECT = 'IN_PROJECT',
  IN_SPECIFIC_PERMISSION = 'IN_SPECIFIC_PERMISSION',
  IN_USER_GROUP = 'IN_USER_GROUP',
  IN_USER_IDENTITY = 'IN_USER_IDENTITY',
  IN_USER_IDENTITY_ROLE = 'IN_USER_IDENTITY_ROLE',
}

export enum AuditRevType {
  MOD = 'MOD',
  ADD = 'ADD',
  DEL = 'DEL',
}
