import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InUserIdentityRole from './in-user-identity-role';
import InUserIdentityRoleDetail from './in-user-identity-role-detail';
import InUserIdentityRoleUpdate from './in-user-identity-role-update';
import InUserIdentityRoleDeleteDialog from './in-user-identity-role-delete-dialog';

const InUserIdentityRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InUserIdentityRole />} />
    <Route path="new" element={<InUserIdentityRoleUpdate />} />
    <Route path=":id">
      <Route index element={<InUserIdentityRoleDetail />} />
      <Route path="edit" element={<InUserIdentityRoleUpdate />} />
      <Route path="delete" element={<InUserIdentityRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InUserIdentityRoleRoutes;
