import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RecertificationSessionUserGroup from './recertification-session-user-group';
import RecertificationSessionUserGroupDetail from './recertification-session-user-group-detail';
import RecertificationSessionUserGroupUpdate from './recertification-session-user-group-update';
import RecertificationSessionUserGroupDeleteDialog from './recertification-session-user-group-delete-dialog';

const RecertificationSessionUserGroupRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RecertificationSessionUserGroup />} />
    <Route path="new" element={<RecertificationSessionUserGroupUpdate />} />
    <Route path=":id">
      <Route index element={<RecertificationSessionUserGroupDetail />} />
      <Route path="edit" element={<RecertificationSessionUserGroupUpdate />} />
      <Route path="delete" element={<RecertificationSessionUserGroupDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecertificationSessionUserGroupRoutes;
