import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {
  IReconSessionUserIdentityIamNotInSystems,
  defaultValue,
} from 'app/shared/model/recon-session-user-identity-iam-not-in-systems.model';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import { APP_DATETIME_SAFE_FORMAT } from 'app/config/constants';

import saveAs from 'file-saver';
import moment from 'moment';

const initialState: EntityState<IReconSessionUserIdentityIamNotInSystems> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/recon-session-user-identity-iam-not-in-systems';
const apiSearchUrl = 'api/_search/recon-session-user-identity-iam-not-in-systems';

// Actions

export const searchEntities = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/search_entity',
  async ({ query, page, size, sort }: IQueryParams) => {
    const requestUrl = `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
    return axios.get<IReconSessionUserIdentityIamNotInSystems[]>(requestUrl);
  }
);

export const getEntities = createAsyncThunk('reconSessionUserIdentityIamNotInSystems/fetch_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return axios.get<IReconSessionUserIdentityIamNotInSystems[]>(requestUrl);
});

export const exportEntities = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/export_entity_list',
  async (params: IQueryParams) => {
    const requestUrl = `${apiUrl}/export${generateQueryUrl(params)}`;
    return axios.get<any>(requestUrl, {
      responseType: 'blob',
    });
  }
);

export const getEntity = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IReconSessionUserIdentityIamNotInSystems>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/create_entity',
  async (entity: IReconSessionUserIdentityIamNotInSystems, thunkAPI) => {
    const result = await axios.post<IReconSessionUserIdentityIamNotInSystems>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/update_entity',
  async (entity: IReconSessionUserIdentityIamNotInSystems, thunkAPI) => {
    const result = await axios.put<IReconSessionUserIdentityIamNotInSystems>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/partial_update_entity',
  async (entity: IReconSessionUserIdentityIamNotInSystems, thunkAPI) => {
    const result = await axios.patch<IReconSessionUserIdentityIamNotInSystems>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'reconSessionUserIdentityIamNotInSystems/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IReconSessionUserIdentityIamNotInSystems>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const ReconSessionUserIdentityIamNotInSystemsSlice = createEntitySlice({
  name: 'reconSessionUserIdentityIamNotInSystems',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(exportEntities), (state, action) => {
        const { data } = action.payload;

        const filename = `recon_session_iam_users_${moment().format(APP_DATETIME_SAFE_FORMAT)}`;

        saveAs(data, `${filename}.xlsx`);
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(getEntities, searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, searchEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = ReconSessionUserIdentityIamNotInSystemsSlice.actions;

// Reducer
export default ReconSessionUserIdentityIamNotInSystemsSlice.reducer;
