import { IApplication } from 'app/shared/model/application.model';
import { IRoleGroup } from 'app/shared/model/role-group.model';

export interface IRoleDefinition {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  uniqueKey?: string | null;
  roleDefinition?: IApplication | null;
  roleGroups?: IRoleGroup[] | null;
  application?: IApplication | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IRoleDefinition> = {};
