import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InProject from './in-project';
import InProjectDetail from './in-project-detail';
import InProjectUpdate from './in-project-update';
import InProjectDeleteDialog from './in-project-delete-dialog';

const InProjectRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InProject />} />
    <Route path="new" element={<InProjectUpdate />} />
    <Route path=":id">
      <Route index element={<InProjectDetail />} />
      <Route path="edit" element={<InProjectUpdate />} />
      <Route path="delete" element={<InProjectDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InProjectRoutes;
