import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RoleGroup from './role-group';
import RoleGroupDetail from './role-group-detail';
import RoleGroupUpdate from './role-group-update';
import RoleGroupDeleteDialog from './role-group-delete-dialog';

const RoleGroupRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RoleGroup />} />
    <Route path="new" element={<RoleGroupUpdate />} />
    <Route path=":roleGroupUpdateId">
      <Route index element={<RoleGroupDetail />} />
      <Route path="edit" element={<RoleGroupUpdate />} />
      <Route path="delete" element={<RoleGroupDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RoleGroupRoutes;
