export enum OrderStatus {
  DRAFT = 'DRAFT',

  SCHEDULED = 'SCHEDULED',

  TO_APPROVE = 'TO_APPROVE',

  APPROVED = 'APPROVED',

  SUBMITTED = 'SUBMITTED',

  PROCESSING = 'PROCESSING',

  HAS_ERRORS = 'HAS_ERRORS',

  SUCCESS = 'SUCCESS',

  CANCELLED = 'CANCELLED',

  REJECTED = 'REJECTED',

  EXPIRED = 'EXPIRED',
}
