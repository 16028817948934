import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ValidatedInput from 'app/shared/components/validated-input';
import {
  ITaskBusinessForm,
  IToaTask,
  FormBusinessGroup,
  VariableNames,
  VariableValueTypes,
  FormHeaderProperties,
} from 'app/shared/model/task.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, CardTitle, Container, Form, FormGroup, Row } from 'reactstrap';
import '../style.scss';
import { Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { APP_LOCAL_DATETIME_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import moment from 'moment';
import { completeTask } from '../tasks.reducer';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';

const FormCard = () => {
  const dispatch = useAppDispatch();
  const task: IToaTask = useAppSelector(state => state.task.entity);
  const updateSuccess = useAppSelector(state => state.task.updateSuccess);
  const formData: ITaskBusinessForm = task.businessForm;

  const completeManual = (businessForm: ITaskBusinessForm) => {
    const payload = {
      toa: { id: task.toa.id },
      businessForm: {
        ...businessForm,
      },
      variables: {
        name: [VariableNames.retry, VariableNames.escalate],
        value: [false, false],
        valueType: [VariableValueTypes.boolean, VariableValueTypes.boolean],
      },
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const changeTypestoInput = (type: string) => {
    const numbers = ['number', 'float', 'long', 'integer'];
    switch (true) {
      case type === 'localdate':
        return 'date';
        break;
      case type === 'instant':
        return 'datetime-local';
        break;
      case type === 'boolean':
        return 'checkbox';
        break;
      case numbers.includes(type):
        return 'number';
        break;
        return 'text';
    }
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    mode: 'onChange',
  });

  const watchExecutionResult = watch('executionResult');

  //userIdentity
  const watchIamStaticReturnedDateNextChangePassword = watch('returnedDateNextChangePassword');
  const watchReturnedMustChangePasswordOnFirstLogin = watch('returnedMustChangePasswordOnFirstLogin');
  const watchReturnedAlternativeUserName = watch('returnedAlternativeUserName');
  const watchReturnedBusinessValidityEndOrderedUserIdentity = watch('returnedBusinessValidityEndOrderedUserIdentity');
  const watchReturnedUserName = watch('returnedUserName');
  const watchReturnedInitialPassword = watch('returnedInitialPassword');
  const watchReturnedDisplayName = watch('returnedDisplayName');
  const watchReturnedBusinessValidityStartOrderedUserIdentity = watch('returnedBusinessValidityStartOrderedUserIdentity');

  //roles
  const watchReturnedBusinessValidityStartOrderedRole = watch('returnedBusinessValidityStartOrderedRole');
  const watchReturnedBusinessValidityEndOrderedRole = watch('returnedBusinessValidityEndOrderedRole');

  //groups
  const watchReturnedBusinessValidityStartOrderedGroup = watch('returnedBusinessValidityStartOrderedGroup');
  const watchReturnedBusinessValidityEndOrderedGroup = watch('returnedBusinessValidityEndOrderedGroup');

  //specificPermmission
  const watchReturnedBusinessValidityStartOrderedSpecificPermission = watch('returnedBusinessValidityStartOrderedSpecificPermission');
  const watchReturnedBusinessValidityEndOrderedSpecificPermission = watch('returnedBusinessValidityEndOrderedSpecificPermission');

  const submitForm = values => {
    const userIdentity = {
      iamStaticReturnedDateNextChangePassword: values.returnedDateNextChangePassword ? values.returnedDateNextChangePassword : null,
      iamStaticReturnedMustChangePasswordOnFirstLogin: values.returnedMustChangePasswordOnFirstLogin,
      iamStaticReturnedAlternativeUserName: values.returnedAlternativeUserName,
      iamStaticReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedUserIdentity
        ? moment(values.returnedBusinessValidityEndOrderedUserIdentity).toISOString()
        : null,
      iamStaticReturnedUserName: values.returnedUserName,
      iamStaticReturnedInitialPassword: values.returnedInitialPassword,
      iamStaticReturnedDisplayName: values.returnedDisplayName,
      iamStaticReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedUserIdentity
        ? moment(values.returnedBusinessValidityStartOrderedUserIdentity).toISOString()
        : null,
      iamStaticReturnedAssociatedEmailAddress: values.returnedAssociatedEmailAddress,
    };

    const role = {
      iamStaticRoleReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedRole
        ? moment(values.returnedBusinessValidityStartOrderedRole).toISOString()
        : null,
      iamStaticRoleReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedRole
        ? moment(values.returnedBusinessValidityEndOrderedRole).toISOString()
        : null,
    };

    const groupOrdered = {
      iamStaticGroupReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedGroup
        ? moment(values.returnedBusinessValidityStartOrderedGroup).toISOString()
        : null,
      iamStaticGroupReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedGroup
        ? moment(values.returnedBusinessValidityEndOrderedGroup).toISOString()
        : null,
    };

    const specificPermission = {
      iamStaticPermReturnedBusinessValidityStart: values.returnedBusinessValidityStartOrderedSpecificPermission
        ? moment(values.returnedBusinessValidityStartOrderedSpecificPermission).toISOString()
        : null,
      iamStaticPermReturnedBusinessValidityEnd: values.returnedBusinessValidityEndOrderedSpecificPermission
        ? moment(values.returnedBusinessValidityEndOrderedSpecificPermission).toISOString()
        : null,
    };

    const entity = {
      ...formData,
      header: {
        properties: formData.header.properties.map(prop =>
          prop.name === FormHeaderProperties.responseMessage ? { ...prop, value: watchExecutionResult ? watchExecutionResult : null } : prop
        ),
      },
      groups: formData.groups.map(group => ({
        ...group,
        values: group?.values?.map(i => {
          let returnedValue;
          if (group.name === FormBusinessGroup.OrderedUserIdentityDTO) {
            for (const [key, val] of Object.entries(userIdentity)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedRoleDTO) {
            for (const [key, val] of Object.entries(role)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedGroupDTO) {
            for (const [key, val] of Object.entries(groupOrdered)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          if (group.name === FormBusinessGroup.OrderedSpecificPermissionDTO) {
            for (const [key, val] of Object.entries(specificPermission)) {
              if (key === i.name) {
                returnedValue = {
                  ...i,
                  value: val ? val : null,
                };
              }
            }
          }
          for (const [key, val] of Object.entries(values)) {
            if (key === i.name) {
              returnedValue = {
                ...i,
                value: val ? val : null,
              };
            }
          }

          return returnedValue;
        }),
      })),
    };

    completeManual(entity);
  };

  useEffect(() => {
    if (task.toa?.orderedUserIdentity) {
      setValue('returnedDateNextChangePassword', task.toa.orderedUserIdentity.returnedDateNextChangePassword ?? '');
      setValue('returnedMustChangePasswordOnFirstLogin', task.toa.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin ?? '');
      setValue('returnedAlternativeUserName', task.toa.orderedUserIdentity.returnedAlternativeUserName ?? '');
      setValue(
        'returnedBusinessValidityEndOrderedUserIdentity',
        moment(task.toa.orderedUserIdentity.returnedBusinessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue('returnedUserName', task.toa.orderedUserIdentity.returnedUserName ?? '');
      setValue('returnedInitialPassword', task.toa.orderedUserIdentity.returnedInitialPassword ?? '');
      setValue('returnedDisplayName', task.toa.orderedUserIdentity.returnedDisplayName ?? '');
      setValue(
        'returnedBusinessValidityStartOrderedUserIdentity',
        moment(task.toa.orderedUserIdentity.returnedBusinessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue('executionResult', task.toa.orderedUserIdentity.executionResult ?? '');
    }

    if (task.toa?.orderedRole) {
      setValue(
        'returnedBusinessValidityStartOrderedRole',
        moment(task.toa.orderedRole.returnedBusinessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedRole',
        moment(task.toa.orderedRole.returnedBusinessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue('executionResult', task.toa.orderedRole.executionResult ?? '');
    }

    if (task.toa?.orderedGroup) {
      setValue(
        'returnedBusinessValidityStartOrderedGroup',
        moment(task.toa.orderedGroup.returnedBusinessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedGroup',
        moment(task.toa.orderedGroup.returnedBusinessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue('executionResult', task.toa.orderedGroup.executionResult ?? '');
    }

    if (task.toa?.orderedSpecificPermission) {
      setValue(
        'returnedBusinessValidityStartOrderedSpecificPermission',
        moment(task.toa.orderedSpecificPermission.returnedBusinessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue(
        'returnedBusinessValidityEndOrderedSpecificPermission',
        moment(task.toa.orderedSpecificPermission.returnedBusinessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString() ?? ''
      );
      setValue('executionResult', task.toa.orderedSpecificPermission.executionResult ?? '');
    }

    const checkConnectorCommand = formData?.groups?.some(gr => gr.name.includes(FormBusinessGroup.ConnectorCommandAttributeDTO));
    if (checkConnectorCommand) {
      const getConnectorCommandFields = formData.groups.find(gr => gr.name === FormBusinessGroup.ConnectorCommandAttributeDTO).values;
      for (const field of getConnectorCommandFields) {
        setValue(field.name, field.value);
      }
    }
  }, [task]);

  const copyValueToInput = (input: string, value: string | boolean) => {
    if (value && input) setValue(input, value);
  };

  return (
    <Card color="white" inverse className="form-card">
      <CardHeader>
        <CardTitle className="mb-0" tag="h5">
          {translate('iamdentityApp.tasks.taskForm')}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit(submitForm)}>
          {task.toa?.orderedGroup ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedGroup.home.title">Ordered Role</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedGroup.businessValidityStart')}:<br />
                    {task?.toa?.orderedGroup?.businessValidityStart
                      ? moment(task.toa.orderedGroup.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedGroup',
                          moment(task.toa.orderedGroup.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={`${translate('iamdentityApp.orderedGroup.returnedBusinessValidityStart')}`}
                      name="returnedBusinessValidityStartOrderedGroup"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedGroup.businessValidityEnd')}:<br />
                    {task?.toa?.orderedRole?.businessValidityEnd
                      ? moment(task.toa.orderedRole.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedGroup',
                          moment(task.toa.orderedRole.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedGroup.returnedBusinessValidityEnd')}
                      name="returnedBusinessValidityEndOrderedGroup"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedRole ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedRole.home.title">Ordered Role</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedRole.businessValidityStart')}:<br />
                    {task?.toa?.orderedRole?.businessValidityStart
                      ? moment(task.toa.orderedRole.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedRole',
                          moment(task.toa.orderedRole.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={`${translate('iamdentityApp.orderedRole.returnedBusinessValidityStart')}`}
                      name="returnedBusinessValidityStartOrderedRole"
                      type="datetime-local"
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedRole.businessValidityEnd')}:<br />
                    {task?.toa?.orderedRole?.businessValidityEnd
                      ? moment(task.toa.orderedRole.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedRole',
                          moment(task.toa.orderedRole.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedRole.returnedBusinessValidityEnd')}
                      name="returnedBusinessValidityEndOrderedRole"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedSpecificPermission ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedSpecificPermission.home.title">Ordered Specific Permission</Translate>
              </h5>
              <Container>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedSpecificPermission.businessValidityStart')}:<br />
                    {task?.toa?.orderedSpecificPermission?.businessValidityStart
                      ? moment(task.toa.orderedSpecificPermission.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedSpecificPermission',
                          moment(task.toa.orderedSpecificPermission.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={`${translate('iamdentityApp.orderedSpecificPermission.returnedBusinessValidityStart')}`}
                      name="returnedBusinessValidityStartOrderedSpecificPermission"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedSpecificPermission.businessValidityEnd')}:<br />
                    {task?.toa?.orderedSpecificPermission?.businessValidityEnd
                      ? moment(task.toa.orderedSpecificPermission.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedSpecificPermission',
                          moment(task.toa.orderedSpecificPermission.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedSpecificPermission.returnedBusinessValidityEnd')}
                      name="returnedBusinessValidityEndOrderedSpecificPermission"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          {task.toa?.orderedUserIdentity ? (
            <React.Fragment>
              <h5>
                <Translate contentKey="iamdentityApp.orderedUserIdentity.home.title">Ordered User Identity</Translate>
              </h5>
              <Container className="container">
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.userName')}:<br />
                    {task?.toa?.orderedUserIdentity?.userName ? task.toa.orderedUserIdentity.userName : null}
                  </div>
                  <div>
                    <span className="pointer" onClick={() => copyValueToInput('returnedUserName', task.toa.orderedUserIdentity.userName)}>
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      required
                      label={`*${translate('iamdentityApp.orderedUserIdentity.returnedUserName')}`}
                      name="returnedUserName"
                      type="text"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.alternativeUserName')}:<br />
                    {task?.toa?.orderedUserIdentity?.alternativeUserName ? task.toa.orderedUserIdentity.alternativeUserName : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedAlternativeUserName', task.toa.orderedUserIdentity.alternativeUserName)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedAlternativeUserName')}
                      name="returnedAlternativeUserName"
                      type="text"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.associatedEmailAddress')}:<br />
                    {task?.toa?.orderedUserIdentity?.associatedEmailAddress ? task.toa.orderedUserIdentity.associatedEmailAddress : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput('returnedAssociatedEmailAddress', task.toa.orderedUserIdentity.associatedEmailAddress)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    {' '}
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedAssociatedEmailAddress')}
                      name="returnedAssociatedEmailAddress"
                      type="email"
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.displayName')}:<br />
                    {task?.toa?.orderedUserIdentity?.displayName ? task.toa.orderedUserIdentity.displayName : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedDisplayName', task.toa.orderedUserIdentity.displayName)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    {' '}
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedDisplayName')}
                      name="returnedDisplayName"
                      type="text"
                    />
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.initialPassword')}:<br />
                    *****
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() => copyValueToInput('returnedInitialPassword', task.toa.orderedUserIdentity.initialPassword)}
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedInitialPassword')}
                      name="returnedInitialPassword"
                      type="password"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.mustChangePasswordOnFirstLogin')}:<br />
                    {task?.toa?.orderedUserIdentity?.mustChangePasswordOnFirstLogin
                      ? task.toa.orderedUserIdentity.mustChangePasswordOnFirstLogin
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedMustChangePasswordOnFirstLogin',
                          task.toa.orderedUserIdentity.mustChangePasswordOnFirstLogin
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedMustChangePasswordOnFirstLogin')}
                      name="returnedMustChangePasswordOnFirstLogin"
                      type="checkbox"
                    />{' '}
                  </div>
                </Row>

                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.dateNextChangePassword')}:<br />
                    {task?.toa?.orderedUserIdentity?.dateNextChangePassword
                      ? moment(task.toa.orderedUserIdentity.dateNextChangePassword).format(APP_LOCAL_DATE_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput('returnedDateNextChangePassword', task.toa.orderedUserIdentity.dateNextChangePassword)
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedDateNextChangePassword')}
                      name="returnedDateNextChangePassword"
                      type="date"
                    />{' '}
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.businessValidityStart')}:<br />
                    {task?.toa?.orderedUserIdentity?.businessValidityStart
                      ? moment(task.toa.orderedUserIdentity.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityStartOrderedUserIdentity',
                          moment(task.toa.orderedUserIdentity.businessValidityStart).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={`${translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityStart')}`}
                      name="returnedBusinessValidityStartOrderedUserIdentity"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
                <Row className="">
                  <div>
                    {translate('iamdentityApp.orderedUserIdentity.businessValidityEnd')}:<br />
                    {task?.toa?.orderedUserIdentity?.businessValidityEnd
                      ? moment(task.toa.orderedUserIdentity.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT)
                      : null}
                  </div>
                  <div>
                    <span
                      className="pointer"
                      onClick={() =>
                        copyValueToInput(
                          'returnedBusinessValidityEndOrderedUserIdentity',
                          moment(task.toa.orderedUserIdentity.businessValidityEnd).format(APP_LOCAL_DATETIME_FORMAT).toString()
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faArrowRight} />
                    </span>
                  </div>
                  <div>
                    <ValidatedInput
                      control={control}
                      label={translate('iamdentityApp.orderedUserIdentity.returnedBusinessValidityEnd')}
                      name="returnedBusinessValidityEndOrderedUserIdentity"
                      type="datetime-local"
                    />{' '}
                  </div>
                </Row>
              </Container>
            </React.Fragment>
          ) : null}

          <Container>
            <Row className={task.toa?.orderedUserIdentity ? ' user-identity' : ' rest'}>
              <div> </div>
              <div> </div>
              <div className="executionResultForceWidth">
                <ValidatedInput
                  control={control}
                  label={`${translate('iamdentityApp.tasks.executionResult')}`}
                  name="executionResult"
                  type="text"
                />{' '}
              </div>
            </Row>
          </Container>
          <Container>
            {formData?.groups?.map(group => {
              if (group.name === FormBusinessGroup.ConnectorCommandAttributeDTO && group.values) {
                return (
                  <React.Fragment key={group.name}>
                    <div>
                      <div> </div>
                      <div> </div>
                      <h5>{group.name}</h5>
                      {group?.values?.map(value => {
                        const decimalNumberInputType = ['float', 'number'];
                        return (
                          <ValidatedInput
                            key={value.name}
                            control={control}
                            label={value.name}
                            name={value.name}
                            type={changeTypestoInput(value.valueType)}
                            step={decimalNumberInputType.includes(value.valueType) ? '.01' : null}
                          />
                        );
                      })}
                    </div>
                  </React.Fragment>
                );
              }
            })}
          </Container>
          <div className="d-flex justify-content-end">
            <Button color="success" id="save-entity" disabled={updateSuccess} data-cy="entityCreateSaveButton" type="submit">
              <FontAwesomeIcon icon="save" /> {translate('iamdentityApp.tasks.updateCompleteManual')}
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default FormCard;
