import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserPermissionIamNotInSystems {
  id?: number;
  processed?: boolean | null;
  comments?: string | null;
  iamUserPermission?: ISpecificPermission | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IReconSessionUserPermissionIamNotInSystems> = {
  processed: false,
};
