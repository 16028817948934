export enum ConnectorCommandAttributeType {
  NUMBER = 'NUMBER',

  STRING = 'STRING',

  INTEGER = 'INTEGER',

  LONG = 'LONG',

  DATE = 'DATE',

  DATETIME = 'DATETIME',

  BOOLEAN = 'BOOLEAN',
}
