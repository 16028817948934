import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { createEntity, getEntitiesByOrderId } from 'app/entities/order-comments/order-comments.reducer';
import { IOrderComments } from 'app/shared/model/order-comments.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Translate } from 'react-jhipster';
import { Badge, Button, Card, CardBody, CardHeader, Form, FormGroup, Input, Spinner } from 'reactstrap';
import '../style.scss';

interface ICommentsCardProps {
  orderId: number;
}

const CommentsCard = ({ orderId }: ICommentsCardProps) => {
  const dispatch = useAppDispatch();

  const account = useAppSelector(state => state.authentication.account);
  const orderCommentsList: IOrderComments[] = useAppSelector(state => state.orderComments.entities);
  const loading = useAppSelector(state => state.orderComments.loading);
  const updateSuccess = useAppSelector(state => state.orderComments.updateSuccess);

  const { register, handleSubmit, reset } = useForm();

  const { ref: descriptionRef, ...descriptionProps } = register('description', { required: true });

  const commentsBoxRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    dispatch(getEntitiesByOrderId(orderId));
  }, [updateSuccess]);

  useEffect(() => {
    commentsBoxRef.current.scrollTop = commentsBoxRef.current.scrollHeight;
  }, [orderCommentsList]);

  const submitForm = values => {
    const newComment: IOrderComments = {
      ...values,
      order: { id: orderId },
      user: { id: account.id },
      username: account?.employee?.fullName ?? account.email,
    };
    dispatch(createEntity(newComment));
    reset();
  };

  return (
    <Card className="comments-card" color="secondary" inverse>
      <CardHeader>
        <Translate contentKey="iamdentityApp.tasks.comments">Comments</Translate>
      </CardHeader>
      <CardBody>
        <div ref={commentsBoxRef} className="comments-box">
          {loading ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            orderCommentsList.map((comment, index) => {
              const author = comment.username ? comment.username : 'System';
              return (
                <div key={`comment-${index}`} className="comment">
                  <Badge color="primary">
                    <p className="author">{author}</p>
                  </Badge>
                  <br />
                  <span>{comment.description}</span>
                </div>
              );
            })
          )}
        </div>
        <Form onSubmit={handleSubmit(submitForm)}>
          <FormGroup className="mt-1">
            <div className="flex-div justify-space-between new-comment-box">
              <Input {...descriptionProps} innerRef={descriptionRef} id="new-comment" type="text" />
              <Button disabled={loading} type="submit" color="primary">
                <FontAwesomeIcon icon="paper-plane" />
              </Button>
            </div>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};

export default CommentsCard;
