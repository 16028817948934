import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import MenuItem from './menu-item';
import { faUnlockKeyhole } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export const AccessProfilesData = () => {
  const location = useLocation(); // Obținem locația curentă

  // Lista de rute pentru submeniuri
  const subMenuRoutes = [
    '/profile-definition',
    '/profile-user-identity',
    '/profile-user-identity-role',
    '/profile-user-group',
    '/profile-specific-permission',
    '/standard-policy-rule',
  ];

  // Verificăm dacă vreuna dintre rutele de submeniu este activă
  const isSubMenuActive = subMenuRoutes.some(route => location.pathname.includes(route));

  return (
    <NavDropdown
      isActive={isSubMenuActive}
      icon={faUnlockKeyhole}
      name={translate('global.menu.profiles')}
      id="entity-menu"
      data-cy="entity"
      className="max-height-80 overflow-a"
    >
      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.profiles" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/profile-definition">
        <Translate contentKey="global.menu.entities.profileDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-identity">
        <Translate contentKey="global.menu.entities.profileUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-identity-role">
        <Translate contentKey="global.menu.entities.profileUserIdentityRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-user-group">
        <Translate contentKey="global.menu.entities.profileUserGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/profile-specific-permission">
        <Translate contentKey="global.menu.entities.profileSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/standard-policy-rule">
        <Translate contentKey="global.menu.entities.standardPolicyRule" />
      </MenuItem>
    </NavDropdown>
  );
};
