import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecertificationSessionSpecificPermission } from 'app/shared/model/recertification-session-specific-permission.model';
import React from 'react';
import { Translate, TextFormat, translate } from 'react-jhipster';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { Link } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { generateAssignedSpecificPermissionUrl } from './recertification-session-users/recertification-session-users';
import useModal from 'app/shared/components/modal/modal.hook';
import { revokeUserSpecificPermissionById } from 'app/entities/orders/orders.reducer';

interface IAssignedRecertificationSessionSpecificPermissionsTablesProps {
  recertificationSessionSpecificPermissions: IRecertificationSessionSpecificPermission[];
  isTask?: boolean | false;
}

const AssignedRecertificationSessionSpecificPermissionsTable = ({
  recertificationSessionSpecificPermissions,
  isTask,
}: IAssignedRecertificationSessionSpecificPermissionsTablesProps) => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(state => state.locale.currentLocale);

  // recertification session revoke user specific permission from task
  const startRevokeUserSpecificPermission = (recertificationSessionEntityId, recertificationSessionSpecificPermissionIdForRevokeInit) => {
    dispatch(revokeUserSpecificPermissionById({ recertificationSessionEntityId, recertificationSessionSpecificPermissionIdForRevokeInit }));
  };

  const modal = useModal();
  const revokeUserSpecificPermission = (
    recertificationSessionEntityId,
    recertificationSessionSpecificPermissionIdForRevoke,
    specificPermissionNameParam,
    applicationNameParam,
    employeeNameParam
  ) => {
    modal.show({
      size: 'LARGE',
      title: translate('iamdentityApp.recertificationSessionSpecificPermission.revokeSpecificPermission'),
      body: translate('iamdentityApp.recertificationSessionSpecificPermission.revokeSpecificPermissionDialog', {
        specificPermissionName: specificPermissionNameParam,
        applicationName: applicationNameParam,
        employeeName: employeeNameParam,
      }),
      callback: startRevokeUserSpecificPermission.bind(
        null,
        recertificationSessionEntityId,
        recertificationSessionSpecificPermissionIdForRevoke
      ),
    });
  };

  return (
    <div className="table-responsive">
      {recertificationSessionSpecificPermissions.length ? (
        <Table responsive>
          <thead>
            <tr>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.application">Application</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.permissionDefinition">
                  Permission Definition
                </Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.status">Status</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.grantedBy">Granted By</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.validFrom">Valid From</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.validTo">Valid To</Translate>
              </th>
              <th>
                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revoked">Revoked</Translate>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {recertificationSessionSpecificPermissions.map((recertificationSessionSpecificPermission, i) => (
              <tr key={`specific-permission-${i}`}>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition ? (
                    <Link
                      to={`/application/${recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition.applicationResource.application.id}`}
                    >
                      {
                        recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition.applicationResource.application
                          .name
                      }
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition ? (
                    <Link
                      to={`/permission-definition/${recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition.id}`}
                    >
                      {recertificationSessionSpecificPermission.userSpecificPermission.permissionDefinition.name}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.status
                    ? recertificationSessionSpecificPermission.userSpecificPermission.status
                    : ''}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.grantedBy
                    ? recertificationSessionSpecificPermission.userSpecificPermission.grantedBy
                    : ''}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.startDate ? (
                    <TextFormat
                      value={recertificationSessionSpecificPermission.userSpecificPermission.startDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.userSpecificPermission.endDate ? (
                    <TextFormat
                      value={recertificationSessionSpecificPermission.userSpecificPermission.endDate}
                      type="date"
                      format={convertDateFormat(locale, 'instant')}
                    />
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {recertificationSessionSpecificPermission.revoked !== null &&
                  recertificationSessionSpecificPermission.revoked !== undefined ? (
                    recertificationSessionSpecificPermission.revoked === true ? (
                      recertificationSessionSpecificPermission.order ? (
                        <div>
                          <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revokedByOrder">
                            Revoked by Order
                          </Translate>
                          :&nbsp;
                          <Link to={`/orders/${recertificationSessionSpecificPermission.order.id}`}>
                            {recertificationSessionSpecificPermission.order.id}
                          </Link>
                          &nbsp;
                          {recertificationSessionSpecificPermission?.order?.status ? (
                            <span className="fw-bold text-black d-inline-block">
                              ({recertificationSessionSpecificPermission?.order?.status})
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      ) : (
                        <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.revokedByOrder">
                          Revoked by Order
                        </Translate>
                      )
                    ) : (
                      <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.confirmed">Confirmed</Translate>
                    )
                  ) : (
                    ''
                  )}
                </td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    {!isTask ? (
                      <Button
                        tag={Link}
                        to={generateAssignedSpecificPermissionUrl(
                          recertificationSessionSpecificPermission.recertificationSessionUserIdentity,
                          recertificationSessionSpecificPermission.id,
                          'VIEW'
                        )}
                        className="btn-md"
                        color="info"
                        data-cy="entityDetailsButton"
                      >
                        <FontAwesomeIcon icon="eye" />
                      </Button>
                    ) : null}
                    {isTask ? (
                      <Button
                        disabled={
                          recertificationSessionSpecificPermission?.revoked === true ||
                          recertificationSessionSpecificPermission?.revoked === false
                            ? true
                            : false
                        }
                        onClick={() =>
                          revokeUserSpecificPermission(
                            recertificationSessionSpecificPermission?.recertificationSession?.id,
                            recertificationSessionSpecificPermission?.id,
                            recertificationSessionSpecificPermission?.userSpecificPermission?.permissionDefinition?.name,
                            recertificationSessionSpecificPermission?.userSpecificPermission?.permissionDefinition?.applicationResource
                              ?.application?.name,
                            recertificationSessionSpecificPermission?.employee?.code +
                              ' - ' +
                              recertificationSessionSpecificPermission?.employee?.fullName
                          )
                        }
                        className="btn-md"
                        color="primary"
                      >
                        <FontAwesomeIcon icon="ban" />
                        &nbsp;
                        {translate('iamdentityApp.recertificationSession.revoke')}
                      </Button>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <div className="alert alert-warning">
          <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.home.notFound">
            No Profile User Identity Roles found
          </Translate>
        </div>
      )}
    </div>
  );
};

export default AssignedRecertificationSessionSpecificPermissionsTable;
