import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmployeeDelegation from './employee-delegation';
import EmployeeDelegationDetail from './employee-delegation-detail';
import EmployeeDelegationUpdate from './employee-delegation-update';
import EmployeeDelegationDeleteDialog from './employee-delegation-delete-dialog';
import EmployeeDelegationRevokeDialog from './employee-delegation-revoke-dialog';

const EmployeeDelegationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmployeeDelegation />} />
    <Route path="new" element={<EmployeeDelegationUpdate />} />
    <Route path=":id">
      <Route index element={<EmployeeDelegationDetail />} />
      <Route path="edit" element={<EmployeeDelegationUpdate />} />
      <Route path="delete" element={<EmployeeDelegationDeleteDialog />} />
      <Route path="revoke" element={<EmployeeDelegationRevokeDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmployeeDelegationRoutes;
