import dayjs from 'dayjs';
import { IEmployee } from 'app/shared/model/employee.model';
import { IJobDefinition } from 'app/shared/model/job-definition.model';
import { IOrganizationUnit } from 'app/shared/model/organization-unit.model';
import { IOrder } from './order.model';

export interface IEmployeeDelegation {
  id?: number;
  validFrom?: string | null;
  validTo?: string | null;
  cumulated?: boolean | null;
  status?: string | null;
  employee?: IEmployee | null;
  job?: IJobDefinition | null;
  organizationUnit?: IOrganizationUnit | null;
  order?: IOrder | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IEmployeeDelegation> = {
  cumulated: false,
};
