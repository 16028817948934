import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import SpecificPermission from './specific-permission';
import SpecificPermissionDetail from './specific-permission-detail';
import SpecificPermissionUpdate from './specific-permission-update';
import SpecificPermissionDeleteDialog from './specific-permission-delete-dialog';

const SpecificPermissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<SpecificPermission />} />
    <Route path="new" element={<SpecificPermissionUpdate />} />
    <Route path=":id">
      <Route index element={<SpecificPermissionDetail />} />
      <Route path="edit" element={<SpecificPermissionUpdate />} />
      <Route path="delete" element={<SpecificPermissionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default SpecificPermissionRoutes;
