import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Offcanvas, OffcanvasBody, OffcanvasHeader } from 'reactstrap';

interface IInteractionDrawerProps {
  children?: any;
}

const InteractionDrawer = ({ children }: IInteractionDrawerProps) => {
  const navigate = useNavigate();

  const [fullWidth, setFullWidth] = useState(false);

  const toggleWidth = () => {
    setFullWidth(!fullWidth);
  };

  return (
    <Offcanvas
      scrollable={true}
      className={fullWidth ? 'full-width' : 'large-width'}
      direction="end"
      isOpen={true}
      toggle={() => navigate(-1)}
    >
      <OffcanvasHeader toggle={() => navigate(-1)}>
        <FontAwesomeIcon className="hand" onClick={toggleWidth} icon="expand" />
      </OffcanvasHeader>
      <OffcanvasBody>{children}</OffcanvasBody>
    </Offcanvas>
  );
};

export default InteractionDrawer;
