/* eslint-disable react/jsx-key */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import RecertificationSessionUserIdentityDetail from 'app/entities/recertification-session-user-identity/recertification-session-user-identity-detail';
import RecertificationSessionUserRoleDetail from 'app/entities/recertification-session-user-role/recertification-session-user-role-detail';
import RecertificationSessionUserGroupDetail from 'app/entities/recertification-session-user-group/recertification-session-user-group-detail';
import RecertificationSessionSpecificPermissionDetail from 'app/entities/recertification-session-specific-permission/recertification-session-specific-permission-detail';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserIdentitiesByRecertificationSessionId } from 'app/entities/recertification-session-user-identity/recertification-session-user-identity.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserRolesByRecertificationSessionId } from 'app/entities/recertification-session-user-role/recertification-session-user-role.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserGroupsByRecertificationSessionId } from 'app/entities/recertification-session-user-group/recertification-session-user-group.reducer';
import { getEntitiesByRecertificationSessionId as getRecertificationSessionUserSpecificPermissionsByRecertificationSessionId } from 'app/entities/recertification-session-specific-permission/recertification-session-specific-permission.reducer';
import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { IRecertificationSessionUserIdentity } from 'app/shared/model/recertification-session-user-identity.model';
import { IRecertificationSessionUserRole } from 'app/shared/model/recertification-session-user-role.model';
import { IRecertificationSessionUserGroup } from 'app/shared/model/recertification-session-user-group.model';
import { IRecertificationSessionSpecificPermission } from 'app/shared/model/recertification-session-specific-permission.model';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { TextFormat, translate, Translate } from 'react-jhipster';
import { Link, Route } from 'react-router-dom';
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Card,
  Col,
  Input,
  InputGroup,
  Spinner,
  UncontrolledAccordion,
} from 'reactstrap';
import AssignedRecertificationSessionRolesTable from '../assigned-recertification-session-roles-table';
import AssignedRecertificationSessionGroupsTable from '../assigned-recertification-session-groups-table';
import AssignedRecertificationSessionSpecificPermissionsTable from '../assigned-recertification-session-specific-permissions-table';
import { default as RecertificationSessionInteractionModal } from './recertification-session-interaction-modal';
import { convertDateFormat } from 'app/shared/util/date-utils';
import useModal from 'app/shared/components/modal/modal.hook';
import { revokeUserIdentityById } from 'app/entities/orders/orders.reducer';

const generateRecertificationSessionUserIdentityUrl = (
  recertificationSession: IRecertificationSession,
  recertificationSessionUserIdentityId?: number,
  action?: 'VIEW' | 'DELETE'
) => {
  let result = '';

  if (recertificationSessionUserIdentityId) {
    result += `recertification-session-user/${recertificationSessionUserIdentityId}${
      action ? (action == 'DELETE' ? '/delete' : '/view') : ''
    }`;
  } else {
    result += 'recertification-session-user';
  }

  return encodeURI(result);
};

export const generateAssignedRoleUrl = (
  recertificationSessionUserIdentity: IRecertificationSessionUserIdentity,
  recertificationSessionUserIdentityRoleId?: number,
  action?: 'VIEW' | 'DELETE' | null
) => {
  let result = '';

  if (recertificationSessionUserIdentityRoleId) {
    result += `recertification-session-assigned-role/${recertificationSessionUserIdentityRoleId}${
      action ? (action == 'DELETE' ? '/delete' : '/view') : ''
    }`;
  } else {
    result += 'recertification-session-assigned-role';
  }

  return encodeURI(result);
};

export const generateAssignedRoleGroupUrl = (
  recertificationSessionUserIdentity: IRecertificationSessionUserIdentity,
  recertificationSessionUserGroupId?: number | null,
  action?: 'VIEW' | 'DELETE' | null
) => {
  let result = '';

  if (recertificationSessionUserGroupId) {
    result += `recertification-session-assigned-role-group/${recertificationSessionUserGroupId}${
      action ? (action == 'DELETE' ? '/delete' : '/view') : ''
    }`;
  } else {
    result += 'recertification-session-assigned-role-group';
  }
  return encodeURI(result);
};

export const generateAssignedSpecificPermissionUrl = (
  recertificationSessionUserIdentity: IRecertificationSessionUserIdentity,
  recertificationSessionSpecificPermissionId?: number | null,
  action?: 'VIEW' | 'DELETE' | null
) => {
  let result = '';
  if (recertificationSessionSpecificPermissionId) {
    result += `recertification-session-assigned-specific-permission/${recertificationSessionSpecificPermissionId}${
      action ? (action == 'DELETE' ? '/delete' : '/view') : ''
    }`;
  } else {
    result += 'recertification-session-assigned-specific-permission';
  }

  return encodeURI(result);
};

interface IRecertificationSessionUsersProps {
  recertificationSessionId: number;
  isTask?: boolean | false;
}

interface IRecertificationSessionEmployee {
  employee: IEmployee;
  recertificationSessionUserIdentities: IRecertificationSessionUserIdentity[];
}

const RecertificationSessionUsers = ({ recertificationSessionId, isTask }: IRecertificationSessionUsersProps) => {
  const dispatch = useAppDispatch();

  const recertificationSessionEntity = useAppSelector(state => state.recertificationSession.entity);
  const recertificationSessionUserIdentityList: IRecertificationSessionUserIdentity[] = useAppSelector(
    state => state.recertificationSessionUserIdentity.entities
  );
  const recertificationSessionUserRoleList: IRecertificationSessionUserRole[] = useAppSelector(
    state => state.recertificationSessionUserRole.entities
  );
  const recertificationSessionUserGroupList: IRecertificationSessionUserGroup[] = useAppSelector(
    state => state.recertificationSessionUserGroup.entities
  );
  const recertificationSessionSpecificPermissionList: IRecertificationSessionSpecificPermission[] = useAppSelector(
    state => state.recertificationSessionSpecificPermission.entities
  );

  const locale = useAppSelector(state => state.locale.currentLocale);
  const loading = useAppSelector(
    state =>
      state.recertificationSession.loading ||
      state.recertificationSessionUserIdentity.loading ||
      state.recertificationSessionUserRole.loading ||
      state.recertificationSessionUserGroup.loading ||
      state.recertificationSessionSpecificPermission.loading
  );

  const [search, setSearch] = useState<string>('');
  const [employeeGroupedState, setEmployeeGrouped] = useState({});
  const [recertificationSessionUserIdentityByIdState, setRecertificationSessionUserIdentityById] = useState({});
  const [employeesByEmployeeIdState, setEmployeesByEmployeeId] = useState({});
  const [filteredEmployeesByEmployeeIdState, setFilteredEmployeesByEmployeeId] = useState({});

  useEffect(() => {
    if (recertificationSessionId) {
      dispatch(getRecertificationSessionUserIdentitiesByRecertificationSessionId(recertificationSessionId));
      dispatch(getRecertificationSessionUserRolesByRecertificationSessionId(recertificationSessionId));
      dispatch(getRecertificationSessionUserGroupsByRecertificationSessionId(recertificationSessionId));
      dispatch(getRecertificationSessionUserSpecificPermissionsByRecertificationSessionId(recertificationSessionId));
    }
  }, [recertificationSessionId]);

  useEffect(() => {
    if (!loading) {
      const allEmployeesByRecertificationSessionId = [
        ...recertificationSessionUserIdentityList.map(userIdentity => ({
          employeeId: userIdentity.employee.id,
          recertificationSessionUserIdentityId: userIdentity.id ?? 0,
          type: 'userIdentity',
          value: { ...userIdentity, recertificationSessionUserIdentity: userIdentity },
        })),
        ...recertificationSessionUserRoleList.map(userRole => ({
          employeeId: userRole.employee.id,
          recertificationSessionUserIdentityId: userRole?.recertificationSessionUserIdentity?.id ?? 0,
          type: 'userRole',
          value: userRole,
        })),
        ...recertificationSessionUserGroupList.map(userGroup => ({
          employeeId: userGroup.employee.id,
          recertificationSessionUserIdentityId: userGroup?.recertificationSessionUserIdentity?.id ?? 0,
          type: 'userGroup',
          value: userGroup,
        })),
        ...recertificationSessionSpecificPermissionList.map(specificPermission => ({
          employeeId: specificPermission.employee.id,
          recertificationSessionUserIdentityId: specificPermission?.recertificationSessionUserIdentity?.id ?? 0,
          type: 'specificPermission',
          value: specificPermission,
        })),
      ];

      const employeesByEmployeeId = {};
      const recertificationSessionUserIdentityById = {};
      const employeeGrouped = allEmployeesByRecertificationSessionId.reduce((result, item) => {
        const { employeeId, recertificationSessionUserIdentityId, type, value } = item;
        employeesByEmployeeId[employeeId] = value.employee;
        if (type === 'userIdentity') {
          recertificationSessionUserIdentityById[recertificationSessionUserIdentityId] = value.recertificationSessionUserIdentity;
        }
        if (!result[employeeId]) {
          result[employeeId] = {};
        }
        if (!result[employeeId][recertificationSessionUserIdentityId]) {
          result[employeeId][recertificationSessionUserIdentityId] = {};
        }
        if (!result[employeeId][recertificationSessionUserIdentityId][type]) {
          result[employeeId][recertificationSessionUserIdentityId][type] = [];
        }
        result[employeeId][recertificationSessionUserIdentityId][type].push(value);
        return result;
      }, {});
      setEmployeeGrouped(employeeGrouped);
      setEmployeesByEmployeeId(employeesByEmployeeId);
      setFilteredEmployeesByEmployeeId(employeesByEmployeeId);
      setRecertificationSessionUserIdentityById(recertificationSessionUserIdentityById);
    }
  }, [
    loading,
    recertificationSessionUserIdentityList,
    recertificationSessionUserRoleList,
    recertificationSessionUserGroupList,
    recertificationSessionSpecificPermissionList,
  ]);

  useEffect(() => {
    if (search !== '' || Object.keys(employeesByEmployeeIdState).length > 0) {
      applySearch();
    }
  }, [search, employeesByEmployeeIdState]);

  const handleSearch = event => {
    setSearch(event.target.value);
  };

  const clearSearch = () => {
    setSearch('');
  };

  const applySearch = _.debounce(() => {
    if (search !== '') {
      const newEmployeeGrouped = _.keys(employeesByEmployeeIdState)
        .filter((employeeId, index) => {
          return (
            employeesByEmployeeIdState[employeeId].fullName?.toLowerCase().includes(search.toLowerCase()) ||
            employeesByEmployeeIdState[employeeId].code?.toLowerCase().includes(search.toLowerCase())
          );
        })
        .map(employeeId => [employeeId, employeesByEmployeeIdState[employeeId]]);
      setFilteredEmployeesByEmployeeId(Object.fromEntries(newEmployeeGrouped));
    } else {
      setFilteredEmployeesByEmployeeId(employeesByEmployeeIdState);
    }
  }, 1000);

  // recertification session revoke user identity from task
  const startRevokeUserIdentity = (recertificationSessionEntityId, recertificationSessionUserIdentityIdForRevokeInit) => {
    dispatch(revokeUserIdentityById({ recertificationSessionEntityId, recertificationSessionUserIdentityIdForRevokeInit }));
  };

  const modal = useModal();
  const revokeUserIdentity = (
    recertificationSessionEntityId,
    recertificationSessionUserIdentityIdForRevoke,
    userNameParam,
    applicationNameParam,
    employeeNameParam
  ) => {
    modal.show({
      size: 'LARGE',
      title: translate('iamdentityApp.recertificationSessionUserIdentity.revokeUser'),
      body: translate('iamdentityApp.recertificationSessionUserIdentity.revokeUserDialog', {
        userName: userNameParam,
        applicationName: applicationNameParam,
        employeeName: employeeNameParam,
      }),
      callback: startRevokeUserIdentity.bind(null, recertificationSessionEntityId, recertificationSessionUserIdentityIdForRevoke),
    });
  };

  return (
    <React.Fragment>
      {loading ? (
        <div className="entity-spinner-container">
          <Spinner color="primary">Loading...</Spinner>
        </div>
      ) : (
        <React.Fragment>
          {!isTask ? (
            <Col sm="12" className="mb-2">
              <InputGroup>
                <Input
                  type="text"
                  name="search"
                  value={search}
                  onChange={handleSearch}
                  placeholder={translate('iamdentityApp.employee.home.search')}
                />
                <Button type="reset" className="input-group-addon" onClick={clearSearch}>
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </InputGroup>
            </Col>
          ) : null}
          <UncontrolledAccordion open="" defaultOpen={['0']} stayOpen>
            {Object.keys(employeeGroupedState).length ? (
              _.keys(employeeGroupedState).map((employeeId, index) => {
                const employee = filteredEmployeesByEmployeeIdState[employeeId];
                if (employee) {
                  const key = index.toString();
                  return (
                    <AccordionItem key={`accordion-item-${key}`}>
                      <AccordionHeader className="accordion-employee-header" targetId={key}>
                        <div className="flex-div full-width justify-space-between mr-1">
                          <div>
                            <Translate contentKey="iamdentityApp.employee.detail.title">Employee</Translate>: {employee?.code} -{' '}
                            {employee?.fullName}
                          </div>
                        </div>
                      </AccordionHeader>
                      <AccordionBody accordionId={key}>
                        {_.keys(employeeGroupedState[employeeId])
                          .sort((a, b) => (b === '0' ? Number.MAX_VALUE : -parseInt(a, 10)))
                          .map(recertificationSessionUserIdentityId => {
                            const recertificationSessionUserIdentity =
                              recertificationSessionUserIdentityByIdState[recertificationSessionUserIdentityId];
                            return (
                              <div className="details-grid-container details-grid-row">
                                <div className="summary-container">
                                  <Card style={{ minHeight: 100 }}>
                                    <Col className="dark-text">
                                      {recertificationSessionUserIdentity?.id ? (
                                        <>
                                          <div className="flex-div full-width justify-space-between mb-1 text-primary fw-bold">
                                            <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.detail.title">
                                              Recertification Session User Identity
                                            </Translate>
                                          </div>
                                          <dl className="jh-entity-details">
                                            <dt>
                                              <Translate contentKey="iamdentityApp.application.detail.title">Application</Translate>:
                                            </dt>
                                            <dd>
                                              {recertificationSessionUserIdentity?.userIdentity ? (
                                                <Link to={`/application/${recertificationSessionUserIdentity.userIdentity.application.id}`}>
                                                  {recertificationSessionUserIdentity.userIdentity.application.name}
                                                </Link>
                                              ) : (
                                                ''
                                              )}
                                            </dd>
                                            <dt>
                                              <Translate contentKey="iamdentityApp.userIdentity.userName">Username</Translate>:
                                            </dt>
                                            <dd>
                                              {recertificationSessionUserIdentity?.userIdentity ? (
                                                <Link
                                                  to={`/user-identity/${
                                                    recertificationSessionUserIdentity.userIdentity.userName
                                                      ? recertificationSessionUserIdentity.userIdentity.id
                                                      : ''
                                                  }`}
                                                >
                                                  {recertificationSessionUserIdentity.userIdentity.userName
                                                    ? recertificationSessionUserIdentity.userIdentity.userName
                                                    : ''}
                                                </Link>
                                              ) : (
                                                ''
                                              )}
                                            </dd>
                                            <dt>
                                              <Translate contentKey="iamdentityApp.userIdentity.startDate">Valid From</Translate>:
                                            </dt>
                                            <dd>
                                              {recertificationSessionUserIdentity?.userIdentity?.startDate ? (
                                                <TextFormat
                                                  value={recertificationSessionUserIdentity.userIdentity.startDate}
                                                  type="date"
                                                  format={convertDateFormat(locale, 'instant')}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </dd>
                                            <dt>
                                              <Translate contentKey="iamdentityApp.userIdentity.endDate">Valid To</Translate>:
                                            </dt>
                                            <dd>
                                              {recertificationSessionUserIdentity?.userIdentity?.endDate ? (
                                                <TextFormat
                                                  value={recertificationSessionUserIdentity.userIdentity.endDate}
                                                  type="date"
                                                  format={convertDateFormat(locale, 'instant')}
                                                />
                                              ) : (
                                                ''
                                              )}
                                            </dd>
                                            {recertificationSessionUserIdentity?.revoked !== null &&
                                            recertificationSessionUserIdentity?.revoked !== undefined ? (
                                              <div>
                                                <dt>
                                                  <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revoked">
                                                    Revoked
                                                  </Translate>
                                                  :
                                                </dt>
                                                <dd>
                                                  {recertificationSessionUserIdentity.revoked === true ? (
                                                    recertificationSessionUserIdentity.order ? (
                                                      <div>
                                                        <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revokedByOrder">
                                                          Revoked by Order
                                                        </Translate>
                                                        :&nbsp;
                                                        <Link to={`/orders/${recertificationSessionUserIdentity.order.id}`}>
                                                          {recertificationSessionUserIdentity.order.id}
                                                        </Link>
                                                        &nbsp;
                                                        {recertificationSessionUserIdentity?.order?.status ? (
                                                          <span className="fw-bold text-black d-inline-block">
                                                            ({recertificationSessionUserIdentity?.order?.status})
                                                          </span>
                                                        ) : (
                                                          ''
                                                        )}
                                                      </div>
                                                    ) : (
                                                      <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.revokedByOrder">
                                                        Revoked by Order
                                                      </Translate>
                                                    )
                                                  ) : (
                                                    <Translate contentKey="iamdentityApp.recertificationSessionUserIdentity.confirmed">
                                                      Confirmed
                                                    </Translate>
                                                  )}
                                                </dd>
                                              </div>
                                            ) : null}
                                          </dl>
                                          {!isTask ? (
                                            <div className="text-center">
                                              <div className="btn-group flex-btn-group-container">
                                                <Button
                                                  tag={Link}
                                                  to={generateRecertificationSessionUserIdentityUrl(
                                                    recertificationSessionEntity,
                                                    recertificationSessionUserIdentity?.id,
                                                    'VIEW'
                                                  )}
                                                  className="btn-md"
                                                  color="info"
                                                  data-cy="entityDetailsButton"
                                                >
                                                  <FontAwesomeIcon icon="eye" />
                                                </Button>
                                              </div>
                                            </div>
                                          ) : null}
                                          {isTask ? (
                                            <div className="text-center">
                                              <div className="btn-group flex-btn-group-container">
                                                <Button
                                                  disabled={
                                                    recertificationSessionUserIdentity?.revoked === true ||
                                                    recertificationSessionUserIdentity?.revoked === false
                                                      ? true
                                                      : false
                                                  }
                                                  onClick={() =>
                                                    revokeUserIdentity(
                                                      recertificationSessionUserIdentity?.recertificationSession?.id,
                                                      recertificationSessionUserIdentity?.id,
                                                      recertificationSessionUserIdentity?.userIdentity?.userName,
                                                      recertificationSessionUserIdentity?.userIdentity?.application?.name,
                                                      employee?.code + ' - ' + employee?.fullName
                                                    )
                                                  }
                                                  className="btn-md"
                                                  color="primary"
                                                >
                                                  <FontAwesomeIcon icon="ban" />
                                                  &nbsp;
                                                  {translate('iamdentityApp.recertificationSession.revoke')}
                                                </Button>
                                              </div>
                                            </div>
                                          ) : null}
                                        </>
                                      ) : null}
                                    </Col>
                                  </Card>
                                </div>
                                <div className="content-container ml-1">
                                  <UncontrolledAccordion
                                    className={isTask ? 'bg-white' : ''}
                                    open=""
                                    defaultOpen={['assigned-roles', 'assigned-role-groups', 'assigned-specific-permissions']}
                                    stayOpen
                                    flush
                                  >
                                    {employeeGroupedState[employeeId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userRole'] && (
                                        <AccordionItem>
                                          <AccordionHeader className="accordion-employee-header" targetId="assigned-roles">
                                            <div className="flex-div full-width justify-space-between mr-1">
                                              <div>
                                                <Translate contentKey="iamdentityApp.recertificationSessionUserRole.home.title">
                                                  Recertification Session User Roles
                                                </Translate>
                                              </div>
                                            </div>
                                          </AccordionHeader>
                                          <AccordionBody accordionId="assigned-roles">
                                            <AssignedRecertificationSessionRolesTable
                                              isTask={isTask}
                                              recertificationSessionRoles={
                                                employeeGroupedState[employeeId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userRole']
                                                  ? employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userRole']
                                                  : []
                                              }
                                            />
                                          </AccordionBody>
                                        </AccordionItem>
                                      )}
                                    {employeeGroupedState[employeeId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userGroup'] && (
                                        <AccordionItem>
                                          <AccordionHeader className="accordion-employee-header" targetId="assigned-role-groups">
                                            <div className="flex-div full-width justify-space-between mr-1">
                                              <div>
                                                <Translate contentKey="iamdentityApp.recertificationSessionUserGroup.home.title">
                                                  Recertification Session User Group Roles
                                                </Translate>
                                              </div>
                                            </div>
                                          </AccordionHeader>
                                          <AccordionBody accordionId="assigned-role-groups">
                                            <AssignedRecertificationSessionGroupsTable
                                              isTask={isTask}
                                              recertificationSessionGroups={
                                                employeeGroupedState[employeeId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userGroup']
                                                  ? employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['userGroup']
                                                  : []
                                              }
                                            />
                                          </AccordionBody>
                                        </AccordionItem>
                                      )}
                                    {employeeGroupedState[employeeId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                      employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['specificPermission'] && (
                                        <AccordionItem>
                                          <AccordionHeader className="accordion-employee-header" targetId="assigned-specific-permissions">
                                            <div className="flex-div full-width justify-space-between mr-1">
                                              <div>
                                                <Translate contentKey="iamdentityApp.recertificationSessionSpecificPermission.home.title">
                                                  Recertification Session Specific Permissions
                                                </Translate>
                                              </div>
                                            </div>
                                          </AccordionHeader>
                                          <AccordionBody accordionId="assigned-specific-permissions">
                                            <AssignedRecertificationSessionSpecificPermissionsTable
                                              isTask={isTask}
                                              recertificationSessionSpecificPermissions={
                                                employeeGroupedState[employeeId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId] &&
                                                employeeGroupedState[employeeId][recertificationSessionUserIdentityId]['specificPermission']
                                                  ? employeeGroupedState[employeeId][recertificationSessionUserIdentityId][
                                                      'specificPermission'
                                                    ]
                                                  : []
                                              }
                                            />
                                          </AccordionBody>
                                        </AccordionItem>
                                      )}
                                  </UncontrolledAccordion>
                                </div>
                              </div>
                            );
                          })}
                      </AccordionBody>
                    </AccordionItem>
                  );
                }
              })
            ) : (
              <div className="alert alert-warning">
                <Translate contentKey="iamdentityApp.employee.home.notFound">Not found</Translate>
              </div>
            )}
          </UncontrolledAccordion>
        </React.Fragment>
      )}

      <ErrorBoundaryRoutes>
        <Route path="recertification-session-user">
          <Route path=":recertificationSessionUserIdentityId">
            <Route
              path="view"
              element={
                <RecertificationSessionInteractionModal>
                  <RecertificationSessionUserIdentityDetail />
                </RecertificationSessionInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="recertification-session-assigned-role">
          <Route path=":recertificationSessionUserIdentityRoleId">
            <Route
              path="view"
              element={
                <RecertificationSessionInteractionModal>
                  <RecertificationSessionUserRoleDetail />
                </RecertificationSessionInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="recertification-session-assigned-role-group">
          <Route path=":recertificationSessionUserGroupId">
            <Route
              path="view"
              element={
                <RecertificationSessionInteractionModal>
                  <RecertificationSessionUserGroupDetail />
                </RecertificationSessionInteractionModal>
              }
            />
          </Route>
        </Route>
        <Route path="recertification-session-assigned-specific-permission">
          <Route path=":recertificationSessionSpecificPermissionId">
            <Route
              path="view"
              element={
                <RecertificationSessionInteractionModal>
                  <RecertificationSessionSpecificPermissionDetail />
                </RecertificationSessionInteractionModal>
              }
            />
          </Route>
        </Route>
      </ErrorBoundaryRoutes>
    </React.Fragment>
  );
};

export default RecertificationSessionUsers;
