export const getQueryParamsIfExists = (locationString: string, defaultUrlIfNot?: string) => {
  let queryString = '';
  if (locationString.includes('query')) {
    queryString = locationString.substring(locationString.indexOf('query=') + 6, locationString.length);
  }
  return queryString ? queryString : defaultUrlIfNot ? defaultUrlIfNot : '';
};

export const getFilterPartialColumnsFromQueryString = (queryString: string): { name: string; value: string; criteria: string }[] => {
  if (!queryString) return [];
  const columns = [];
  const filterOptions = queryString.split('&');
  for (const option of filterOptions) {
    const [body, value] = option.split('=');
    const [name, criteria] = body.split('.');
    if (value && name && criteria) {
      const column = {
        name,
        value,
        criteria,
      };
      columns.push(column);
    }
  }
  return columns;
};
