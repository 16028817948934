import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { translate, Translate, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './recon-session-user-identity-iam-not-in-systems.reducer';

export const ReconSessionUserIdentityIamNotInSystemsDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const locale = useAppSelector(state => state.locale.currentLocale);
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const reconSessionUserIdentityIamNotInSystemsEntity = useAppSelector(state => state.reconSessionUserIdentityIamNotInSystems.entity);
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="reconSessionUserIdentityIamNotInSystemsDetailsHeading">
              <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.detail.title">
                ReconSessionUserIdentityIamNotInSystems
              </Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentityIamNotInSystemsEntity.id}</dd>
              <dt>
                <span id="processed">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.processed">Processed</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentityIamNotInSystemsEntity.processed ? translate('global.yes') : translate('global.no')}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.processedBy">Processed By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.processedBy
                  ? reconSessionUserIdentityIamNotInSystemsEntity.processedBy.login
                  : ''}
              </dd>
              <dt>
                <span id="comments">
                  <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.comments">Comments</Translate>
                </span>
              </dt>
              <dd>{reconSessionUserIdentityIamNotInSystemsEntity.comments}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.iamUserIdentity">Iam User Identity</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.iamUserIdentity
                  ? reconSessionUserIdentityIamNotInSystemsEntity.iamUserIdentity.userName
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.iamApplication">Application</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.iamUserIdentity?.application
                  ? reconSessionUserIdentityIamNotInSystemsEntity.iamUserIdentity.application.name
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.reconSessionUserIdentityIamNotInSystems.importSession">Import Session</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.importSession
                  ? reconSessionUserIdentityIamNotInSystemsEntity.importSession.id
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.createdBy ? reconSessionUserIdentityIamNotInSystemsEntity.createdBy : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.createdDate ? (
                  <TextFormat
                    value={reconSessionUserIdentityIamNotInSystemsEntity.createdDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.lastModifiedBy
                  ? reconSessionUserIdentityIamNotInSystemsEntity.lastModifiedBy
                  : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {reconSessionUserIdentityIamNotInSystemsEntity.lastModifiedDate ? (
                  <TextFormat
                    value={reconSessionUserIdentityIamNotInSystemsEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" color="info" onClick={() => navigate(-1)} data-cy="entityDetailsBackButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button
              tag={Link}
              to={`/recon-session-user-identity-iam-not-in-systems/${reconSessionUserIdentityIamNotInSystemsEntity.id}/edit`}
              replace
              color="primary"
            >
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
    </React.Fragment>
  );
};

export default ReconSessionUserIdentityIamNotInSystemsDetail;
