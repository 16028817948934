import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ApplicationResource from './application-resource';
import ApplicationResourceDetail from './application-resource-detail';
import ApplicationResourceUpdate from './application-resource-update';
import ApplicationResourceDeleteDialog from './application-resource-delete-dialog';

const ApplicationResourceRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ApplicationResource />} />
    <Route path="new" element={<ApplicationResourceUpdate />} />
    <Route path=":applicationResourceId">
      <Route index element={<ApplicationResourceDetail />} />
      <Route path="edit" element={<ApplicationResourceUpdate />} />
      <Route path="delete" element={<ApplicationResourceDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ApplicationResourceRoutes;
