import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InOrganizationUnit from './in-organization-unit';
import InOrganizationUnitDetail from './in-organization-unit-detail';
import InOrganizationUnitUpdate from './in-organization-unit-update';
import InOrganizationUnitDeleteDialog from './in-organization-unit-delete-dialog';

const InOrganizationUnitRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InOrganizationUnit />} />
    <Route path="new" element={<InOrganizationUnitUpdate />} />
    <Route path=":id">
      <Route index element={<InOrganizationUnitDetail />} />
      <Route path="edit" element={<InOrganizationUnitUpdate />} />
      <Route path="delete" element={<InOrganizationUnitDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InOrganizationUnitRoutes;
