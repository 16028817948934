import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileUserIdentity from './profile-user-identity';
import ProfileUserIdentityDetail from './profile-user-identity-detail';
import ProfileUserIdentityUpdate from './profile-user-identity-update';
import ProfileUserIdentityDeleteDialog from './profile-user-identity-delete-dialog';

const ProfileUserIdentityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileUserIdentity />} />
    <Route path="new" element={<ProfileUserIdentityUpdate />} />
    <Route path=":profileUserIdentityId">
      <Route index element={<ProfileUserIdentityDetail />} />
      <Route path="edit" element={<ProfileUserIdentityUpdate />} />
      <Route path="delete" element={<ProfileUserIdentityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileUserIdentityRoutes;
