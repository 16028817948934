import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IAMSystem from './iam-system';
import IAMSystemDetail from './iam-system-detail';
import IAMSystemUpdate from './iam-system-update';
import IAMSystemDeleteDialog from './iam-system-delete-dialog';

const IAMSystemRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IAMSystem />} />
    <Route path="new" element={<IAMSystemUpdate />} />
    <Route path=":id">
      <Route index element={<IAMSystemDetail />} />
      <Route path="edit" element={<IAMSystemUpdate />} />
      <Route path="delete" element={<IAMSystemDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IAMSystemRoutes;
