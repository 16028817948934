import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserGroupIamNotInSystems from './recon-session-user-group-iam-not-in-systems';
import ReconSessionUserGroupIamNotInSystemsDetail from './recon-session-user-group-iam-not-in-systems-detail';
import ReconSessionUserGroupIamNotInSystemsUpdate from './recon-session-user-group-iam-not-in-systems-update';
import ReconSessionUserGroupIamNotInSystemsDeleteDialog from './recon-session-user-group-iam-not-in-systems-delete-dialog';

const ReconSessionUserGroupIamNotInSystemsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserGroupIamNotInSystems />} />
    <Route path="new" element={<ReconSessionUserGroupIamNotInSystemsUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserGroupIamNotInSystemsDetail />} />
      <Route path="edit" element={<ReconSessionUserGroupIamNotInSystemsUpdate />} />
      <Route path="delete" element={<ReconSessionUserGroupIamNotInSystemsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserGroupIamNotInSystemsRoutes;
