import dayjs from 'dayjs';

export interface IInJobDefinition {
  id?: number;
  name?: string | null;
  internalCode?: string | null;
  publicCode?: string | null;
  validFrom?: string | null;
  validTo?: string | null;
}

export const defaultValue: Readonly<IInJobDefinition> = {};
