import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileDefinition from './profile-definition';
import ProfileDefinitionDeleteDialog from './profile-definition-delete-dialog';
import ProfileDefinitionDetail from './profile-definition-detail';
import ProfileDefinitionUpdate from './profile-definition-update';

const ProfileDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileDefinition />} />
    <Route path="new" element={<ProfileDefinitionUpdate />} />
    <Route path=":id">
      <Route index element={<ProfileDefinitionDetail />} />
      <Route path="edit" element={<ProfileDefinitionUpdate />} />
      <Route path="delete" element={<ProfileDefinitionDeleteDialog />} />
      <Route path="*" element={<ProfileDefinitionDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileDefinitionRoutes;
