import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { combineReducers } from 'redux';
import { translateBpmnXml } from '../components/bpmn/bpmn-react';

const initialState = {
  errorMessage: null,
  bpmnContent: [],
  bpmnXml: null,
};

/*
assignee
candidates
taskName
taskStartTime
taskStatus

taskEndTime
: 
"2023-02-08T09:54:16.133+00:00"
taskName
: 
"Calculeaza_Aprobatori"
taskStartTime
: 
"2023-02-08T09:54:15.513+00:00"
taskStatus
: 
"Completed"
*/
interface IBpmnContent {
  assignee?: string;
  candidates?: [];
  taskName: string;
  taskStartTime: string;
  taskEndTime?: string;
  taskStatus: string;
  taskId: string;
}

export const apiUrl = 'api/camunda-tasks';
export const apiUrlXml = 'api/bpmn-process';
// Async Actions

export const getBpmnContent = createAsyncThunk('bpmn/fetch_bpmncontent', async (orderId: number) => {
  const requestUrl = `${apiUrl}/${orderId}`;
  return axios.get<IBpmnContent[]>(requestUrl);
});

export const getBpmnXML = createAsyncThunk('bpmn/fetch_xml', async () => {
  const requestUrl = `${apiUrlXml}`;
  return axios.get<string>(requestUrl);
});

export type BpmnState = Readonly<typeof initialState>;

export const BpmnSlice = createSlice({
  name: 'bpmn',
  initialState: initialState as BpmnState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getBpmnContent.pending, state => state)
      .addCase(getBpmnContent.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      })
      .addCase(getBpmnContent.fulfilled, (state, action) => {
        state.bpmnContent = action.payload.data;
      })
      .addCase(getBpmnXML.pending, state => state)
      .addCase(getBpmnXML.rejected, (state, action) => {
        state.errorMessage = action.error.message;
      })
      .addCase(getBpmnXML.fulfilled, (state, action) => {
        state.bpmnXml = translateBpmnXml(action.payload.data);
      });
  },
});

const urlReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_URL':
      return {
        ...state,
        currentURL: action.payload,
      };
    default:
      return state;
  }
};

export const { reset } = BpmnSlice.actions;

// Reducer
export default BpmnSlice.reducer;
