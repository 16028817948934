import dayjs from 'dayjs';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IInUserGroup {
  id?: number;
  userBusinessKey?: string | null;
  groupCode?: string | null;
  status?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  applicationCode?: string | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IInUserGroup> = {};
