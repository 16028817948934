import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Form, Row, Spinner } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { IEmailTemplate } from 'app/shared/model/email-template.model';
import { EmailEvents } from 'app/shared/model/enumerations/email-events.model';
import { useRef } from 'react';
import EmailEditor, { EditorRef } from 'react-email-editor';
import { useForm } from 'react-hook-form';
import { createEntity, getEntity, getVariables, reset, updateEntity } from './email-template.reducer';

// Aici se pot edita optiunile default
const emailEditorOptions = {
  safeHtml: true,
};

export const EmailTemplateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [defaultLoading, setDefaultLoading] = useState<boolean>(true);
  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const emailTemplateEntity: IEmailTemplate = useAppSelector(state => state.emailTemplate.entity);
  const variables: any[] = useAppSelector(state => state.emailTemplate.variables);
  const loading = useAppSelector(state => state.emailTemplate.loading);
  const updating = useAppSelector(state => state.emailTemplate.updating);
  const updateSuccess = useAppSelector(state => state.emailTemplate.updateSuccess);
  const emailEventsValues = Object.keys(EmailEvents);

  const emailEditorRef = useRef<EditorRef | null>(null);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
    dispatch(getVariables());
  }, []);

  useEffect(() => {
    if (defaultLoading && emailTemplateEntity && variables.length) {
      setDefaultLoading(false);
    }
  }, [emailTemplateEntity, variables]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [emailTemplateEntity]);

  const saveEntity = async values => {
    const { html, json } = await getEmailDesign();

    const entity = {
      ...emailTemplateEntity,
      ...values,
      templateContent: html,
      templateJson: JSON.stringify(json),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? { emailEvent: EmailEvents.ORDER_PROCESSED }
      : {
          ...emailTemplateEntity,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const getEmailDesign = (): Promise<{ html; json }> => {
    return new Promise((resolve, reject) => {
      emailEditorRef.current?.editor?.exportHtml(data => {
        const { design, html } = data;
        resolve({ html, json: design });
      });
    });
  };

  const onLoad = () => {
    if (emailEditorRef.current) {
      emailEditorRef.current.editor.setMergeTags(variables);
      emailEditorRef.current.editor.loadDesign(emailTemplateEntity.templateJson ? JSON.parse(emailTemplateEntity.templateJson) : null);
    }
  };

  return (
    <Card className="jh-card pad-1">
      <Row className="margin-horizontal-centered col-md-8">
        <Col md="12">
          <h2 id="iamdentityApp.emailTemplate.home.createOrEditLabel" data-cy="EmailTemplateCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.emailTemplate.home.createOrEditLabel">Create or edit a EmailTemplate</Translate>
          </h2>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {defaultLoading || loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  className="margin-horizontal-centered col-md-8"
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="email-template-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <div className="margin-horizontal-centered col-md-8">
                <ValidatedInput
                  control={control}
                  label={'*' + translate('iamdentityApp.emailTemplate.name')}
                  id="email-template-name"
                  name="name"
                  data-cy="name"
                  type="text"
                  validate={{ required: true }}
                />
              </div>
              <ValidatedField
                className="margin-horizontal-centered col-md-8"
                register={register}
                label={translate('iamdentityApp.emailTemplate.emailEvent')}
                id="email-template-emailEvent"
                name="emailEvent"
                data-cy="emailEvent"
                type="select"
              >
                {emailEventsValues.map(emailEvents => (
                  <option value={emailEvents} key={emailEvents}>
                    {translate('iamdentityApp.EmailEvents.' + emailEvents)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                className="margin-horizontal-centered col-md-8"
                register={register}
                label={translate('iamdentityApp.emailTemplate.subject')}
                id="email-template-subject"
                name="subject"
                data-cy="subject"
                type="text"
              />
              <div className="flex-div flex-column align-items-center">
                <h3>{translate('iamdentityApp.emailTemplate.templateContent')}</h3>
                <div className="email-editor-container">
                  <EmailEditor editorId="email-editor" minHeight={612} ref={emailEditorRef} onReady={onLoad} options={emailEditorOptions} />
                </div>
              </div>
              <div className="margin-horizontal-centered col-md-8 mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default EmailTemplateUpdate;
