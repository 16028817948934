import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { CompleteTask, defaultValue, ITask, ITaskBusinessForm } from 'app/shared/model/task.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import axios from 'axios';
import { getEntities as getMyTasks } from 'app/entities/tasks/tasks.reducer';

const initialState: EntityState<ITask> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
  refresh: false,
};

export const apiUrl = 'api/user-tasks';

export const getEntities = createAsyncThunk('tasks/fetch_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return axios.get<ITask[]>(requestUrl);
});

export const getEntity = createAsyncThunk(
  'tasks/fetch_entity',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITask>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const completeTask = createAsyncThunk(
  'tasks/complete',
  async ({ id, payload }: { id: string | number; payload: CompleteTask }) => {
    const requestUrl = `${apiUrl}/${id}/complete`;
    return axios.patch<ITask>(requestUrl, payload);
  },
  { serializeError: serializeAxiosError }
);

export const claimTask = createAsyncThunk(
  'tasks/complete',
  async (id: string) => {
    const requestUrl = `${apiUrl}/${id}/claim`;
    return axios.get<ITask>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const delegateTask = createAsyncThunk(
  'tasks/delegate',
  async ({ taskId, login }: { taskId: string; login: string }) => {
    const requestUrl = `${apiUrl}/${taskId}/delegate/${login}`;
    return axios.get<ITask>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const cancelProcess = createAsyncThunk(
  'toa/cancel_process',
  async (toaId: string | number) => {
    const requestUrl = `api/user-process/cancel?toaId=${toaId}`;
    const result = await axios.post(requestUrl);
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const TaskSlice = createEntitySlice({
  name: 'tasks',
  initialState,
  reducers: {
    refreshTaskList(state) {
      state.refresh = true;
    },
    refreshCompleted(state) {
      state.refresh = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isFulfilled(completeTask, claimTask, delegateTask, cancelProcess), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
      })
      .addMatcher(isPending(completeTask, claimTask, delegateTask, cancelProcess), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset, refreshTaskList, refreshCompleted } = TaskSlice.actions;

// Reducer
export default TaskSlice.reducer;
