import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

import { APP_DATETIME_SAFE_FORMAT } from 'app/config/constants';
import { defaultValue, IIAMSystem } from 'app/shared/model/iam-system.model';
import { createEntitySlice, EntityState, IQueryParams, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { generateQueryUrl } from 'app/shared/util/url-utils';
import saveAs from 'file-saver';
import moment from 'moment';

const initialState: EntityState<IIAMSystem> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

export const apiUrl = 'api/iam-systems';
const apiSearchUrl = 'api/_search/iam-systems';

// Actions

export const searchEntities = createAsyncThunk('iAMSystem/search_entity', async ({ query, page, size, sort }: IQueryParams) => {
  const requestUrl = `${apiSearchUrl}?query=${query}${sort ? `&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return axios.get<IIAMSystem[]>(requestUrl);
});

export const getEntities = createAsyncThunk('iAMSystem/fetch_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}${generateQueryUrl(params)}`;
  return axios.get<IIAMSystem[]>(requestUrl);
});

export const exportEntities = createAsyncThunk('iAMSystem/export_entity_list', async (params: IQueryParams) => {
  const requestUrl = `${apiUrl}/export${generateQueryUrl(params)}`;
  return axios.get<any>(requestUrl, {
    responseType: 'blob',
  });
});

export const getEntity = createAsyncThunk(
  'iAMSystem/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IIAMSystem>(requestUrl);
  },
  { serializeError: serializeAxiosError }
);

export const createEntity = createAsyncThunk(
  'iAMSystem/create_entity',
  async (entity: IIAMSystem, thunkAPI) => {
    const result = await axios.post<IIAMSystem>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const updateEntity = createAsyncThunk(
  'iAMSystem/update_entity',
  async (entity: IIAMSystem, thunkAPI) => {
    const result = await axios.put<IIAMSystem>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const partialUpdateEntity = createAsyncThunk(
  'iAMSystem/partial_update_entity',
  async (entity: IIAMSystem, thunkAPI) => {
    const result = await axios.patch<IIAMSystem>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

export const deleteEntity = createAsyncThunk(
  'iAMSystem/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IIAMSystem>(requestUrl);
    thunkAPI.dispatch(getEntities({}));
    return result;
  },
  { serializeError: serializeAxiosError }
);

// slice

export const IAMSystemSlice = createEntitySlice({
  name: 'iAMSystem',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(exportEntities), (state, action) => {
        const { data } = action.payload;

        const filename = `iam_systems_${moment().format(APP_DATETIME_SAFE_FORMAT)}`;

        saveAs(data, `${filename}.xlsx`);
        return {
          ...state,
          loading: false,
        };
      })
      .addMatcher(isFulfilled(getEntities, searchEntities), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, searchEntities), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      });
  },
});

export const { reset } = IAMSystemSlice.actions;

// Reducer
export default IAMSystemSlice.reducer;
