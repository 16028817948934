export interface IConnectorType {
  id?: number;
  name?: string | null;
  description?: string | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IConnectorType> = {};
