export const is_valid_cnp = value => {
  // 0: cnp
  // 1: s (1-8)
  // 2: aa (00-99)
  // 3: ll (01-12)
  // 4: zz (01-31)
  // 5: jj (1-46, 51-52)
  // 6: nnn (001-999)
  // 7: c (279146358279)
  const RADIX = 10;
  let m;
  // tslint:disable-next-line: no-conditional-assignment
  if ((m = /^([1-8])(\d{2})(0[1-9]|1[0-2])(\d{2})(\d{2})(\d{3})(\d)$/.exec(value))) {
    const ll = parseInt(m[3], RADIX);
    const zz = parseInt(m[4], RADIX);
    switch (ll) {
      case 2:
        if (zz > 29) {
          return false;
        }
        break;
      case 3:
      case 4:
      case 6:
      case 9:
      case 11:
        if (zz > 30) {
          return false;
        }
        break;
      default:
        if (zz > 31) {
          return false;
        }
    }
    const jj = parseInt(m[5], RADIX);
    if (jj < 0 || (jj > 48 && jj < 51) || jj > 52) {
      return false;
    }
    const nnn = parseInt(m[6], RADIX);
    if (nnn < 0) {
      return false;
    }
    const c = parseInt(m[7], RADIX);
    const constanta = '279146358279';
    let suma = 0;
    for (let i = 0; i < constanta.length; i++) {
      suma = suma + parseInt(m[0].charAt(i), RADIX) * parseInt(constanta.charAt(i), RADIX);
    }
    const rest = suma % 11;
    // tslint:disable-next-line: triple-equals
    return (rest < 10 && rest == m[0].charAt(12)) || (rest == 10 && m[0].charAt(12) == 1) ? true : false;
  }
  return false;
};
