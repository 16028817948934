import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserGroupSystemsNotInIam from './recon-session-user-group-systems-not-in-iam';
import ReconSessionUserGroupSystemsNotInIamDetail from './recon-session-user-group-systems-not-in-iam-detail';
import ReconSessionUserGroupSystemsNotInIamUpdate from './recon-session-user-group-systems-not-in-iam-update';
import ReconSessionUserGroupSystemsNotInIamDeleteDialog from './recon-session-user-group-systems-not-in-iam-delete-dialog';

const ReconSessionUserGroupSystemsNotInIamRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserGroupSystemsNotInIam />} />
    <Route path="new" element={<ReconSessionUserGroupSystemsNotInIamUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserGroupSystemsNotInIamDetail />} />
      <Route path="edit" element={<ReconSessionUserGroupSystemsNotInIamUpdate />} />
      <Route path="delete" element={<ReconSessionUserGroupSystemsNotInIamDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserGroupSystemsNotInIamRoutes;
