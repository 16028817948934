import dayjs from 'dayjs';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IInSpecificPermission {
  id?: number;
  userBusinessKey?: string | null;
  permissionCode?: string | null;
  status?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  applicationCode?: string | null;
  applicationResourceCode?: string | null;
  importSession?: IImportSession | null;
}

export const defaultValue: Readonly<IInSpecificPermission> = {};
