import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RiskRule from './risk-rule';
import RiskRuleDetail from './risk-rule-detail';
import RiskRuleUpdate from './risk-rule-update';
import RiskRuleDeleteDialog from './risk-rule-delete-dialog';

const RiskRuleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RiskRule />} />
    <Route path="new" element={<RiskRuleUpdate />} />
    <Route path=":id">
      <Route index element={<RiskRuleDetail />} />
      <Route path="edit" element={<RiskRuleUpdate />} />
      <Route path="delete" element={<RiskRuleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RiskRuleRoutes;
