import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import '../style.scss';
import { IToaTask } from 'app/shared/model/task.model';
import { Link } from 'react-router-dom';

interface ITaskCardProps {
  task: IToaTask;
  showLinks?: boolean;
}

const TaskDetailsCard = ({ task, showLinks = false }: ITaskCardProps) => {
  return (
    <Card color="default" inverse>
      <CardHeader>
        <Translate contentKey={`iamdentityApp.tasks.taskDetails`} />
      </CardHeader>
      <CardBody>
        <CardText>ID : {task.toa.id}</CardText>

        {showLinks ? (
          <CardText>
            <Translate contentKey="iamdentityApp.tasks.applicationName">Application Name</Translate>:{' '}
            <Link to={`/application/${task.toa.application.id}`}>{task.toa.application.name}</Link>
          </CardText>
        ) : (
          <CardText>
            <Translate contentKey="iamdentityApp.tasks.applicationName">Application Name</Translate>: {task.toa.application.name}
          </CardText>
        )}

        <CardText>
          {task.toa.orderedUserIdentity ? (
            <span>{`${
              task.toa.orderedUserIdentity.operationType
                ? translate(`iamdentityApp.tasks.orderedUserIdentity.${task.toa.orderedUserIdentity.operationType.toLowerCase()}`) + ': '
                : ''
            }
          ${task.toa.orderedUserIdentity?.userName ? task.toa.orderedUserIdentity?.userName : ''}${
              task.toa.orderedUserIdentity.alternativeUserName ? ',' + task.toa.orderedUserIdentity.alternativeUserName : ''
            }
          ${task.toa.orderedUserIdentity?.displayName ? '(' + task.toa.orderedUserIdentity.displayName + ')' : ''}`}</span>
          ) : null}
          {task.toa.orderedRole ? (
            <span>{`${
              task.toa.orderedRole.operationType
                ? translate(`iamdentityApp.tasks.orderedRole.${task.toa.orderedRole.operationType.toLowerCase()}`) + ': '
                : ''
            }
            ${task.toa.orderedRole?.roleDefinition?.name ? task.toa.orderedRole.roleDefinition.name : ''}`}</span>
          ) : null}
          {task.toa.orderedGroup ? (
            <span>{`${
              task.toa.orderedGroup.operationType
                ? translate(`iamdentityApp.tasks.orderedGroup.${task.toa.orderedGroup.operationType.toLowerCase()}`) + ': '
                : ''
            }
            ${task.toa.orderedGroup?.roleGroup?.name ? task.toa.orderedGroup.roleGroup.name : ''}`}</span>
          ) : null}

          {task.toa.orderedSpecificPermission ? (
            <span>{`${
              task.toa.orderedSpecificPermission.operationType
                ? translate(
                    `iamdentityApp.tasks.orderedSpecificPermission.${task.toa.orderedSpecificPermission.operationType.toLowerCase()}`
                  ) + ': '
                : ''
            }
            ${
              task.toa.orderedSpecificPermission?.permissionDefinition?.name
                ? task.toa.orderedSpecificPermission.permissionDefinition.name
                : ''
            }`}</span>
          ) : null}
        </CardText>
        <CardText>
          {task.toa.executedCommand ? (
            <span>
              {translate(`iamdentityApp.tasks.executedCommand`)} : {task.toa.executedCommand}
            </span>
          ) : null}
        </CardText>
        <CardText>
          {task.toa.executionResult ? (
            <span>
              {translate(`iamdentityApp.tasks.executionResult`)} : {task.toa.executionResult}
            </span>
          ) : null}
        </CardText>
        <CardText>
          {task.toa.rawConnectorLog ? (
            <span>
              {translate(`iamdentityApp.tasks.rawConnectorLog`)} : {task.toa.rawConnectorLog}
            </span>
          ) : null}
        </CardText>
      </CardBody>
    </Card>
  );
};

export default TaskDetailsCard;
