import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import IAMdentityAccessToOrderTypes from './iamdentity-access-to-order-types';
import IAMdentityAccessToOrderTypesDetail from './iamdentity-access-to-order-types-detail';
import IAMdentityAccessToOrderTypesUpdate from './iamdentity-access-to-order-types-update';
import IAMdentityAccessToOrderTypesDeleteDialog from './iamdentity-access-to-order-types-delete-dialog';

const IAMdentityAccessToOrderTypesRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<IAMdentityAccessToOrderTypes />} />
    <Route path="new" element={<IAMdentityAccessToOrderTypesUpdate />} />
    <Route path=":id">
      <Route index element={<IAMdentityAccessToOrderTypesDetail />} />
      <Route path="edit" element={<IAMdentityAccessToOrderTypesUpdate />} />
      <Route path="delete" element={<IAMdentityAccessToOrderTypesDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default IAMdentityAccessToOrderTypesRoutes;
