import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InUserIdentity from './in-user-identity';
import InUserIdentityDetail from './in-user-identity-detail';
import InUserIdentityUpdate from './in-user-identity-update';
import InUserIdentityDeleteDialog from './in-user-identity-delete-dialog';

const InUserIdentityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InUserIdentity />} />
    <Route path="new" element={<InUserIdentityUpdate />} />
    <Route path=":id">
      <Route index element={<InUserIdentityDetail />} />
      <Route path="edit" element={<InUserIdentityUpdate />} />
      <Route path="delete" element={<InUserIdentityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InUserIdentityRoutes;
