import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileUserGroup from './profile-user-group';
import ProfileUserGroupDetail from './profile-user-group-detail';
import ProfileUserGroupUpdate from './profile-user-group-update';
import ProfileUserGroupDeleteDialog from './profile-user-group-delete-dialog';

const ProfileUserGroupRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileUserGroup />} />
    <Route path="new" element={<ProfileUserGroupUpdate />} />
    <Route path=":profileUserGroupId">
      <Route index element={<ProfileUserGroupDetail />} />
      <Route path="edit" element={<ProfileUserGroupUpdate />} />
      <Route path="delete" element={<ProfileUserGroupDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileUserGroupRoutes;
