import React from 'react';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { SORT } from './pagination.constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const handleSortIconsTable = (column: string) => {
  const params = new URLSearchParams(location.search);
  if (params['size'] > 0) {
    const sortParams = params.get(SORT).split(',');
    const getColumn = sortParams[0];
    const getSort = sortParams[1];
    if (getColumn === column && getSort === 'asc') return <FontAwesomeIcon icon={faSortUp} />;
    else if (getColumn === column && getSort === 'desc') return <FontAwesomeIcon icon={faSortDown} />;
  }
  return <FontAwesomeIcon icon="sort" />;
};
