import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './employee.reducer';
import { IEmployee } from 'app/shared/model/employee.model';
import { getEntity as getUserIdentityEntity } from 'app/entities/user-identity/user-identity.reducer';
import { IOrderType } from 'app/shared/model/order-type.model';
import { ICreateOrder, IOrder } from 'app/shared/model/order.model';
import { convertDateTimeToServer } from 'app/shared/util/date-utils';
import moment from 'moment';
import { APP_LOCAL_DATETIME_FORMAT } from 'app/config/constants';
import { createEntity as createOrder } from 'app/entities/orders/orders.reducer';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
interface IModifyUserDataProps {
  userIdentity: IUserIdentity;
}
const EmployeeRemoveUserDialog = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const userIdentityId = useParams()['userIdentityId'];
  const { id } = useParams<'id'>();
  const [loadModal, setLoadModal] = useState(false);

  const userIdentityEntitySPR: IUserIdentity = useAppSelector(state => state.userIdentity.entity);

  useEffect(() => {
    dispatch(getUserIdentityEntity(userIdentityId));
    setLoadModal(true);
  }, []);

  useEffect(() => {
    dispatch(getEntity(id));
    setLoadModal(true);
  }, []);

  const employeeEntity = useAppSelector(state => state.employee.entity);
  const userIdentityEntity = useAppSelector(state => state.userIdentity.entity);
  const { updateSuccess, entity: order } = useAppSelector(state => state.orders);

  const handleClose = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (updateSuccess && order && order.id) {
      navigate(`/orders/${order.id}`);
      setLoadModal(false);
    }
  }, [updateSuccess, order]);

  const confirmRemoveUser = () => {
    const userIdentityEntity: IUserIdentity = {
      id: Number(userIdentityId),
    };
    const orderType: IOrderType = {
      id: 15,
    };
    const orderEntity: IOrder = {
      orderType,
      scheduleTime: convertDateTimeToServer(moment().format(APP_LOCAL_DATETIME_FORMAT)),
    };

    const entity: ICreateOrder = {
      order: orderEntity,
      userIdentity: userIdentityEntity,
    };
    dispatch(createOrder(entity));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="employeeRemoveUserDialogHeading">
        <Translate contentKey="entity.removeUser.title">Confirm remove user operation</Translate>
      </ModalHeader>
      <ModalBody id="iamdentityApp.employee.removeUser.question">
        <Translate
          contentKey="iamdentityApp.employee.removeUser.question"
          interpolate={{
            userName: userIdentityEntitySPR.userName,
            employeeCode: employeeEntity.code,
            employeeName: employeeEntity.fullName,
          }}
        >
          Are you sure you want to Remove User ?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-remove-user-employee" data-cy="entityConfirmRemoveUserButton" color="danger" onClick={confirmRemoveUser}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.removeUser">Remove User</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default EmployeeRemoveUserDialog;
