import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import React from 'react';
import { Translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardLink, CardText, CardTitle } from 'reactstrap';
import '../style.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';

interface IRecertificationSessionCardProps {
  recertificationSession: IRecertificationSession;
  children?: any;
}

export const RecertificationSessionCard = ({ recertificationSession, children }: IRecertificationSessionCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);
  const statusBadge = <Badge color="warning">{recertificationSession?.status}</Badge>;
  const rsCreatedDate = (
    <Badge color="success">
      {<TextFormat value={recertificationSession?.createdDate} type="date" format={convertDateFormat(locale, 'instant')} />}
    </Badge>
  );
  const hasChildren = <Card color="prima  color=" prima />;
  return (
    <Card color="primary" inverse>
      <CardHeader className={hasChildren ? null : 'no-border'}>
        <CardTitle tag="h5">
          <CardLink className="text-white" href={`/recertification-session/${recertificationSession?.id}`}>
            <Translate contentKey="iamdentityApp.tasks.recertificationSession">Recertification Session</Translate> #
            {recertificationSession?.id}
          </CardLink>
        </CardTitle>
        <CardText>
          <span>{rsCreatedDate}</span>
          <span>{statusBadge}</span>
        </CardText>
      </CardHeader>
      {hasChildren ? <CardBody>{children}</CardBody> : null}
    </Card>
  );
};
export default RecertificationSessionCard;
