export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  IAM_TEAM: 'ROLE_IAM_TEAM',
  HR_ADMIN: 'ROLE_HR_ADMIN',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
export const APP_GLOBAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_GLOBAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_GLOBAL_INSTANT_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const APP_DATE_FORMAT_RO = 'DD.MM.YYYY';
export const APP_DATETIME_FORMAT_RO = 'DD.MM.YYYY HH:mm';
export const APP_DATETIME_SHORT_FORMAT = 'DD-MM-YY HH:mm';
export const APP_DATETIME_SAFE_FORMAT = 'DD_MM_YY_HH_mm';

export const APP_DATE_FORMAT_USA = 'MM/DD/YYYY';
export const APP_DATETIME_FORMAT_USA = 'MM/DD/YYYY HH:mm';

export const INPUT_ENUM_SEPARATOR = '|';
