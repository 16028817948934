import { IApplication } from 'app/shared/model/application.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import { IUserIdentityRole } from 'app/shared/model/user-identity-role.model';

export interface ISimulateResyncResultsList {
  notInIam: INotInIam[];
  notInSystems: INotInSystems[];
  notInIamCount: number;
  notInSystemsCount: number;
}

export interface INotInIam {
  id: number;
  applicationCode: string | null;
  userBusinessKey: string | null;
  userName: string | null;
  alternativeUserName: string | null;
  displayName: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  importSession: string | null;
  hasAssocUserIdentity: boolean;
  inUserRoles: IInUserRoles[];
  inUserGroups: IInUserGroups[];
  inSpecificPermissions: IInSpecificPermission[];
}

export interface INotInSystems {
  id: number;
  employee: IEmployee;
  application: IApplication;
  userName: string | null;
  alternativeUserName: string | null;
  status: string | null;
  startDate: string | null;
  endDate: string | null;
  grantedBy: string | null;
  explicitlyRemoved: string | null;
  lastModifiedByOrder: string | null;
  associatedEmailAddress: string | null;
  displayName: string | null;
  initialPassword: string | null;
  mustChangePasswordOnFirstLogin: boolean | null;
  dateNextChangePassword: string | null;
  period: string | null;
  hasAssocInUserIdentity: boolean;
  userIdentityRoles: IUserIdentityRole[];
  userGroups: IUserGroup[];
  specificPermissions: ISpecificPermission[];
}

interface IInSpecificPermission {
  endDate: null;
  id: null;
  permissionCode: 'perm1';
  startDate: null;
  status: null;
  userBusinessKey: 'user1';
}

interface IInUserGroups {
  id: null;
  userBusinessKey: 'user1';
  groupCode: 'grup1';
  status: null;
  startDate: null;
  endDate: null;
}

interface IInUserRoles {
  id: null;
  userBusinessKey: 'user1';
  roleCode: 'role1';
  status: null;
  startDate: null;
  endDate: null;
}

export interface ICheckAdminEmployeeProfile {
  value: boolean;
  message: checkSimulateProcessStatus;
}

export interface ICheckCanSimulateResync extends ICheckAdminEmployeeProfile {}

export enum checkSimulateProcessStatus {
  PROCESS_COMPLETE = 'PROCESS_COMPLETE',
  PROCESS_IN_PROGRESS = 'PROCESS_IN_PROGRESS',
  PROCESS_CAN_START = 'PROCESS_IN_PROGRESS',
}
