import { AppliedOnTypes } from './enumerations/applied-on-types.model';
import { ModificationLevelType } from './enumerations/modification-level-type.model';

export interface IOrderType {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  requiresApproval?: boolean | null;
  revertingOrderType?: IOrderType | null;
  orderCreateServiceClass?: string | null;
  appliedOn?: AppliedOnTypes | null;
  modificationLevel?: ModificationLevelType | null;
  forStandardPolicy?: boolean | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IOrderType> = {
  requiresApproval: false,
  forStandardPolicy: false,
};
