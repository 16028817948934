import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import PermissionDefinition from './permission-definition';
import PermissionDefinitionDeleteDialog from './permission-definition-delete-dialog';
import PermissionDefinitionDetail from './permission-definition-detail';
import PermissionDefinitionUpdate from './permission-definition-update';

const PermissionDefinitionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<PermissionDefinition />} />
    <Route path="new" element={<PermissionDefinitionUpdate />} />
    <Route path=":permissionDefinitionUpdateId">
      <Route index element={<PermissionDefinitionDetail />} />
      <Route path="edit" element={<PermissionDefinitionUpdate />} />
      <Route path="delete" element={<PermissionDefinitionDeleteDialog />} />
      {/* <Route path="*" element={<PermissionDefinitionDetail />} /> */}
    </Route>
  </ErrorBoundaryRoutes>
);

export default PermissionDefinitionRoutes;
