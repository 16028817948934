import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { IUserGroup } from 'app/shared/model/user-group.model';
import { IOrder } from 'app/shared/model/order.model';
import { IRecertificationSessionUserIdentity } from 'app/shared/model/recertification-session-user-identity.model';

export interface IRecertificationSessionUserGroup {
  id?: number;
  revoked?: boolean | null;
  recertificationSession?: IRecertificationSession;
  employee?: IEmployee;
  userGroup?: IUserGroup;
  order?: IOrder | null;
  recertificationSessionUserIdentity?: IRecertificationSessionUserIdentity | null;
}

export const defaultValue: Readonly<IRecertificationSessionUserGroup> = {
  revoked: false,
};
