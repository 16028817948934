import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Translate } from 'react-jhipster';
import RecertificationSessionProcess from './recertification-session-process';

export const RecertificationSessionProcessDialog = ({ isOpen, setRecertificationIsModalOpen }) => {
  const closeXRecertificationModal = () => {
    setRecertificationIsModalOpen(!isOpen);
  };

  return (
    <Modal size="xl" isOpen={isOpen}>
      <ModalHeader toggle={closeXRecertificationModal} className="sticky-top">
        <Translate contentKey="iamdentityApp.recertificationSession.home.startRecertificationProcess">
          Start Recertification Process
        </Translate>
      </ModalHeader>
      <ModalBody>
        <RecertificationSessionProcess setRecertificationIsModalOpen={setRecertificationIsModalOpen} />
      </ModalBody>
    </Modal>
  );
};

export default RecertificationSessionProcessDialog;
