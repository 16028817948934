import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderApprovalLevels from './order-approval-levels';
import OrderApprovalLevelsDetail from './order-approval-levels-detail';
import OrderApprovalLevelsUpdate from './order-approval-levels-update';
import OrderApprovalLevelsDeleteDialog from './order-approval-levels-delete-dialog';

const OrderApprovalLevelsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderApprovalLevels />} />
    <Route path="new" element={<OrderApprovalLevelsUpdate />} />
    <Route path=":id">
      <Route index element={<OrderApprovalLevelsDetail />} />
      <Route path="edit" element={<OrderApprovalLevelsUpdate />} />
      <Route path="delete" element={<OrderApprovalLevelsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderApprovalLevelsRoutes;
