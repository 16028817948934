import { ITechnicalOrderAction } from 'app/shared/model/technical-order-action.model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Translate, translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Card, Table, UncontrolledTooltip } from 'reactstrap';

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { APP_LOCAL_DATE_FORMAT, APP_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';

import Filter from 'app/shared/FilterComponent';
import { getQueryParamsIfExists } from 'app/shared/util/filter-utils';
import { exportEntities, getEntities, searchEntities } from './technical-order-action.reducer';
import { OrderExecutionStatus } from 'app/shared/model/enumerations/order-execution-status.model';
import PaginatedDropdown from 'app/shared/components/pagination-dropdown';
import { ToaExecutionStage } from 'app/shared/model/enumerations/toa-execution-stage.model';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { handleSortIconsTable } from 'app/shared/util/table-icons';

export const TechnicalOrderAction = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const [filterShown, setFilterShown] = useState<boolean>(true);
  const [query, setQuery] = useState<string>(getQueryParamsIfExists(location.search));

  const [search, setSearch] = useState('');

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const handlePageSizeChange = size => {
    paginationState.activePage = 1;
    paginationState.itemsPerPage = size;
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };

  const technicalOrderActionList = useAppSelector(state => state.technicalOrderAction.entities);
  const loading = useAppSelector(state => state.technicalOrderAction.loading);
  const totalItems = useAppSelector(state => state.technicalOrderAction.totalItems);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleFilter = () => {
    if (filterShown) setQuery('');
    setFilterShown(!filterShown);
  };

  const getAllEntities = () => {
    if (search) {
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    } else {
      dispatch(
        getEntities({
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
          query,
        })
      );
    }
  };

  const startSearching = e => {
    if (search) {
      setPaginationState({
        ...paginationState,
        activePage: 1,
      });
      dispatch(
        searchEntities({
          query: search,
          page: paginationState.activePage - 1,
          size: paginationState.itemsPerPage,
          sort: `${paginationState.sort},${paginationState.order}`,
        })
      );
    }
    e.preventDefault();
  };

  const clear = () => {
    setSearch('');
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    dispatch(getEntities({}));
  };

  const handleSearch = event => setSearch(event.target.value);

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}&query=${query}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort, search, query]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };
  const exportTable = () => {
    dispatch(
      exportEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
        query,
      })
    );
  };
  return (
    <Card className="jh-card">
      <h2 id="technical-order-action-heading" data-cy="TechnicalOrderActionHeading">
        <Translate contentKey="iamdentityApp.technicalOrderAction.home.title">Technical Order Actions</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="iamdentityApp.technicalOrderAction.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Button className="me-2" color="light" onClick={exportTable} disabled={loading}>
            <FontAwesomeIcon icon="file-excel" spin={loading} /> <Translate contentKey="entity.action.export">Export</Translate>
          </Button>
        </div>
      </h2>
      <div className="table-responsive table-scroll-custom table-input-number">
        <Table responsive>
          <thead className="table-scroll-custom_thead">
            <tr>
              <th className="hand" onClick={sort('id')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.id">ID</Translate> {handleSortIconsTable('id')}
              </th>
              <th className="hand" onClick={sort('executionStatus')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executionStatus">Execution Status</Translate>{' '}
                {handleSortIconsTable('executionStatus')}
              </th>
              <th className="hand" onClick={sort('executionStartTime')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executionStartTime">Execution Start Time</Translate>{' '}
                {handleSortIconsTable('executionStartTime')}
              </th>
              <th className="hand" onClick={sort('executionEndTime')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executionEndTime">Execution End Time</Translate>{' '}
                {handleSortIconsTable('executionEndTime')}
              </th>
              <th className="hand" onClick={sort('executedCommand')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executedCommand">Executed Command</Translate>{' '}
                {handleSortIconsTable('executedCommand')}
              </th>
              <th className="hand" onClick={sort('executionResult')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executionResult">Execution Result</Translate>{' '}
                {handleSortIconsTable('executionResult')}
              </th>
              <th className="hand" onClick={sort('priority')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.priority">Priority</Translate> {handleSortIconsTable('priority')}
              </th>
              <th className="hand" onClick={sort('connectorCommand.code')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.connectorCommand">Connector Command</Translate>{' '}
                {handleSortIconsTable('connectorCommand.code')}
              </th>
              <th className="hand" onClick={sort('iamSystem.name')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.iamSystem">Iam System</Translate>{' '}
                {handleSortIconsTable('iamSystem.name')}
              </th>
              <th className="hand" onClick={sort('application.name')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.application">Application</Translate>{' '}
                {handleSortIconsTable('application.name')}
              </th>
              <th className="hand" onClick={sort('orderedUserIdentity')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.orderedUserIdentity">Ordered User Identity</Translate>{' '}
                {handleSortIconsTable('orderedUserIdentity')}
              </th>
              <th className="hand" onClick={sort('orderedRole')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.orderedRole">Ordered Role</Translate>{' '}
                {handleSortIconsTable('orderedRole')}
              </th>
              <th className="hand" onClick={sort('orderedGroup')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.orderedGroup">Ordered Group</Translate>{' '}
                {handleSortIconsTable('orderedGroup')}
              </th>
              <th className="hand" onClick={sort('orderedSpecificPermission')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.orderedSpecificPermission">Ordered Specific Permission</Translate>{' '}
                {handleSortIconsTable('orderedSpecificPermission')}
              </th>
              <th className="hand" onClick={sort('order')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.order">Order</Translate> {handleSortIconsTable('order')}
              </th>
              <th className="hand" onClick={sort('rawConnectorLog')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.rawConnectorLog">Raw Connector Log</Translate>{' '}
                {handleSortIconsTable('rawConnectorLog')}
              </th>
              <th className="hand" onClick={sort('executionStage')}>
                <Translate contentKey="iamdentityApp.technicalOrderAction.executionStage">Execution Stage</Translate>{' '}
                {handleSortIconsTable('executionStage')}
              </th>
              <th className="text-right">
                <span>
                  <Button size="sm" id="toggle-filter" color={filterShown ? 'secondary' : 'default'} onClick={toggleFilter}>
                    <FontAwesomeIcon icon="magnifying-glass" />
                  </Button>
                </span>
                <UncontrolledTooltip placement="top" target="toggle-filter">
                  <Translate contentKey={filterShown ? 'global.hideFilter' : 'global.showFilter'}>Show/Hide Filter</Translate>
                </UncontrolledTooltip>
              </th>
            </tr>
            {filterShown && (
              <Filter
                getEntities={setQuery}
                columns={[
                  {
                    name: 'id',
                    type: 'ignore',
                  },
                  {
                    name: 'executionStatus',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(OrderExecutionStatus).map(value => ({
                        value,
                        label: translate(`iamdentityApp.OrderExecutionStatus.${value}`),
                      })),
                    ],
                  },
                  {
                    name: 'executionStartTime',
                    type: 'instant',
                  },
                  {
                    name: 'executionEndTime',
                    type: 'instant',
                  },
                  {
                    name: 'executedCommand',
                    type: 'string',
                  },
                  {
                    name: 'executionResult',
                    type: 'string',
                  },
                  {
                    name: 'priority',
                    type: 'number',
                  },
                  {
                    name: 'connectorCommand',
                    type: 'string',
                  },
                  {
                    name: 'iamSystem',
                    type: 'string',
                  },
                  {
                    name: 'application',
                    type: 'string',
                  },
                  {
                    name: 'orderedUserIdentity',
                    type: 'string',
                  },
                  {
                    name: 'orderedRoleId',
                    type: 'number',
                  },
                  {
                    name: 'orderedGroupId',
                    type: 'number',
                  },
                  {
                    name: 'orderedSpecificPermissionId',
                    type: 'number',
                  },
                  {
                    name: 'orderId',
                    type: 'number',
                  },
                  {
                    name: 'rawConnectorLog',
                    type: 'string',
                  },
                  {
                    name: 'executionStage',
                    type: 'select',
                    values: [
                      { value: 'placeholder', label: '' },
                      ...Object.values(ToaExecutionStage).map(value => ({
                        value,
                        label: translate(`iamdentityApp.ToaExecutionStage.${value}`),
                      })),
                    ],
                  },
                ]}
              />
            )}
          </thead>
          <tbody>
            {technicalOrderActionList.map((technicalOrderAction, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                <td>
                  <Button tag={Link} to={`/technical-order-action/${technicalOrderAction.id}`} color="link" size="sm">
                    {technicalOrderAction.id}
                  </Button>
                </td>
                <td>{translate(`iamdentityApp.OrderExecutionStatus.${technicalOrderAction.executionStatus}`)}</td>
                <td>
                  {technicalOrderAction.executionStartTime ? (
                    <TextFormat type="date" value={technicalOrderAction.executionStartTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>
                  {technicalOrderAction.executionEndTime ? (
                    <TextFormat type="date" value={technicalOrderAction.executionEndTime} format={convertDateFormat(locale, 'instant')} />
                  ) : null}
                </td>
                <td>{technicalOrderAction.executedCommand}</td>
                <td>{technicalOrderAction.executionResult}</td>
                <td>{technicalOrderAction.priority}</td>
                <td>
                  {technicalOrderAction.connectorCommand ? (
                    <Link to={`/connector-command/${technicalOrderAction.connectorCommand.id}`}>
                      {technicalOrderAction.connectorCommand.code}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.iamSystem ? (
                    <Link to={`/iam-system/${technicalOrderAction.iamSystem.id}`}>{technicalOrderAction.iamSystem.name}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.application ? (
                    <Link to={`/application/${technicalOrderAction.application.id}`}>{technicalOrderAction.application.name}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.orderedUserIdentity ? (
                    <Link to={`/ordered-user-identity/${technicalOrderAction.orderedUserIdentity.id}`}>
                      {technicalOrderAction.orderedUserIdentity.userName}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.orderedRole ? (
                    <Link to={`/ordered-role/${technicalOrderAction.orderedRole.id}`}>{technicalOrderAction.orderedRole.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.orderedGroup ? (
                    <Link to={`/ordered-group/${technicalOrderAction.orderedGroup.id}`}>{technicalOrderAction.orderedGroup.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.orderedSpecificPermission ? (
                    <Link to={`/ordered-specific-permission/${technicalOrderAction.orderedSpecificPermission.id}`}>
                      {technicalOrderAction.orderedSpecificPermission.id}
                    </Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>
                  {technicalOrderAction.order ? (
                    <Link to={`/orders/${technicalOrderAction.order.id}`}>{technicalOrderAction.order.id}</Link>
                  ) : (
                    ''
                  )}
                </td>
                <td>{technicalOrderAction.rawConnectorLog}</td>
                <td>{translate(`iamdentityApp.ToaExecutionStage.${technicalOrderAction.executionStage}`)}</td>
                <td className="text-end">
                  <div className="btn-group flex-btn-group-container">
                    <Button
                      tag={Link}
                      to={`/technical-order-action/${technicalOrderAction.id}`}
                      color="info"
                      size="sm"
                      data-cy="entityDetailsButton"
                    >
                      <FontAwesomeIcon icon="eye" />{' '}
                      <span className="d-none d-md-inline">
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {(!technicalOrderActionList || technicalOrderActionList.length < 1) && !loading && (
          <div className="alert alert-warning">
            <Translate contentKey="iamdentityApp.technicalOrderAction.home.notFound">No Technical Order Actions found</Translate>
          </div>
        )}
      </div>
      {totalItems ? (
        <div className={technicalOrderActionList && technicalOrderActionList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
            <PaginatedDropdown itemsPerPage={paginationState.itemsPerPage} handlePageSizeChange={handlePageSizeChange}></PaginatedDropdown>
          </div>
        </div>
      ) : (
        ''
      )}
    </Card>
  );
};

export default TechnicalOrderAction;
