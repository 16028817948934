import React, { useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFormat, Translate, translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity } from './recertification-session.reducer';
import { convertDateFormat } from 'app/shared/util/date-utils';
import RecertificationSessionUsers from './details-components/recertification-session-users/recertification-session-users';

export const RecertificationSessionDetail = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const recertificationSessionEntity = useAppSelector(state => state.recertificationSession.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);

  return (
    <div className="details-grid-container">
      <div className="summary-container">
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="recertificationSessionDetailsHeading">
              <Translate contentKey="iamdentityApp.recertificationSession.detail.title">RecertificationSession</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{recertificationSessionEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.recertificationSession.name">Name</Translate>
                </span>
              </dt>
              <dd>{recertificationSessionEntity.name}</dd>
              <dt>
                <span id="status">
                  <Translate contentKey="iamdentityApp.recertificationSession.status">Status</Translate>
                </span>
              </dt>
              <dd>{recertificationSessionEntity.status}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSession.application">Application</Translate>
              </dt>
              <dd>
                {recertificationSessionEntity.application ? (
                  <Link to={`/application/${recertificationSessionEntity.application.id}`}>
                    {recertificationSessionEntity.application.name}
                  </Link>
                ) : (
                  ''
                )}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSession.manager">Manager</Translate>
              </dt>
              <dd>
                {recertificationSessionEntity.manager ? (
                  <Link to={`/employee/${recertificationSessionEntity.manager.id}`}>
                    {recertificationSessionEntity.manager.code} - {recertificationSessionEntity.manager.fullName}
                  </Link>
                ) : (
                  ''
                )}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.recertificationSession.requestNumber">Request Number</Translate>
              </dt>
              <dd>{recertificationSessionEntity.requestNumber ? recertificationSessionEntity.requestNumber : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{recertificationSessionEntity.createdBy ? recertificationSessionEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {recertificationSessionEntity.createdDate ? (
                  <TextFormat value={recertificationSessionEntity.createdDate} type="date" format={convertDateFormat(locale, 'instant')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{recertificationSessionEntity.lastModifiedBy ? recertificationSessionEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {recertificationSessionEntity.lastModifiedDate ? (
                  <TextFormat
                    value={recertificationSessionEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'instant')}
                  />
                ) : null}
              </dd>
            </dl>
            <Row>
              <Col className="noPad">
                <Button
                  block
                  id="cancel-save"
                  data-cy="entityDetailsBackButton"
                  onClick={() => navigate(-1)}
                  color="info"
                  style={{ borderRadius: 0 }}
                >
                  <FontAwesomeIcon icon="arrow-left" />{' '}
                </Button>
              </Col>
            </Row>
          </Col>
        </Card>
      </div>
      <div className="content-container ml-1">
        <RecertificationSessionUsers recertificationSessionId={parseInt(id, 10)} />
      </div>
    </div>
  );
};

export default RecertificationSessionDetail;
