import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import MenuItem from './menu-item';
import { faDiagramProject } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export const OrdersRoutesData = () => {
  const location = useLocation(); // Obținem locația curentă

  // Lista de rute pentru submeniuri
  const subMenuRoutes = [
    '/orders',
    '/order-comments',
    '/ordered-user-identity',
    '/ordered-role',
    '/ordered-group',
    '/ordered-specific-permission',
    '/technical-order-action',
    '/technical-order-action-attribute',
  ];

  // Verificăm dacă vreuna dintre rutele de submeniu este activă
  const isSubMenuActive = subMenuRoutes.some(route => location.pathname.includes(route));

  return (
    <NavDropdown
      isActive={isSubMenuActive}
      icon={faDiagramProject}
      name={translate('global.menu.orders')}
      id="entity-menu"
      data-cy="entity"
      className="max-height-80 overflow-a"
    >
      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.orders" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/orders">
        <Translate contentKey="global.menu.entities.orders" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-comments">
        <Translate contentKey="global.menu.entities.orderComments" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-user-identity">
        <Translate contentKey="global.menu.entities.orderedUserIdentity" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-role">
        <Translate contentKey="global.menu.entities.orderedRole" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-group">
        <Translate contentKey="global.menu.entities.orderedGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/ordered-specific-permission">
        <Translate contentKey="global.menu.entities.orderedSpecificPermission" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action">
        <Translate contentKey="global.menu.entities.technicalOrderAction" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action-attribute">
        <Translate contentKey="global.menu.entities.technicalOrderActionAttribute" />
      </MenuItem>
    </NavDropdown>
  );
};
