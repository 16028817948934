import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { TextFormat, Translate } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row } from 'reactstrap';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { faClockRotateLeft } from '@fortawesome/free-solid-svg-icons';
import { AuditEntityName } from 'app/shared/model/audit.model';
import AuditModal from '../audit/audit-modal';
import { getEntity } from './connector-command-attribute.reducer';
import CompactExpressionBuilder from 'app/shared/components/expression-builder/compact-expression-builder';
import { convertDateFormat } from 'app/shared/util/date-utils';

export const ConnectorCommandAttributeDetail = () => {
  const dispatch = useAppDispatch();
  const [auditModal, setAuditModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const { connectorCommandAtributeUpdateId: id } = useParams<'connectorCommandAtributeUpdateId'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const connectorCommandAttributeEntity = useAppSelector(state => state.connectorCommandAttribute.entity);
  const locale = useAppSelector(state => state.locale.currentLocale);
  const toggleAuditModal = () => {
    setAuditModal(!auditModal);
  };
  return (
    <React.Fragment>
      <Row>
        <Card className="jh-card">
          <Col md="8">
            <h2 data-cy="connectorCommandAttributeDetailsHeading">
              <Translate contentKey="iamdentityApp.connectorCommandAttribute.detail.title">ConnectorCommandAttribute</Translate>
            </h2>
            <dl className="jh-entity-details">
              <dt>
                <span id="id">
                  <Translate contentKey="global.field.id">ID</Translate>
                </span>
              </dt>
              <dd>{connectorCommandAttributeEntity.id}</dd>
              <dt>
                <span id="name">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.name">Name</Translate>
                </span>
              </dt>
              <dd>{connectorCommandAttributeEntity.name}</dd>
              <dt>
                <span id="direction">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.direction">Direction</Translate>
                </span>
              </dt>
              <dd>{connectorCommandAttributeEntity.direction}</dd>
              <dt>
                <span id="description">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.description">Description</Translate>
                </span>
              </dt>
              <dd>{connectorCommandAttributeEntity.description}</dd>
              <dt>
                <span id="dataType">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.dataType">Data Type</Translate>
                </span>
              </dt>
              <dd>{connectorCommandAttributeEntity.dataType}</dd>
              <dt>
                <span id="attributeValueExpression">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.attributeValueExpression">
                    Attribute Value Expression
                  </Translate>
                </span>
              </dt>
              <dd>
                <CompactExpressionBuilder tree={connectorCommandAttributeEntity.attributeValueExpression} />
              </dd>
              <dt>
                <span id="validFrom">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.validFrom">Valid From</Translate>
                </span>
              </dt>
              <dd>
                {connectorCommandAttributeEntity.validFrom ? (
                  <TextFormat value={connectorCommandAttributeEntity.validFrom} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <span id="validTo">
                  <Translate contentKey="iamdentityApp.connectorCommandAttribute.validTo">Valid To</Translate>
                </span>
              </dt>
              <dd>
                {connectorCommandAttributeEntity.validTo ? (
                  <TextFormat value={connectorCommandAttributeEntity.validTo} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="iamdentityApp.connectorCommandAttribute.connectorCommand">Connector Command</Translate>
              </dt>
              <dd>{connectorCommandAttributeEntity.connectorCommand ? connectorCommandAttributeEntity.connectorCommand.code : ''}</dd>
              <dt>
                <Translate contentKey="iamdentityApp.connectorCommandAttribute.attributeDefinition">Attribute Definition</Translate>
              </dt>
              <dd>
                {connectorCommandAttributeEntity.attributeDefinition ? connectorCommandAttributeEntity.attributeDefinition.displayName : ''}
              </dd>
              <dt>
                <Translate contentKey="global.field.createdBy">Created By</Translate>
              </dt>
              <dd>{connectorCommandAttributeEntity.createdBy ? connectorCommandAttributeEntity.createdBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.createdDate">Created Date</Translate>
              </dt>
              <dd>
                {connectorCommandAttributeEntity.createdDate ? (
                  <TextFormat value={connectorCommandAttributeEntity.createdDate} type="date" format={convertDateFormat(locale, 'date')} />
                ) : null}
              </dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedBy">Last Modified By</Translate>
              </dt>
              <dd>{connectorCommandAttributeEntity.lastModifiedBy ? connectorCommandAttributeEntity.lastModifiedBy : ''}</dd>
              <dt>
                <Translate contentKey="global.field.lastModifiedDate">Last Modified Date</Translate>
              </dt>
              <dd>
                {connectorCommandAttributeEntity.lastModifiedDate ? (
                  <TextFormat
                    value={connectorCommandAttributeEntity.lastModifiedDate}
                    type="date"
                    format={convertDateFormat(locale, 'date')}
                  />
                ) : null}
              </dd>
            </dl>
            <Button id="cancel-save" data-cy="entityDetailsBackButton" onClick={() => navigate(-1)} color="info">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.back">Back</Translate>
              </span>
            </Button>
            &nbsp;
            <Button tag={Link} to={`/connector-command-attribute/${connectorCommandAttributeEntity.id}/edit`} color="primary">
              <FontAwesomeIcon icon="pencil-alt" />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.edit">Edit</Translate>
              </span>
            </Button>
            <Button onClick={toggleAuditModal} color="light">
              <FontAwesomeIcon icon={faClockRotateLeft} />{' '}
              <span className="d-none d-md-inline">
                <Translate contentKey="entity.action.history">History</Translate>
              </span>
            </Button>
          </Col>
        </Card>
      </Row>
      <AuditModal isOpen={auditModal} toggle={toggleAuditModal} entityId={id} entityName={AuditEntityName.CONNECTOR_COMMAND_ATTRIBUTE} />
    </React.Fragment>
  );
};

export default ConnectorCommandAttributeDetail;
