import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { apiUrl as employeesApi } from '../../../entities/employee/employee.reducer';
import { apiUrl as ordersApi } from '../../../entities/orders/orders.reducer';
import CompactExpressionBuilder from './compact-expression-builder';
import ExpressionBuilder from './expression-builder';
import './style.scss';
import { createTree } from './utils';

import { json } from '@codemirror/lang-json';
import ReactCodeMirror from '@uiw/react-codemirror';
import { Alert, Button, ButtonGroup, Col, FormGroup, Label, Row, TabContent, TabPane } from 'reactstrap';
import ControlledPaginatedReactSelect from '../react-select/controlled-paginated-react-select';
import initalJson1 from './mock-data/initial1.json';
import VerboseExpressionBuilder from './verbose-expression-builder';

const initialState = createTree(initalJson1);

export enum BuilderEntityContext {
  LOCATION = 'location',
  JOB_DEFINITION = 'job_definition',
  ORGANIZATION_UNIT = 'organization_unit',
  EMPLOYEE = 'employee',
  ORDERS = 'orders',
}

export const ExpressionBuilderSandbox = () => {
  const [tree, setTree] = useState(initialState);

  const [result, setResult] = useState(null);

  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const [activeTab, setActiveTab] = useState('1');

  const queryClient = useQueryClient();

  const createContext = () => {
    const contextList = [];

    if (selectedEmployee) {
      contextList.push({
        entityName: BuilderEntityContext.EMPLOYEE,
        translatableEntityName: 'Employee',
        ids: [selectedEmployee.id],
      });
    }

    if (selectedOrder) {
      contextList.push({
        entityName: BuilderEntityContext.ORDERS,
        translatableEntityName: 'Orders',
        ids: [selectedOrder.id],
      });
    }

    if (contextList.length) {
      return {
        expression: JSON.stringify(tree),
        contextList,
      };
    }

    return null;
  };

  const fetchResult = () => {
    const expressionContext = createContext();

    return axios.post(`api/evaluate-expression`, expressionContext, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  };

  const { mutate } = useMutation('if-result', fetchResult, {
    onSuccess: ({ data }) => {
      setResult(data);
      queryClient.invalidateQueries('if-result');
    },
    onError: (error: AxiosError) => {
      const { message, response } = error;
      const { status, data } = response;
      setResult({ message, status, data });
    },
  });

  useEffect(() => {
    if (selectedOrder || selectedEmployee) {
      mutate();
    } else {
      setResult(null);
    }
  }, [tree, selectedOrder, selectedEmployee]);

  return (
    <div className="eb-grid">
      <div className="settings">
        <h2>Settings</h2>
        <div className="flex-div flex-gap-1">
          <FormGroup>
            <Label>Order</Label>
            <ControlledPaginatedReactSelect
              value={selectedOrder}
              onChange={setSelectedOrder}
              url={ordersApi}
              queryProps={{
                name: 'id',
                type: 'number',
              }}
              hideSelectedOptions={true}
            />
          </FormGroup>
          <FormGroup>
            <Label>Employee</Label>
            <ControlledPaginatedReactSelect
              value={selectedEmployee}
              onChange={setSelectedEmployee}
              url={employeesApi}
              queryProps={{
                name: 'code',
                secondName: 'fullName',
                type: 'string',
              }}
              hideSelectedOptions={true}
            />
          </FormGroup>
        </div>
      </div>
      <div className="if">
        <h2>IF</h2>
        <ExpressionBuilder
          initialState={tree}
          exportType="OBJECT"
          onChange={t => {
            setTree(t);
          }}
        />
      </div>
      <div className="if-result">
        <ButtonGroup className="mb-1">
          <Button onClick={() => setActiveTab('1')} color="success">
            Response
          </Button>
          <Button onClick={() => setActiveTab('2')} color="warning">
            Payload
          </Button>
        </ButtonGroup>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <div>
                  {result != null && result != undefined ? (
                    <ReactCodeMirror theme="dark" value={JSON.stringify(result, null, 4)} height="400px" extensions={[json()]} />
                  ) : (
                    <Alert color="danger">Please configure context!</Alert>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <div>
                  <ReactCodeMirror theme="dark" value={JSON.stringify(tree, null, 4)} height="400px" extensions={[json()]} />
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
      <div className="compact">
        <h2>COMPACT</h2>
        <CompactExpressionBuilder tree={tree} />
      </div>
      <div className="verbose">
        <h2>VERBOSE</h2>
        <VerboseExpressionBuilder tree={tree} />
      </div>
    </div>
  );
};

export default ExpressionBuilderSandbox;
