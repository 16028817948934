import dayjs from 'dayjs';
import { IOrderType } from 'app/shared/model/order-type.model';
import { IApplication } from 'app/shared/model/application.model';
import { IAMdentityAccessPermissionType } from 'app/shared/model/enumerations/iamdentity-access-permission-type.model';

export interface IIAMdentityAccessToOrderTypes {
  id?: number;
  permissionType?: IAMdentityAccessPermissionType | null;
  validFrom?: string | null;
  validTo?: string | null;
  orderType?: IOrderType;
  application?: IApplication;
  authorityName?: string | null;
}

export const defaultValue: Readonly<IIAMdentityAccessToOrderTypes> = {};
