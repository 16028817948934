import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RecertificationSessionUserRole from './recertification-session-user-role';
import RecertificationSessionUserRoleDetail from './recertification-session-user-role-detail';
import RecertificationSessionUserRoleUpdate from './recertification-session-user-role-update';
import RecertificationSessionUserRoleDeleteDialog from './recertification-session-user-role-delete-dialog';

const RecertificationSessionUserRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RecertificationSessionUserRole />} />
    <Route path="new" element={<RecertificationSessionUserRoleUpdate />} />
    <Route path=":id">
      <Route index element={<RecertificationSessionUserRoleDetail />} />
      <Route path="edit" element={<RecertificationSessionUserRoleUpdate />} />
      <Route path="delete" element={<RecertificationSessionUserRoleDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecertificationSessionUserRoleRoutes;
