import dayjs from 'dayjs';
import { IProfileUserIdentity } from 'app/shared/model/profile-user-identity.model';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';

export interface IProfileUserIdentityRole {
  id?: number;
  descriptionExpression?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  roleDefinitionExpression?: string | null;
  profileUserIdentity?: IProfileUserIdentity | null;
  roleDefinition?: IRoleDefinition | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IProfileUserIdentityRole> = {};

export const ENTITY_NAME = 'ProfileUserIdentityRole';
