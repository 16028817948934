import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TechnicalOrderActionCreate from './technical-order-action-create';
import TechnicalOrderActionCreateDetail from './technical-order-action-create-detail';
import TechnicalOrderActionCreateUpdate from './technical-order-action-create-update';
import TechnicalOrderActionCreateDeleteDialog from './technical-order-action-create-delete-dialog';

const TechnicalOrderActionCreateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TechnicalOrderActionCreate />} />
    <Route path="new" element={<TechnicalOrderActionCreateUpdate />} />
    <Route path=":technicalOrderActionUpdateId">
      <Route index element={<TechnicalOrderActionCreateDetail />} />
      <Route path="edit" element={<TechnicalOrderActionCreateUpdate />} />
      <Route path="delete" element={<TechnicalOrderActionCreateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TechnicalOrderActionCreateRoutes;
