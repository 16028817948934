import React from 'react';
import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

interface IProfileInteractionModal {
  children?: any;
}

function ProfileInteractionModal({ children }: IProfileInteractionModal) {
  const navigate = useNavigate();
  const back = () => navigate(-1);

  return ReactDOM.createPortal(
    <Modal fullscreen isOpen={true} toggle={back}>
      <ModalHeader toggle={back} />
      <ModalBody>{children}</ModalBody>
    </Modal>,
    document.querySelector('body')
  );
}

export default ProfileInteractionModal;
