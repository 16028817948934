import { IApplication } from 'app/shared/model/application.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { RecertificationSessionStatus } from 'app/shared/model/enumerations/recertification-session-status.model';

export interface IRecertificationSession {
  id?: number;
  name?: string | null;
  requestNumber?: number | null;
  status?: RecertificationSessionStatus | null;
  application?: IApplication | null;
  manager?: IEmployee;
  createdDate?: string | null;
}

export const defaultValue: Readonly<IRecertificationSession> = {};
