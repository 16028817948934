import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserPermissionSystemsNotInIam from './recon-session-user-permission-systems-not-in-iam';
import ReconSessionUserPermissionSystemsNotInIamDetail from './recon-session-user-permission-systems-not-in-iam-detail';
import ReconSessionUserPermissionSystemsNotInIamUpdate from './recon-session-user-permission-systems-not-in-iam-update';
import ReconSessionUserPermissionSystemsNotInIamDeleteDialog from './recon-session-user-permission-systems-not-in-iam-delete-dialog';

const ReconSessionUserPermissionSystemsNotInIamRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserPermissionSystemsNotInIam />} />
    <Route path="new" element={<ReconSessionUserPermissionSystemsNotInIamUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserPermissionSystemsNotInIamDetail />} />
      <Route path="edit" element={<ReconSessionUserPermissionSystemsNotInIamUpdate />} />
      <Route path="delete" element={<ReconSessionUserPermissionSystemsNotInIamDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserPermissionSystemsNotInIamRoutes;
