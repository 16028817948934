export enum ToaExecutionStage {
  NOT_STARTED = 'NOT_STARTED',

  VERIFY_START_CONDITIONS = 'VERIFY_START_CONDITIONS',

  WAIT_FOR_TOA = 'WAIT_FOR_TOA',

  SEND_COMMAND = 'SEND_COMMAND',

  WAIT_RESPONSE = 'WAIT_RESPONSE',

  USER_TASK = 'USER_TASK',

  FINISHED = 'FINISHED',
}
