import React from 'react';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';
import { DropdownItem } from 'reactstrap';
import MenuItem from './menu-item';
import { faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

export const ConfigurationData = ({ isAdmin = false }) => {
  const location = useLocation(); // Obținem locația curentă

  // Lista de rute pentru submeniuri
  const subMenuRoutes = [
    '/connector-type',
    '/iam-system',
    '/application',
    '/application-resource',
    '/role-definition',
    '/role-group',
    '/permission-definition',
    '/custom-attribute-definition',
    '/order-type',
    '/order-approval-levels',
    '/technical-order-action-create',
    '/connector-command',
    '/connector-command-attribute',
    '/risk-rule',
    '/risk-combination',
    '/email-template',
    '/iamdentity-access-to-order-types',
  ];

  // Verificăm dacă vreuna dintre rutele de submeniu este activă
  const isSubMenuActive = subMenuRoutes.some(route => location.pathname.includes(route));

  return (
    <NavDropdown
      isActive={isSubMenuActive}
      icon={faScrewdriverWrench}
      name={translate('global.menu.configuration')}
      id="entity-menu"
      data-cy="entity"
      className="max-height-80 overflow-a"
    >
      <DropdownItem className="menu-header" header>
        <Translate contentKey="global.menu.configuration" />
      </DropdownItem>
      <MenuItem icon="asterisk" to="/connector-type">
        <Translate contentKey="global.menu.entities.connectorType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/iam-system">
        <Translate contentKey="global.menu.entities.iamSystem" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application">
        <Translate contentKey="global.menu.entities.application" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/application-resource">
        <Translate contentKey="global.menu.entities.applicationResource" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role-definition">
        <Translate contentKey="global.menu.entities.roleDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/role-group">
        <Translate contentKey="global.menu.entities.roleGroup" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/permission-definition">
        <Translate contentKey="global.menu.entities.permissionDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/custom-attribute-definition">
        <Translate contentKey="global.menu.entities.customAttributeDefinition" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-type">
        <Translate contentKey="global.menu.entities.orderType" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/order-approval-levels">
        <Translate contentKey="global.menu.entities.orderApprovalLevels" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/technical-order-action-create">
        <Translate contentKey="global.menu.entities.technicalOrderActionCreate" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connector-command">
        <Translate contentKey="global.menu.entities.connectorCommand" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/connector-command-attribute">
        <Translate contentKey="global.menu.entities.connectorCommandAttribute" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/risk-rule">
        <Translate contentKey="global.menu.entities.riskRule" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/risk-combination">
        <Translate contentKey="global.menu.entities.riskCombination" />
      </MenuItem>
      <MenuItem icon="asterisk" to="/email-template">
        <Translate contentKey="global.menu.entities.emailTemplate" />
      </MenuItem>
      {isAdmin && (
        <MenuItem icon="asterisk" to="/iamdentity-access-to-order-types">
          <Translate contentKey="global.menu.entities.iamDentityAccessToOrderTypes" />
        </MenuItem>
      )}
    </NavDropdown>
  );
};
