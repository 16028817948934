import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import RecertificationSessionUserIdentity from './recertification-session-user-identity';
import RecertificationSessionUserIdentityDetail from './recertification-session-user-identity-detail';
import RecertificationSessionUserIdentityUpdate from './recertification-session-user-identity-update';
import RecertificationSessionUserIdentityDeleteDialog from './recertification-session-user-identity-delete-dialog';

const RecertificationSessionUserIdentityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<RecertificationSessionUserIdentity />} />
    <Route path="new" element={<RecertificationSessionUserIdentityUpdate />} />
    <Route path=":id">
      <Route index element={<RecertificationSessionUserIdentityDetail />} />
      <Route path="edit" element={<RecertificationSessionUserIdentityUpdate />} />
      <Route path="delete" element={<RecertificationSessionUserIdentityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default RecertificationSessionUserIdentityRoutes;
