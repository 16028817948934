import { IOrderType } from 'app/shared/model/order-type.model';
import React from 'react';
import { translate, Translate } from 'react-jhipster';
import { Card, CardBody, CardHeader, CardText } from 'reactstrap';
import '../style.scss';
import { BPMType } from 'app/shared/model/enumerations/bpm-type.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { Link } from 'react-router-dom';

interface IOrderTypeCardProps {
  orderType: IOrderType;
  taskType: BPMType.Process_Order_Approval | BPMType.Process_TOA_Execution;
  employee?: IEmployee;
  showLinks?: boolean;
}

const OrderTypeCard = ({ orderType, taskType, employee, showLinks = false }: IOrderTypeCardProps) => {
  return (
    <React.Fragment>
      {taskType == BPMType.Process_Order_Approval && (
        <Card color="default" inverse>
          <CardHeader>
            <Translate contentKey="iamdentityApp.tasks.orderType">Order Type</Translate> : {orderType.name}
          </CardHeader>
          <CardBody>
            {orderType.appliedOn !== 'NONE' ? (
              <CardText>
                <Translate contentKey="iamdentityApp.orderType.appliedOn">appliedOn</Translate>:{' '}
                {translate(`iamdentityApp.AppliedOnTypes.${orderType.appliedOn}`)}
              </CardText>
            ) : null}
          </CardBody>
        </Card>
      )}
      {taskType == BPMType.Process_TOA_Execution && (
        <Card color="default" inverse>
          <CardHeader>
            <Translate contentKey="iamdentityApp.tasks.orderType">Order Type</Translate> : {orderType.name}
          </CardHeader>
          <CardBody>
            {showLinks ? (
              <CardText>
                <Translate contentKey="iamdentityApp.tasks.employee">Employee</Translate>:{' '}
                <Link to={`/employee/${employee?.id}`}>
                  {employee?.code} - {employee?.fullName}
                </Link>
              </CardText>
            ) : (
              <CardText>
                <Translate contentKey="iamdentityApp.tasks.employee">Employee</Translate>: {employee?.code} - {employee?.fullName}
              </CardText>
            )}

            {orderType.appliedOn !== 'NONE' ? (
              <CardText>
                <Translate contentKey="iamdentityApp.orderType.appliedOn">appliedOn</Translate>:{' '}
                {translate(`iamdentityApp.AppliedOnTypes.${orderType.appliedOn}`)}
              </CardText>
            ) : null}
          </CardBody>
        </Card>
      )}
    </React.Fragment>
  );
};

export default OrderTypeCard;
