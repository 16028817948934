import React, { useRef, useState } from 'react';
import { ModalContext } from './modal.provider';

export const useModalState = () => {
  const [visible, setVisible] = useState(false);
  const [size, setSize] = useState<'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'FULLSCREEN'>('SMALL');
  const [showFooter, setShowFooter] = useState(true);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const callbackRef = useRef(null);

  const show = (props: {
    title: string;
    body: any;
    callback?: Function;
    size?: 'SMALL' | 'MEDIUM' | 'LARGE' | 'EXTRA_LARGE' | 'FULLSCREEN';
    showFooter?: boolean;
  }) => {
    setVisible(true);
    setTitle(props.title);
    setBody(props.body);
    setSize(props.size ? props.size : 'MEDIUM');
    setShowFooter(props.showFooter == undefined ? true : props.showFooter);
    if (props.callback) {
      callbackRef.current = props.callback;
    }
  };

  const hide = () => {
    setVisible(false);
    setTitle('');
    setBody('');
    setSize('SMALL');
    setShowFooter(true);
    callbackRef.current = null;
  };

  return { visible, title, body, callbackRef, showFooter, size, show, hide };
};

const useModal = () => {
  const { show, hide, callbackRef } = React.useContext(ModalContext);
  return { show, hide, callbackRef };
};

export default useModal;
