export enum ModificationLevelType {
  READ_ONLY = 'READ_ONLY',
  ADD_NEW = 'ADD_NEW',
  MODIFY_EXISTING = 'MODIFY_EXISTING',
  DELETE_EXISTING = 'DELETE_EXISTING',
  ADD_AND_MODIFY_EXISTING = 'ADD_AND_MODIFY_EXISTING',
  ADD_MODIFY_DELETE = 'ADD_MODIFY_DELETE',
  PASSWORD = 'PASSWORD',
  ADD_EXCEPTION = 'ADD_EXCEPTION',
  DELETE_EXCEPTION = 'DELETE_EXCEPTION',
}
