import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, Card, Form } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities as getUserGroups } from 'app/entities/user-group/user-group.reducer';
import { IUser } from 'app/shared/model/user.model';
import { getUsers } from 'app/shared/reducers/user-management';
import { getEntities as getImportSessions } from 'app/entities/import-session/import-session.reducer';
import { getEntity, updateEntity, createEntity, reset } from './recon-session-user-group-iam-not-in-systems.reducer';
import { useForm } from 'react-hook-form';

export const ReconSessionUserGroupIamNotInSystemsUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const userGroups = useAppSelector(state => state.userGroup.entities);
  const users = useAppSelector(state => state.userManagement.users);
  const importSessions = useAppSelector(state => state.importSession.entities);
  const reconSessionUserGroupIamNotInSystemsEntity = useAppSelector(state => state.reconSessionUserGroupIamNotInSystems.entity);
  const loading = useAppSelector(state => state.reconSessionUserGroupIamNotInSystems.loading);
  const updating = useAppSelector(state => state.reconSessionUserGroupIamNotInSystems.updating);
  const updateSuccess = useAppSelector(state => state.reconSessionUserGroupIamNotInSystems.updateSuccess);
  const currentUser = useAppSelector(state => state.authentication.account);

  const [processedName, setProcessedName] = useState<IUser | null>(null);

  const handleClose = () => {
    navigate('/recon-session-user-group-iam-not-in-systems' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(reset());
      dispatch(getEntity(id));
    }

    dispatch(getUserGroups({}));
    dispatch(getUsers({}));
    dispatch(getImportSessions({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    setProcessedName(reconSessionUserGroupIamNotInSystemsEntity.processedBy);
    formReset(defaultValues());
  }, [reconSessionUserGroupIamNotInSystemsEntity]);

  const saveEntity = values => {
    const entity = {
      ...reconSessionUserGroupIamNotInSystemsEntity,
      ...values,
      iamUserGroup: reconSessionUserGroupIamNotInSystemsEntity?.iamUserGroup,
      processedBy: processedName,
      importSession: reconSessionUserGroupIamNotInSystemsEntity?.importSession,
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...reconSessionUserGroupIamNotInSystemsEntity,
          iamUserGroup: reconSessionUserGroupIamNotInSystemsEntity?.iamUserGroup?.userIdentity?.userName,
          processedBy: reconSessionUserGroupIamNotInSystemsEntity?.processedBy?.login,
          importSession: reconSessionUserGroupIamNotInSystemsEntity?.importSession?.id,
        };

  const {
    register,
    handleSubmit,
    reset: formReset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchProcessed: boolean = watch('processed');

  const handleProcessedChange = () => {
    if (!watchProcessed) {
      const name = currentUser.login;
      setProcessedName(currentUser);
      setValue('processedBy', name);
      return;
    }
    setProcessedName(null);
    setValue('processedBy', null);
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2
            id="iamdentityApp.reconSessionUserGroupIamNotInSystems.home.createOrEditLabel"
            data-cy="ReconSessionUserGroupIamNotInSystemsCreateUpdateHeading"
          >
            <Translate contentKey="iamdentityApp.reconSessionUserGroupIamNotInSystems.home.createOrEditLabel">
              Create or edit a ReconSessionUserGroupIamNotInSystems
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="recon-session-user-group-iam-not-in-systems-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserGroupIamNotInSystems.processed')}
                id="recon-session-user-group-iam-not-in-systems-processed"
                name="processed"
                data-cy="processed"
                check
                type="checkbox"
                onChange={handleProcessedChange}
              />
              <ValidatedField
                register={register}
                id="recon-session-user-group-iam-not-in-systems-processedBy"
                name="processedBy"
                readOnly
                data-cy="processedBy"
                label={translate('iamdentityApp.reconSessionUserGroupIamNotInSystems.processedBy')}
                type="text"
              ></ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.reconSessionUserGroupIamNotInSystems.comments')}
                id="recon-session-user-group-iam-not-in-systems-comments"
                name="comments"
                data-cy="comments"
                type="text"
              />
              <ValidatedField
                register={register}
                id="recon-session-user-group-iam-not-in-systems-iamUserGroup"
                name="iamUserGroup"
                readOnly
                data-cy="iamUserGroup"
                label={translate('iamdentityApp.reconSessionUserGroupIamNotInSystems.iamUserGroup')}
                type="text"
              ></ValidatedField>
              <ValidatedField
                register={register}
                id="recon-session-user-group-iam-not-in-systems-importSession"
                name="importSession"
                readOnly
                data-cy="importSession"
                label={translate('iamdentityApp.reconSessionUserGroupIamNotInSystems.importSession')}
                type="text"
              ></ValidatedField>
              <Button
                tag={Link}
                id="cancel-save"
                data-cy="entityCreateCancelButton"
                to="/recon-session-user-group-iam-not-in-systems"
                replace
                color="info"
              >
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default ReconSessionUserGroupIamNotInSystemsUpdate;
