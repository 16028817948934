import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderedUserIdentity from './ordered-user-identity';
import OrderedUserIdentityDetail from './ordered-user-identity-detail';
import OrderedUserIdentityUpdate from './ordered-user-identity-update';
import OrderedUserIdentityDeleteDialog from './ordered-user-identity-delete-dialog';

const OrderedUserIdentityRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderedUserIdentity />} />
    <Route path="new" element={<OrderedUserIdentityUpdate />} />
    <Route path=":orderedUserIdentityId">
      <Route index element={<OrderedUserIdentityDetail />} />
      <Route path="edit" element={<OrderedUserIdentityUpdate />} />
      <Route path="delete" element={<OrderedUserIdentityDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderedUserIdentityRoutes;
