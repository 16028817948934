import dayjs from 'dayjs';
import { IRoleDefinition } from 'app/shared/model/role-definition.model';
import { IApplication } from 'app/shared/model/application.model';

export interface IRoleGroup {
  id?: number;
  name?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  code?: string | null;
  roleDefinitions?: IRoleDefinition[] | null;
  application?: IApplication;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IRoleGroup> = {};
