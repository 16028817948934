import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ReconSessionUserPermissionIamNotInSystems from './recon-session-user-permission-iam-not-in-systems';
import ReconSessionUserPermissionIamNotInSystemsDetail from './recon-session-user-permission-iam-not-in-systems-detail';
import ReconSessionUserPermissionIamNotInSystemsUpdate from './recon-session-user-permission-iam-not-in-systems-update';
import ReconSessionUserPermissionIamNotInSystemsDeleteDialog from './recon-session-user-permission-iam-not-in-systems-delete-dialog';

const ReconSessionUserPermissionIamNotInSystemsRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ReconSessionUserPermissionIamNotInSystems />} />
    <Route path="new" element={<ReconSessionUserPermissionIamNotInSystemsUpdate />} />
    <Route path=":id">
      <Route index element={<ReconSessionUserPermissionIamNotInSystemsDetail />} />
      <Route path="edit" element={<ReconSessionUserPermissionIamNotInSystemsUpdate />} />
      <Route path="delete" element={<ReconSessionUserPermissionIamNotInSystemsDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ReconSessionUserPermissionIamNotInSystemsRoutes;
