import dayjs from 'dayjs';
import { Gender } from 'app/shared/model/enumerations/gender.model';
import { Nationality } from 'app/shared/model/enumerations/nationality.model';

export interface IPerson {
  id?: number;
  name?: string | null;
  firstName?: string | null;
  nationalIdentifier?: string | null;
  gender?: Gender | null;
  phoneNumber?: string | null;
  communicationEmail?: string | null;
  dateOfBirth?: string | null;
  nationality?: Nationality;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IPerson> = {};
