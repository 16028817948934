import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

import { defaultValue, IExpressionEntity } from 'app/shared/model/expression-entity.model';
import { createEntitySlice, EntityState, IQueryParams } from 'app/shared/reducers/reducer.utils';

const initialState: EntityState<IExpressionEntity> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/expression';

interface IExpressionParams {
  entityKey?: string;
}

// Actions

export const getExpressionFields = createAsyncThunk('expression/fetch_entity_list', async ({ entityKey }: IExpressionParams) => {
  const requestUrl = entityKey ? `${apiUrl}/fields?entityKey=${entityKey}` : `${apiUrl}/fields`;
  return axios.get<IExpressionEntity[]>(requestUrl);
});

// slice
export const ExpressionBuilderSlice = createEntitySlice({
  name: 'expression',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getExpressionFields), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getExpressionFields), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = ExpressionBuilderSlice.actions;

// Reducer
export default ExpressionBuilderSlice.reducer;
