import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import EmailTemplate from './email-template';
import EmailTemplateDetail from './email-template-detail';
import EmailTemplateUpdate from './email-template-update';
import EmailTemplateDeleteDialog from './email-template-delete-dialog';

const EmailTemplateRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<EmailTemplate />} />
    <Route path="new" element={<EmailTemplateUpdate />} />
    <Route path=":id">
      <Route index element={<EmailTemplateDetail />} />
      <Route path="edit" element={<EmailTemplateUpdate />} />
      <Route path="delete" element={<EmailTemplateDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default EmailTemplateRoutes;
