import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrganizationUnitType from './organization-unit-type';
import OrganizationUnitTypeDetail from './organization-unit-type-detail';
import OrganizationUnitTypeUpdate from './organization-unit-type-update';
import OrganizationUnitTypeDeleteDialog from './organization-unit-type-delete-dialog';

const OrganizationUnitTypeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrganizationUnitType />} />
    <Route path="new" element={<OrganizationUnitTypeUpdate />} />
    <Route path=":id">
      <Route index element={<OrganizationUnitTypeDetail />} />
      <Route path="edit" element={<OrganizationUnitTypeUpdate />} />
      <Route path="delete" element={<OrganizationUnitTypeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrganizationUnitTypeRoutes;
