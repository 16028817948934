import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import OrderedRole from './ordered-role';
import OrderedRoleDetail from './ordered-role-detail';
import OrderedRoleUpdate from './ordered-role-update';
import OrderedRoleDeleteDialog from './ordered-role-delete-dialog';

const OrderedRoleRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<OrderedRole />} />
    <Route path="new" element={<OrderedRoleUpdate />} />
    <Route path=":orderedRoleId">
      <Route index element={<OrderedRoleDetail />} />
      <Route path="edit" element={<OrderedRoleUpdate />} />
      <Route path="delete" element={<OrderedRoleDeleteDialog />} />
      <Route path="*" element={<OrderedRoleDetail />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default OrderedRoleRoutes;
