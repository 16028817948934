import { IOrder } from 'app/shared/model/order.model';
import React from 'react';
import { Translate, translate, TextFormat } from 'react-jhipster';
import { Badge, Card, CardBody, CardHeader, CardLink, CardText, CardTitle, Col } from 'reactstrap';
import '../style.scss';
import { convertDateFormat } from 'app/shared/util/date-utils';
import { useAppSelector } from 'app/config/store';

interface IOrderCardProps {
  order: IOrder;
  children?: any;
}

export const OrderCard = ({ order, children }: IOrderCardProps) => {
  const locale = useAppSelector(state => state.locale.currentLocale);
  const statusBadge = <Badge color="warning">{order.status ? translate(`iamdentityApp.OrderStatus.${order.status}`) : null}</Badge>;
  const temporaryBadge = order.temporary ? (
    <Badge color="danger">
      <Translate contentKey="iamdentityApp.tasks.temporary">Temporary</Translate>
    </Badge>
  ) : null;
  const scheduleTimeBadge = (
    <Badge color="success">{<TextFormat value={order?.scheduleTime} type="date" format={convertDateFormat(locale, 'instant')} />}</Badge>
  );

  const hasChildren = <Card color="prima  color=" prima />;
  return (
    <Card color="primary" inverse>
      <CardHeader className={hasChildren ? null : 'no-border'}>
        <CardTitle tag="h5">
          <CardLink className="text-white" href={`/orders/${order.id}`}>
            <Translate contentKey="iamdentityApp.tasks.order">Order</Translate> #{order.id}
          </CardLink>
        </CardTitle>

        <CardText>
          <span>{scheduleTimeBadge}</span>
          <span>{statusBadge}</span>
          <span>{temporaryBadge}</span>
        </CardText>
      </CardHeader>
      {hasChildren ? <CardBody>{children}</CardBody> : null}
    </Card>
  );
};
export default OrderCard;
