import dayjs from 'dayjs';

export interface IInProject {
  id?: number;
  name?: string | null;
  code?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export const defaultValue: Readonly<IInProject> = {};
