import { IInUserGroup } from 'app/shared/model/in-user-group.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserGroupSystemsNotInIam {
  id?: number;
  applicationCode?: string | null;
  userBusinessKey?: string | null;
  userName?: string | null;
  groupCode?: string | null;
  processed?: boolean | null;
  comments?: string | null;
  inUserGroup?: IInUserGroup | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IReconSessionUserGroupSystemsNotInIam> = {
  processed: false,
};
