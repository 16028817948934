import { ICustomAttributeDefinition } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';

export interface ICustomAttributeObjectType {
  id?: number;
  objectType?: CustomAttributeApplicableObjectType | null;
  attributeDefinition?: ICustomAttributeDefinition | null;
  customAttributeDefinition?: ICustomAttributeDefinition | null;
}

export interface INonCircularyCustomAttributeObjectType {
  id?: number;
  objectType?: CustomAttributeApplicableObjectType | null;
  attributeDefinition?: Omit<ICustomAttributeDefinition, 'objectTypes'> | null;
  customAttributeDefinition?: Omit<ICustomAttributeDefinition, 'objectTypes'> | null;
}

export const defaultValue: Readonly<ICustomAttributeObjectType> = {};
