import dayjs from 'dayjs';

export interface IInProjectAssignment {
  id?: number;
  projectCode?: string | null;
  employeeCode?: string | null;
  startDate?: string | null;
  endDate?: string | null;
}

export const defaultValue: Readonly<IInProjectAssignment> = {};
