import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProfileSpecificPermission from './profile-specific-permission';
import ProfileSpecificPermissionDetail from './profile-specific-permission-detail';
import ProfileSpecificPermissionUpdate from './profile-specific-permission-update';
import ProfileSpecificPermissionDeleteDialog from './profile-specific-permission-delete-dialog';

const ProfileSpecificPermissionRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProfileSpecificPermission />} />
    <Route path="new" element={<ProfileSpecificPermissionUpdate />} />
    <Route path=":profileSpecificPermissionId">
      <Route index element={<ProfileSpecificPermissionDetail />} />
      <Route path="edit" element={<ProfileSpecificPermissionUpdate />} />
      <Route path="delete" element={<ProfileSpecificPermissionDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProfileSpecificPermissionRoutes;
