import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spinner, Form } from 'reactstrap';
import ValidatedInput from 'app/shared/components/validated-input';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntitiesByObjectType as getCustomPropertiesByObjectType,
  reset as resetCA,
} from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { addCustomPropertiesToCustomAttributesMap, extractCustomAttributesAsEntityProperties } from 'app/shared/util/entity-utils';
import { useForm } from 'react-hook-form';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import { createEntity, getEntity, reset, updateEntity } from './job-definition.reducer';
import moment from 'moment';
import { useRef } from 'react';
import { APP_GLOBAL_DATE_FORMAT } from 'app/config/constants';

export const JobDefinitionUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const jobDefinitionEntity = useAppSelector(state => state.jobDefinition.entity);
  const loading = useAppSelector(state => state.jobDefinition.loading);
  const updating = useAppSelector(state => state.jobDefinition.updating);
  const updateSuccess = useAppSelector(state => state.jobDefinition.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const startDate = useRef('');
  const handleClose = () => {
    navigate(-1);
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? { startDate: moment().format(APP_GLOBAL_DATE_FORMAT) }
      : {
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, jobDefinitionEntity),
        };
    startDate.current = result?.startDate;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetCA());
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.EMPLOYEE));
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [jobDefinitionEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(jobDefinitionEntity, values, customAttributeDefinitions),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.jobDefinition.home.createOrEditLabel" data-cy="JobDefinitionCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.jobDefinition.home.createOrEditLabel">Create or edit a JobDefinition</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="job-definition-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.jobDefinition.name')}
                name="name"
                id="job-definition-name"
                data-cy="name"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.jobDefinition.internalCode')}
                name="internalCode"
                id="job-definition-internalCode"
                data-cy="internalCode"
                type="text"
                validate={{ required: true }}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.jobDefinition.publicCode')}
                id="job-definition-publicCode"
                name="publicCode"
                data-cy="publicCode"
                type="text"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.jobDefinition.startDate')}
                id="job-definition-startDate"
                name="startDate"
                data-cy="startDate"
                type="date"
                onChange={event => (startDate.current = event.target.value)}
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.jobDefinition.endDate')}
                id="job-definition-endDate"
                name="endDate"
                data-cy="endDate"
                type="date"
                min={startDate.current}
              />
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  control: control,
                  register: register,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `job-definition-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default JobDefinitionUpdate;
