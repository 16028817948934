import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecertificationSessionTask } from 'app/shared/model/task.model';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Card, CardBody, CardHeader, CardTitle, Form, Spinner } from 'reactstrap';
import '../style.scss';
import { translate } from 'react-jhipster';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { completeTask } from '../tasks.reducer';
import RecertificationSessionUsers from 'app/entities/recertification-session/details-components/recertification-session-users/recertification-session-users';

const RecertificationSessionFormCard = () => {
  const dispatch = useAppDispatch();
  const task: IRecertificationSessionTask = useAppSelector(state => state.task.entity);
  const loading = useAppSelector(state => state.task.loading);
  const updating = useAppSelector(state => state.task.updating);

  const completeManual = () => {
    const payload = {
      recertificationSession: { id: task?.recertificationSession?.id },
      businessForm: null,
      variables: null,
    };

    dispatch(completeTask({ id: task.id, payload }));
  };

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    reset: formReset,
  } = useForm({
    mode: 'onChange',
  });

  const submitForm = values => {
    completeManual();
  };

  return (
    <Card color="secondary" inverse className="form-card">
      <CardHeader>
        <CardTitle className="mb-0" tag="h5">
          {translate('iamdentityApp.tasks.taskForm')}
        </CardTitle>
      </CardHeader>
      <CardBody className="p-0">
        {loading || updating ? (
          <div className="entity-spinner-container">
            <Spinner color="primary">Loading...</Spinner>
          </div>
        ) : (
          <div>
            <div className="session-users-container-details-rs mt-2">
              <RecertificationSessionUsers recertificationSessionId={task?.recertificationSession?.id} isTask={true} />
            </div>
            <Form onSubmit={handleSubmit(submitForm)}>
              <div className="d-flex justify-content-end mb-2 mt-2">
                <Button color="success" id="save-entity" disabled={updating} data-cy="entityCreateSaveButton" type="submit">
                  <FontAwesomeIcon icon="save" /> {translate('iamdentityApp.tasks.recertificationSessionConfirmed')}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default RecertificationSessionFormCard;
