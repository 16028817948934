import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InLocation from './in-location';
import InLocationDetail from './in-location-detail';
import InLocationUpdate from './in-location-update';
import InLocationDeleteDialog from './in-location-delete-dialog';

const InLocationRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InLocation />} />
    <Route path="new" element={<InLocationUpdate />} />
    <Route path=":id">
      <Route index element={<InLocationDetail />} />
      <Route path="edit" element={<InLocationUpdate />} />
      <Route path="delete" element={<InLocationDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InLocationRoutes;
