export enum AppliedOnTypes {
  NONE = 'NONE',

  POSITION = 'POSITION',

  USER = 'USER',

  ROLE = 'ROLE',

  GROUP = 'GROUP',

  PERMISSION = 'PERMISSION',
}
