import React from 'react';
import { Controller } from 'react-hook-form';
import ExpressionBuilder from './expression-builder';
import { IUncontrolledExpressionBuilderProps } from './types';

function UncontrolledExpressionBuilder(props: IUncontrolledExpressionBuilderProps) {
  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        return <ExpressionBuilder onChange={onChange} initialState={value} {...props} />;
      }}
    />
  );
}

export default UncontrolledExpressionBuilder;
