import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { NavDropdown } from './menu-components';
import { Translate, translate } from 'react-jhipster';
import { useLocation } from 'react-router-dom';

const adminMenuItems = () => (
  <>
    <MenuItem icon="eye" to="/admin/tracker">
      <Translate contentKey="global.menu.admin.tracker">User tracker</Translate>
    </MenuItem>
    <MenuItem icon="tachometer-alt" to="/admin/metrics">
      <Translate contentKey="global.menu.admin.metrics">Metrics</Translate>
    </MenuItem>
    <MenuItem icon="heart" to="/admin/health">
      <Translate contentKey="global.menu.admin.health">Health</Translate>
    </MenuItem>
    <MenuItem icon="cogs" to="/admin/configuration">
      <Translate contentKey="global.menu.admin.configuration">Configuration</Translate>
    </MenuItem>
    <MenuItem icon="tasks" to="/admin/logs">
      <Translate contentKey="global.menu.admin.logs">Logs</Translate>
    </MenuItem>
    {/* jhipster-needle-add-element-to-admin-menu - JHipster will add entities to the admin menu here */}
  </>
);

const openAPIItem = () => (
  <MenuItem icon="book" to="/admin/docs">
    <Translate contentKey="global.menu.admin.apidocs">API</Translate>
  </MenuItem>
);

export const AdminMenu = ({ showOpenAPI }) => {
  const location = useLocation(); // Obținem locația curentă

  // Lista de rute pentru submeniuri
  const subMenuRoutes = ['/admin/tracker', '/admin/metrics', '/admin/health', '/admin/configuration', '/admin/logs', '/admin/docs'];

  // Verificăm dacă vreuna dintre rutele de submeniu este activă
  const isSubMenuActive = subMenuRoutes.some(route => location.pathname.includes(route));
  return (
    <NavDropdown
      isActive={isSubMenuActive}
      icon="users-cog"
      name={translate('global.menu.admin.main')}
      id="admin-menu"
      data-cy="adminMenu"
      className="max-height-80 overflow-a bgc-custom"
    >
      {adminMenuItems()}
      {showOpenAPI && openAPIItem()}
    </NavDropdown>
  );
};

export default AdminMenu;
