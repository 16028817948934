import dayjs from 'dayjs';
import { IApplicationResource } from 'app/shared/model/application-resource.model';

export interface IPermissionDefinition {
  id?: number;
  name?: string | null;
  code?: string | null;
  description?: string | null;
  startDate?: string | null;
  endDate?: string | null;
  applicationResource?: IApplicationResource | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IPermissionDefinition> = {};
