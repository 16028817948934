import dayjs from 'dayjs';

export interface IInEmployeeDelegation {
  id?: number;
  employeeCode?: string | null;
  delegatedJobDefinitionInternalCode?: string | null;
  delegatedOrganizationUnitCode?: string | null;
  validFrom?: string | null;
  validTo?: string | null;
  cumulated?: boolean | null;
}

export const defaultValue: Readonly<IInEmployeeDelegation> = {
  cumulated: false,
};
