import React from 'react';
import { Controller } from 'react-hook-form';
import PaginatedSelectComponent from './paginated-select-component';
import './style.scss';
import { IPaginatedReactSelectProps } from './types';

function PaginatedReactSelect(props: IPaginatedReactSelectProps) {
  return (
    <Controller
      rules={props?.validate}
      control={props.control}
      name={props.name}
      render={({ field: { onChange, value, ref, onBlur }, fieldState: { error, isTouched }, formState }) => {
        return (
          <PaginatedSelectComponent
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            error={error}
            isTouched={isTouched}
            innerRef={ref}
            {...props}
          />
        );
      }}
    />
  );
}

export default PaginatedReactSelect;
