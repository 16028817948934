import { IQueryParams } from '../reducers/reducer.utils';

export const getLoginUrl = () => {
  const port = location.port ? `:${location.port}` : '';

  // If you have configured multiple OIDC providers, then, you can update this URL to /login.
  // It will show a Spring Security generated login page with links to configured OIDC providers.
  return `//${location.hostname}${port}${location.pathname}oauth2/authorization/oidc`;
};
export const REDIRECT_URL = 'redirectURL';

export const generateQueryUrl = ({ query, page, size, sort }: IQueryParams) => {
  return `${sort ? `?page=${page}&size=${size}&sort=${sort}&` : '?'}${
    query ? (query.endsWith('&') ? query : `${query}&`) : '?'
  }cacheBuster=${new Date().getTime()}`;
};

export const buildSearchParamsObject = (searchParams: URLSearchParams): any => {
  const newSearchParamsObject = {};

  searchParams.forEach((value, key) => {
    const keyFragments = key.split('.');
    if (keyFragments.length == 1) {
      newSearchParamsObject[keyFragments[0]] = value;
    } else if (keyFragments.length == 2) {
      if (!newSearchParamsObject[keyFragments[0]]) {
        newSearchParamsObject[keyFragments[0]] = {};
      }
      newSearchParamsObject[keyFragments[0]][keyFragments[1]] = value;
    }
  });

  return newSearchParamsObject;
};
