import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InUserGroup from './in-user-group';
import InUserGroupDetail from './in-user-group-detail';
import InUserGroupUpdate from './in-user-group-update';
import InUserGroupDeleteDialog from './in-user-group-delete-dialog';

const InUserGroupRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InUserGroup />} />
    <Route path="new" element={<InUserGroupUpdate />} />
    <Route path=":id">
      <Route index element={<InUserGroupDetail />} />
      <Route path="edit" element={<InUserGroupUpdate />} />
      <Route path="delete" element={<InUserGroupDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InUserGroupRoutes;
