import React, { useState } from 'react';
import PaginatedSelectComponent from './paginated-select-component';
import './style.scss';
import { IUncontrolledPaginatedReactSelectProps } from './types';

function ControlledPaginatedReactSelect(props: IUncontrolledPaginatedReactSelectProps) {
  const [isTouched, setIsTouched] = useState(false);

  return <PaginatedSelectComponent onBlur={() => null} error={null} isTouched={isTouched} {...props} />;
}

export default ControlledPaginatedReactSelect;
