export enum OrderExecutionStatus {
  NOT_APPLICABLE = 'NOT_APPLICABLE',

  NOT_STARTED = 'NOT_STARTED',

  PROCESSING = 'PROCESSING',

  HAS_ERRORS = 'HAS_ERRORS',

  SUCCESS = 'SUCCESS',

  MANUALLY_PROCESSED = 'MANUALLY_PROCESSED',

  CANCELLED = 'CANCELLED',
}
