import { IRecertificationSession } from 'app/shared/model/recertification-session.model';
import { IEmployee } from 'app/shared/model/employee.model';
import { ISpecificPermission } from 'app/shared/model/specific-permission.model';
import { IOrder } from 'app/shared/model/order.model';
import { IRecertificationSessionUserIdentity } from 'app/shared/model/recertification-session-user-identity.model';

export interface IRecertificationSessionSpecificPermission {
  id?: number;
  revoked?: boolean | null;
  recertificationSession?: IRecertificationSession;
  employee?: IEmployee;
  userSpecificPermission?: ISpecificPermission;
  order?: IOrder | null;
  recertificationSessionUserIdentity?: IRecertificationSessionUserIdentity | null;
}

export const defaultValue: Readonly<IRecertificationSessionSpecificPermission> = {
  revoked: false,
};
