import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, Col, Row, Spinner, Form } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import {
  getEntitiesByObjectType as getCustomPropertiesByObjectType,
  reset as resetCA,
} from 'app/entities/custom-attribute-definition/custom-attribute-definition.reducer';
import { CustomAttributeDtoKey } from 'app/shared/model/custom-attribute-definition.model';
import { CustomAttributeApplicableObjectType } from 'app/shared/model/enumerations/custom-attribute-applicable-object-type.model';
import { Gender } from 'app/shared/model/enumerations/gender.model';
import { renderCustomAttributeField } from 'app/shared/util/component-utils';
import {
  addCustomPropertiesToCustomAttributesMap,
  extractCustomAttributesAsEntityProperties,
  convertReactSelectValuesToEntityIds,
} from 'app/shared/util/entity-utils';
import { createEntity, getEntity, reset, updateEntity } from './person.reducer';
import { Nationality } from 'app/shared/model/enumerations/nationality.model';
import { is_valid_cnp } from 'app/shared/util/cnp';
import { is_valid_email } from 'app/shared/util/email';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { is_valid_age } from 'app/shared/util/ageValidation';
import { AUTHORITIES } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getApiConfigurations } from 'app/modules/administration/administration.reducer';
import 'react-toastify/dist/ReactToastify.css';
import ValidatedInput from 'app/shared/components/validated-input';
import { Controller } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import { convertDateFormat } from 'app/shared/util/date-utils';
import ro from 'date-fns/locale/ro';
import moment from 'moment';
import { toast } from 'react-toastify';

export const PersonUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const personEntity = useAppSelector(state => state.person.entity);
  const loading = useAppSelector(state => state.person.loading);
  const updating = useAppSelector(state => state.person.updating);
  const updateSuccess = useAppSelector(state => state.person.updateSuccess);
  const customAttributeDefinitions: CustomAttributeDtoKey[] = useAppSelector(state => state.customAttributeDefinition.entities);
  const genderValues = Object.keys(Gender);
  const nationalityValues = Object.keys(Nationality);
  const nationality = useRef('');
  const configuration = useAppSelector(state => state.administration.configuration);
  const allowManualPersonCreationProp = configuration?.apiConfigProps?.allowManualPersonCreation;
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isHRAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.HR_ADMIN]));

  useEffect(() => {
    dispatch(getApiConfigurations());
  }, []);

  useEffect(() => {
    if (allowManualPersonCreationProp !== undefined && (!(isAdmin || isHRAdmin) || !allowManualPersonCreationProp)) {
      toast.error(translate('error.unauthorizedMessage'));
      navigate('/');
    }
  }, [isAdmin, isHRAdmin, allowManualPersonCreationProp, navigate]);

  const handleClose = () => {
    navigate(-1);
  };

  const locale = useAppSelector(state => state.locale.currentLocale);

  const defaultValues = () => {
    const result = isNew
      ? {
          dateOfBirth: null,
        }
      : {
          gender: 'MALE',
          nationality: 'RO',
          ...extractCustomAttributesAsEntityProperties(customAttributeDefinitions, personEntity),
        };
    nationality.current = result?.nationality;
    return result;
  };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    formState: { errors, touchedFields },
    watch,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
    shouldFocusError: true,
  });

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetCA());
    dispatch(getCustomPropertiesByObjectType(CustomAttributeApplicableObjectType.PERSON));
  }, [id]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [personEntity, customAttributeDefinitions]);

  const saveEntity = values => {
    const entity = {
      ...addCustomPropertiesToCustomAttributesMap(personEntity, convertReactSelectValuesToEntityIds(values), customAttributeDefinitions),
      dateOfBirth: values.dateOfBirth ? moment(values.dateOfBirth).format('YYYY-MM-DD') : null, // Format dateOfBirth for server
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const watchNationality = watch('nationality');

  return (
    <Card className="jh-card">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.person.home.createOrEditLabel" data-cy="PersonCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.person.home.createOrEditLabel">Create or edit a Person</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  disabled
                  id="person-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.person.name')}
                name="name"
                data-cy="name"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {errors.name?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.name.message === 'string' && errors.name.message}
                </span>
              )}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.person.firstName')}
                name="firstName"
                data-cy="firstName"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                }}
              />
              {errors.firstName?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.firstName.message === 'string' && errors.firstName.message}
                </span>
              )}
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.person.nationalIdentifier')}
                name="nationalIdentifier"
                data-cy="nationalIdentifier"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate(value) {
                    if (value && watchNationality && watchNationality == 'RO') {
                      return is_valid_cnp(value) ? true : translate('entity.validation.nationalIdentifierNotValid');
                    }
                  },
                }}
              />
              {errors.nationalIdentifier?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.nationalIdentifier.message === 'string' && errors.nationalIdentifier.message}
                </span>
              )}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.person.gender')}
                id="person-gender"
                name="gender"
                data-cy="gender"
                type="select"
              >
                {genderValues.map(gender => (
                  <option value={gender} key={gender}>
                    {translate('iamdentityApp.Gender.' + gender)}
                  </option>
                ))}
              </ValidatedField>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.person.phoneNumber')}
                id="person-phoneNumber"
                name="phoneNumber"
                data-cy="phoneNumber"
                type="text"
              />
              <ValidatedInput
                control={control}
                label={'*' + translate('iamdentityApp.person.communicationEmail')}
                name="communicationEmail"
                data-cy="communicationEmail"
                type="text"
                validate={{
                  required: { value: true, message: translate('entity.validation.required') },
                  validate(value) {
                    return is_valid_email(value) ? true : translate('entity.validation.emailNotValid');
                  },
                }}
              />
              {errors.communicationEmail?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.communicationEmail.message === 'string' && errors.communicationEmail.message}
                </span>
              )}
              <div className="date-picker-container mb-3">
                <Controller
                  control={control}
                  name="dateOfBirth"
                  rules={{
                    required: { value: true, message: translate('entity.validation.required') },
                    validate(value) {
                      return is_valid_age(value) ? true : translate('entity.validation.ageNotValid');
                    },
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <div className="form-group">
                      <label htmlFor="person-dateOfBirth" className="form-label">
                        *{translate('iamdentityApp.person.dateOfBirth')}
                      </label>
                      <div className="position-relative">
                        <DatePicker
                          selected={value ? new Date(value) : new Date()}
                          onChange={date => {
                            onChange(date ? moment(date, convertDateFormat(locale, 'date')).toDate() : null);
                          }}
                          value={value ? moment(value).format(convertDateFormat(locale, 'date')) : convertDateFormat(locale, 'date')}
                          onBlur={onBlur}
                          locale={locale === 'ro' ? ro : 'en'}
                          todayButton={<Translate contentKey="global.today">Today</Translate>}
                          showMonthDropdown
                          showYearDropdown
                          className={`form-control ${errors.dateOfBirth ? 'is-invalid' : ''}`}
                          id="person-dateOfBirth"
                          data-cy="dateOfBirth"
                        />
                        <span
                          onClick={() => {
                            onChange(null);
                          }}
                          className="fw-semibold close-icon-custom"
                        >
                          {value !== null && value !== convertDateFormat(locale, 'date') ? 'X' : ''}
                        </span>
                      </div>
                    </div>
                  )}
                />
              </div>
              {errors.dateOfBirth?.message && (
                <span style={{ marginTop: '-1.8rem', marginBottom: '1.5rem' }} className="invalid-feedback d-block">
                  {typeof errors.dateOfBirth.message === 'string' && errors.dateOfBirth.message}
                </span>
              )}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.person.nationality')}
                id="person-nationality"
                name="nationality"
                data-cy="nationality"
                type="select"
                onChange={event => (nationality.current = event.target.value)}
              >
                {nationalityValues.map(nationality => (
                  <option value={nationality} key={nationality}>
                    {translate('iamdentityApp.Nationality.' + nationality)}
                  </option>
                ))}
              </ValidatedField>
              {customAttributeDefinitions?.map(cad =>
                renderCustomAttributeField({
                  register: register,
                  control: control,
                  locale: locale,
                  options: cad.selectableOptions,
                  id: `person-${cad.displayName}`,
                  name: cad.displayName,
                  label: cad.displayName,
                  'data-cy': cad.displayName,
                  attributeType: cad.customAttributeType,
                })
              )}
              <div className="mt-1">
                <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;
                <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </div>
            </Form>
          )}
        </Col>
      </Row>
    </Card>
  );
};

export default PersonUpdate;
