import dayjs from 'dayjs';
import { PermissionGrantLevel } from 'app/shared/model/enumerations/permission-grant-level.model';
import { IUserIdentity } from 'app/shared/model/user-identity.model';
import { IPermissionDefinition } from 'app/shared/model/permission-definition.model';
import { IOrder } from 'app/shared/model/order.model';
import { UserStatus } from 'app/shared/model/enumerations/user-status.model';

export interface ISpecificPermission {
  id?: number;
  description?: string | null;
  status?: UserStatus | null;
  startDate?: string | null;
  endDate?: string | null;
  grantedBy?: PermissionGrantLevel | null;
  explicitlyRemoved?: boolean | null;
  userIdentity?: IUserIdentity | null;
  permissionDefinition?: IPermissionDefinition | null;
  lastModifiedByOrder?: IOrder | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<ISpecificPermission> = {};
