import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';
import EditTask from './edit-task';
import MyTasks from './my-tasks';

const TaskRoutes = () => (
  <div>
    <ErrorBoundaryRoutes>
      <Route path="*" element={<MyTasks />}></Route>
    </ErrorBoundaryRoutes>
    <ErrorBoundaryRoutes>
      <Route path="/:id">
        <Route index element={<EditTask />} />
        <Route path="*" element={<EditTask />} />
      </Route>
    </ErrorBoundaryRoutes>
  </div>
);

export default TaskRoutes;
