import { BpmnVisualization, FitType } from 'bpmn-visualization';
import moment from 'moment';
import React, { useRef } from 'react';
import { translate } from 'react-jhipster';

enum OrderApprovalTranslationKeys {
  APPROVE_LEVEL_1 = 'Aproba_Level_1',
  APPROVE_LEVEL_2 = 'Aproba_Level_2',
  APPROVE_LEVEL_3 = 'Aproba_Level_3',
  SET_APPROVED_STATUS = 'Set_Approved_Status',
  CALCULEAZA_APROBATORI = 'Calculeaza_Aprobatori',
  SET_CANCELED_STATUS = 'Set_Canceled_Status',
  REVIEW_ORDER = 'Revizuieste_Cererea',
  NEEDS_APPROVAL_LELVEL_1 = 'Necesita_Aprobare_Level_1',
  NEEDS_APPROVAL_LELVEL_2 = 'Necesita_Aprobare_Level_2',
  NEEDS_APPROVAL_LELVEL_3 = 'Necesita_Aprobare_Level_3',
  REVIZUIT_CEREREA = 'Revizuit_Cererea',
  CANCEL = 'cancel_arrow',
  YES = 'yes_arrow',
  NO = 'no_arrow',
}

export function translateBpmnXml(xml) {
  for (const value of Object.values(OrderApprovalTranslationKeys)) {
    xml = xml.replaceAll(value, translate(`iamdentityApp.bpmn.orderApproval.${value}`));
  }
  return xml;
}

const BpmnDiagram = ({ bpmnXml, activityHistory }) => {
  const containerRef = useRef(null);

  const renderDiagram = bpmnXml => {
    containerRef.current.innerHTML = '';
    const bpmnVisualization = new BpmnVisualization({
      container: 'bpmn-container',
      navigation: { enabled: true },
    });

    bpmnVisualization.load(bpmnXml, { fit: { type: FitType.Center } });
    bpmnVisualization.bpmnElementsRegistry.addCssClasses('Event_1', 'ellipse-info');

    activityHistory.forEach(task => {
      if (task.taskId) {
        // console.log('taskID',task.taskId)
        let taskName = task.taskId.split('_');

        switch (taskName[0]) {
          case 'Gateway': {
            bpmnVisualization.bpmnElementsRegistry.addCssClasses(task.taskId, 'gate-success');

            break;
          }

          case 'ServiceTask':
          case 'Activity': {
            /*
             * Check if the task has assigne
             * If not get the candidate
             */
            let username;
            if (task.assignee) {
              username = task.assignee;
            } else {
              // Some processes don't have assignees or candidates
              if (task.candidates) {
                let arrayCanditates = task.candidates;
                arrayCanditates.forEach(canditate => {
                  username = canditate + ', ';
                });

                username = username?.substring(0, username.length - 2);
              }
            }

            //let taskDate = task.taskEndTime.substring(0, 9);
            let taskDate;
            if (task.taskEndTime) {
              taskDate = moment(task.taskEndTime).format('DD.MM.YYYY HH:mm');
            }

            switch (task.taskStatus) {
              case 'Pending': {
                bpmnVisualization.bpmnElementsRegistry.addCssClasses(task.taskId, 'task-running');
                bpmnVisualization.bpmnElementsRegistry.addOverlays(task.taskId, [
                  {
                    position: 'top-left',
                    label: username,
                    style: {
                      font: { color: 'MidnightBlue', size: 20 },
                      fill: { color: 'Aquamarine', opacity: 70 },
                      stroke: { color: '#4B0082', width: 1 },
                    },
                  },
                  {
                    position: 'bottom-right',
                    label: taskDate,
                    style: {
                      font: { color: ' #212429', size: 20 },
                      fill: { color: '#ffffb7', opacity: 70 },
                      stroke: { color: '#4B0082', width: 1 },
                    },
                  },
                ]);

                break;
              }

              case 'Completed': {
                bpmnVisualization.bpmnElementsRegistry.addCssClasses(task.taskId, 'task-completed');

                bpmnVisualization.bpmnElementsRegistry.addOverlays(task.taskId, [
                  {
                    position: 'top-left',
                    label: username,
                    style: {
                      font: { color: 'MidnightBlue', size: 20 },
                      fill: { color: 'Aquamarine', opacity: 70 },
                      stroke: { color: '#4B0082', width: 1 },
                    },
                  },
                  {
                    position: 'bottom-right',
                    label: taskDate,
                    style: {
                      font: { color: ' #212429', size: 20 },
                      fill: { color: '#ffffb7', opacity: 70 },
                      stroke: { color: '#4B0082', width: 1 },
                    },
                  },
                ]);
                break;
              }
            }
          }
        }
      }
    });

    //bpmnVisualization.bpmnElementsRegistry.addCssClasses(['UserTask_1xx7fv9'], 'task-completed');
    //bpmnVisualization.bpmnElementsRegistry.addCssClasses(['UserTask_16c4dnq'], 'task-running');
    //bpmnVisualization.bpmnElementsRegistry.addCssClasses(['SequenceFlow_1pzfzsv'], 'arrow-success');

    //bpmnVisualization.bpmnElementsRegistry.addCssClasses('UserTask_1xx7fv9', 'task-completed');
    //bpmnVisualization.bpmnElementsRegistry.addCssClasses('UserTask_16c4dnq', 'task-running');
  };

  React.useEffect(() => {
    if (bpmnXml) {
      renderDiagram(bpmnXml);
    }
  }, [bpmnXml, activityHistory]);

  return <div id="bpmn-container" ref={containerRef} />;
  //}
};

export default BpmnDiagram;
