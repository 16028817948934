import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import InEmployee from './in-employee';
import InEmployeeDetail from './in-employee-detail';
import InEmployeeUpdate from './in-employee-update';
import InEmployeeDeleteDialog from './in-employee-delete-dialog';

const InEmployeeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<InEmployee />} />
    <Route path="new" element={<InEmployeeUpdate />} />
    <Route path=":id">
      <Route index element={<InEmployeeDetail />} />
      <Route path="edit" element={<InEmployeeUpdate />} />
      <Route path="delete" element={<InEmployeeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default InEmployeeRoutes;
