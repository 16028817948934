import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import TechnicalOrderActionAttribute from './technical-order-action-attribute';
import TechnicalOrderActionAttributeDetail from './technical-order-action-attribute-detail';
import TechnicalOrderActionAttributeUpdate from './technical-order-action-attribute-update';
import TechnicalOrderActionAttributeDeleteDialog from './technical-order-action-attribute-delete-dialog';

const TechnicalOrderActionAttributeRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<TechnicalOrderActionAttribute />} />
    <Route path="new" element={<TechnicalOrderActionAttributeUpdate />} />
    <Route path=":id">
      <Route index element={<TechnicalOrderActionAttributeDetail />} />
      <Route path="edit" element={<TechnicalOrderActionAttributeUpdate />} />
      <Route path="delete" element={<TechnicalOrderActionAttributeDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default TechnicalOrderActionAttributeRoutes;
