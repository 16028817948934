import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { apiUrl as applicationUrl } from 'app/entities/application/application.reducer';
import { apiUrl as connectorCommandUrl } from 'app/entities/connector-command/connector-command.reducer';
import PaginatedReactSelect from 'app/shared/components/react-select/paginated-react-select';
import { OperationType } from 'app/shared/model/enumerations/operation-type.model';
import { buildSearchParamsObject } from 'app/shared/util/url-utils';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Button, Card, Col, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner } from 'reactstrap';
import { createEntity, getEntity, resetEntity, updateEntity } from './technical-order-action-create.reducer';

export const TechnicalOrderActionCreateUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParamsObject, setSearchParamsObject] = useState(buildSearchParamsObject(searchParams));

  const { technicalOrderActionUpdateId: id } = useParams<'technicalOrderActionUpdateId'>();
  const isNew = id === undefined;

  const technicalOrderActionCreateEntity = useAppSelector(state => state.technicalOrderActionCreate.entity);
  const loading = useAppSelector(state => state.technicalOrderActionCreate.loading);
  const updating = useAppSelector(state => state.technicalOrderActionCreate.updating);
  const updateSuccess = useAppSelector(state => state.technicalOrderActionCreate.updateSuccess);
  const operationTypeValues = Object.keys(OperationType);

  const [previousApplication, setPreviousApplication] = useState(null);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [toggleModal, setToggleModal] = useState(false);

  const handleClose = () => {
    navigate(-1);
  };

  const handleToggleModal = () => {
    setToggleModal(!toggleModal);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(resetEntity());
    } else {
      dispatch(getEntity(id));
    }
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
      resetEntity();
    }
  }, [updateSuccess]);

  useEffect(() => {
    formReset(defaultValues());
  }, [technicalOrderActionCreateEntity]);

  const saveEntity = values => {
    const entity = {
      ...technicalOrderActionCreateEntity,
      ...values,
    };

    if (!entity.createForUserIdentity && !entity.createForRole && !entity.createForGroup && !entity.createForSpecificPermission) {
      setToggleModal(true);

      return;
    }

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? { ...searchParamsObject }
      : {
          operationType: 'ADD',
          ...technicalOrderActionCreateEntity,
          connectorCommand: technicalOrderActionCreateEntity?.connectorCommand,
          application: technicalOrderActionCreateEntity?.application,
        };

  const {
    register,
    handleSubmit,
    control,
    reset: formReset,
    watch,
    setValue,
  } = useForm({
    defaultValues: defaultValues(),
    mode: 'onChange',
  });

  const watchApplication = watch('application', null);

  if (previousApplication !== watchApplication) {
    if (!isNew && isInitialRender) {
      setIsInitialRender(false);
    } else {
      setValue('connectorCommand', '');
    }
    setPreviousApplication(watchApplication);
  }

  return (
    <Card className="jh-card p-3">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="iamdentityApp.technicalOrderActionCreate.home.createOrEditLabel" data-cy="TechnicalOrderActionCreateCreateUpdateHeading">
            <Translate contentKey="iamdentityApp.technicalOrderActionCreate.home.createOrEditLabel">
              Create or edit a TechnicalOrderActionCreate
            </Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading || updating ? (
            <div className="entity-spinner-container">
              <Spinner color="primary">Loading...</Spinner>
            </div>
          ) : (
            <Form onSubmit={handleSubmit(saveEntity)}>
              {!isNew ? (
                <ValidatedField
                  register={register}
                  name="id"
                  required
                  readOnly
                  id="technical-order-action-create-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderActionCreate.createForUserIdentity')}
                id="technical-order-action-create-createForUserIdentity"
                name="createForUserIdentity"
                data-cy="createForUserIdentity"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderActionCreate.createForRole')}
                id="technical-order-action-create-createForRole"
                name="createForRole"
                data-cy="createForRole"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderActionCreate.createForGroup')}
                id="technical-order-action-create-createForGroup"
                name="createForGroup"
                data-cy="createForGroup"
                check
                type="checkbox"
              />
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderActionCreate.createForSpecificPermission')}
                id="technical-order-action-create-createForSpecificPermission"
                name="createForSpecificPermission"
                data-cy="createForSpecificPermission"
                check
                type="checkbox"
              />
              <FormGroup>
                <Label>
                  <Translate contentKey="iamdentityApp.technicalOrderActionCreate.application" />
                </Label>
                <PaginatedReactSelect
                  control={control}
                  name="application"
                  validate={{ required: true }}
                  url={applicationUrl}
                  queryProps={{
                    name: 'name',
                    type: 'string',
                  }}
                />
              </FormGroup>
              <ValidatedField
                register={register}
                label={translate('iamdentityApp.technicalOrderActionCreate.operationType')}
                id="technical-order-action-create-operationType"
                name="operationType"
                data-cy="operationType"
                type="select"
              >
                {operationTypeValues.map(operationType => (
                  <option value={operationType} key={operationType}>
                    {translate('iamdentityApp.OperationType.' + operationType)}
                  </option>
                ))}
              </ValidatedField>
              {watchApplication && (
                <FormGroup>
                  <Label>
                    <Translate contentKey="iamdentityApp.technicalOrderActionCreate.connectorCommand" />
                  </Label>
                  <PaginatedReactSelect
                    control={control}
                    isDisabled={searchParamsObject['connectorCommand']}
                    name="connectorCommand"
                    validate={{ required: true }}
                    url={connectorCommandUrl}
                    queryProps={{
                      name: 'description',
                      secondName: 'code',
                      type: 'string',
                      separeteByBracket: true,
                      predefinedQuery: watchApplication?.system?.connectorType?.id
                        ? `connectorTypeId.equals=${watchApplication.system.connectorType.id}`
                        : null,
                    }}
                  />
                </FormGroup>
              )}
              <Button id="cancel-save" data-cy="entityCreateCancelButton" onClick={() => navigate(-1)} color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </Form>
          )}
        </Col>
      </Row>
      <Modal isOpen={toggleModal} toggle={handleToggleModal}>
        <ModalHeader toggle={handleToggleModal} data-cy="applicationModalHeading">
          <Translate contentKey="iamdentityApp.technicalOrderActionCreate.modal.title">Validation error</Translate>
        </ModalHeader>
        <ModalBody id="iamdentityApp.technicalOrderActionCreate.modal.question">
          <Translate contentKey="iamdentityApp.technicalOrderActionCreate.modal.question">
            It is required to select at least one checkbox to specify the business request handled by this Technical Action
          </Translate>
        </ModalBody>
      </Modal>
    </Card>
  );
};

export default TechnicalOrderActionCreateUpdate;
