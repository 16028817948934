import React, { useState } from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export interface IPaginatedDropdownProps {
  itemsPerPage: number;
  handlePageSizeChange: (newSize: number) => any;
}

const pageSizes = [5, 20, 50, 100];

function PaginatedDropdown(props: IPaginatedDropdownProps) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown} style={{ marginLeft: 10 }} className="me-2" size="sm">
      <DropdownToggle caret color="light">
        {props.itemsPerPage}
      </DropdownToggle>
      <DropdownMenu>
        {pageSizes.map(size => {
          return (
            <DropdownItem key={size} onClick={() => props.handlePageSizeChange(size)}>
              {size}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  );
}

export default PaginatedDropdown;
