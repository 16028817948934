import { IUserGroup } from 'app/shared/model/user-group.model';
import { IUser } from 'app/shared/model/user.model';
import { IImportSession } from 'app/shared/model/import-session.model';

export interface IReconSessionUserGroupIamNotInSystems {
  id?: number;
  processed?: boolean | null;
  comments?: string | null;
  iamUserGroup?: IUserGroup | null;
  processedBy?: IUser | null;
  importSession?: IImportSession | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IReconSessionUserGroupIamNotInSystems> = {
  processed: false,
};
