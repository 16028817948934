import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import axios from 'axios';

import { defaultValue, IAudit } from 'app/shared/model/audit.model';
import { createEntitySlice, EntityState } from 'app/shared/reducers/reducer.utils';

interface AuditRequest {
  id: string;
  entityName: string;
}

const initialState: EntityState<IAudit> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  totalItems: 0,
  updateSuccess: false,
};

const apiUrl = 'api/getRevisionsById/';

// Actions

export const getRevisionsById = createAsyncThunk('audit/fetch_entity_list', async (params: AuditRequest) => {
  const requestUrl = `${apiUrl}${params.id}/${params.entityName}`;
  return axios.get<IAudit[]>(requestUrl);
});

// slice

export const AuditSlice = createEntitySlice({
  name: 'audit',
  initialState,
  extraReducers(builder) {
    builder
      .addMatcher(isFulfilled(getRevisionsById), (state, action) => {
        const { data, headers } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
          totalItems: parseInt(headers['x-total-count'], 10),
        };
      })
      .addMatcher(isPending(getRevisionsById), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      });
  },
});

export const { reset } = AuditSlice.actions;

// Reducer
export default AuditSlice.reducer;
