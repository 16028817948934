import { EmployeeStatus } from 'app/shared/model/enumerations/employee-status.model';
import { IJobDefinition } from 'app/shared/model/job-definition.model';
import { ILocation } from 'app/shared/model/location.model';
import { IOrganizationUnit } from 'app/shared/model/organization-unit.model';
import { IPerson } from 'app/shared/model/person.model';

export interface IEmployee {
  id?: number;
  code?: string | null;
  fullName?: string | null;
  contractStartDate?: string | null;
  contractEndDate?: string | null;
  employeeStatus?: EmployeeStatus | null;
  organizationUnit?: IOrganizationUnit | null;
  job?: IJobDefinition | null;
  superior?: IEmployee | null;
  location?: ILocation | null;
  person?: IPerson | null;
  createdBy?: string | null;
  createdDate?: string | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: string | null;
}

export const defaultValue: Readonly<IEmployee> = {};
